<template>
  <div class="container py-5">
    <div class="card mb-4 rounded-3 shadow-sm">
      <form ref="form">
        <div class="container-fluid">
          <div class="row py-2">
            <div class="col-sm-12">
              <label class="form-label">Descrição*</label>
              <input
                v-model="model.descricao"
                type="text"
                class="form-control"
                required
              />
              <div class="invalid-feedback">
                Por favor, preencha o campo Descrição.
              </div>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-sm-6">
              <label class="form-label">Código do Material*</label>
              <input
                v-model="model.codigo"
                type="text"
                class="form-control"
                required
              />
              <div class="invalid-feedback">
                Por favor, preencha o campo Código do Material.
              </div>
            </div>
            <!-- <div class="col-sm-4">
                    <base-select
                      label="ID"
                      :value="model.id_atividade"
                      textField="descricao"
                      route="atividade"
                      @onSelect="
                        (id) => {
                          model.id_atividade = id;
                        }
                      "
                    />
                  </div> -->
            <div class="col-sm-6">
              <label class="form-label">Velocidade*</label>
              <input
                v-model="model.velocidade"
                type="text"
                class="form-control"
                required
              />
              <div class="invalid-feedback">
                Por favor, preencha o campo Velocidade.
              </div>
            </div>
          </div>
          <div class="row py-2 col-10">
            <div>
              <label class="form-label">Características</label>
              <button
                @click="novaCaracteristica"
                size="sm"
                type="button"
                class="btn btn-success text-light btn-no-hover ms-2"
              >
                +
              </button>
            </div>
            <div
              class="row pb-3"
              v-for="caracteristica in model.materialCaracteristicaMaterial"
              :key="caracteristica.id"
            >
              <div class="col-sm-4">
                <label class="form-label">Chave*</label>
                <select-local
                  :value="caracteristica.chave"
                  :options="chavesCaracteristicas"
                  @onSelect="(val) => (caracteristica.chave = val)"
                />
                <!-- <input
                        v-model="caracteristica.chave"
                        type="text"
                        class="form-control"
                        required
                        disabled
                      /> -->
                <div class="invalid-feedback">
                  Por favor, preencha o campo Chave.
                </div>
              </div>
              <div class="col-sm-4">
                <label class="form-label">Valor*</label>
                <input
                  v-model="caracteristica.valor"
                  type="text"
                  class="form-control"
                  required
                />
                <div class="invalid-feedback">
                  Por favor, preencha o campo Valor.
                </div>
              </div>
              <div class="col-sm-4">
                <p class="form-label">Excluir</p>
                <button
                  @click="apagaCaracteristica(caracteristica)"
                  size="sm"
                  type="button"
                  class="btn btn-danger text-light btn-no-hover ms-2"
                >
                  <i class="bi bi-trash"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row py-2">
            <confirm-buttons
              :habExclusao="false"
              @cancelar="cancelAdd"
              @confirmar="save"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import BaseSelect from "../../components/BaseSelect.vue";
import ConfirmButtons from "../../components/ConfirmButtons.vue";
import RestResource from "../../services/material";
import SelectLocal from "../../components/SelectLocal.vue";

export default {
  components: { ConfirmButtons, BaseSelect, SelectLocal },
  data() {
    return {
      id: null,
      isBusy: false,
      model: {
        nome: "",
        id_atividade: null,
        materialCaracteristicaMaterial: [],
      },
      chavesCaracteristicas: [
        { value: "DIAMETRO", text: "DIAMETRO" },
        { value: "TIPO", text: "TIPO" },
        { value: "DEPOSICAO", text: "DEPOSICAO" },
      ],
    };
  },
  methods: {
    save() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.classList.add("was-validated");
        return;
      }

      RestResource.create(this.model).then(
        (result) => {
          if (result.error) {
            swal({
              title: "Oops!",
              text: result.message,
              icon: "error",
              button: "Continuar...",
            }).then(() => {
              this.isBusy = false;
            });
          } else {
            swal({
              title: "Bom trabalho!",
              text: "O material foi inserida na base de dados com sucesso!",
              icon: "success",
              button: "Continuar...",
            }).then(() => {
              this.$router.replace("/material");
              this.isBusy = false;
            });
          }
        },
        () => {
          swal({
            title: "Oops!",
            text: "Ocorreu um problema ao tentar inserir o material.",
            icon: "error",
            button: "Continuar...",
          }).then(() => {
            this.isBusy = false;
          });
        }
      );
    },
    cancelAdd() {
      swal({
        title: "Cancelamento de criação de registro",
        text: "Deseja cancelar a criação do material?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" },
      }).then((value) => {
        if (value) {
          this.$router.push("/material");
        }
      });
    },
    novaCaracteristica() {
      this.model.materialCaracteristicaMaterial.push({});
    },
    apagaCaracteristica(carac) {
      this.model.materialCaracteristicaMaterial =
        this.model.materialCaracteristicaMaterial.filter(
          (c) => c.chave != carac.chave
        );
    },
  },
};
</script>
