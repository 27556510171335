import { createRouter, createWebHistory } from 'vue-router'


import loginService from './services/login';
import { perms } from './helpers/auth';

import Atividade from './views/Atividade/AtividadeTabela.vue'
import AtividadeAdicionar from './views/Atividade/AtividadeAdicionar.vue'
import AtividadeEditar from './views/Atividade/AtividadeEditar.vue'

import Calculadora from './views/Calculadora/CalculadoraTabela.vue'
import CalculadoraAdicionar from './views/Calculadora/CalculadoraAdicionar.vue'
import CalculadoraEditar from './views/Calculadora/CalculadoraEditar.vue'

import CaracteristicaCalculadora from './views/CaracteristicaCalculadora/CaracteristicaCalculadoraTabela.vue'
import CaracteristicaCalculadoraAdicionar from './views/CaracteristicaCalculadora/CaracteristicaCalculadoraAdicionar.vue'
import CaracteristicaCalculadoraEditar from './views/CaracteristicaCalculadora/CaracteristicaCalculadoraEditar.vue'

import CaracteristicaEstado from './views/CaracteristicaEstado/CaracteristicaEstadoTabela.vue'
import CaracteristicaEstadoAdicionar from './views/CaracteristicaEstado/CaracteristicaEstadoAdicionar.vue'
import CaracteristicaEstadoEditar from './views/CaracteristicaEstado/CaracteristicaEstadoEditar.vue'

import CaracteristicaMaterial from './views/CaracteristicaMaterial/CaracteristicaMaterialTabela.vue'
import CaracteristicaMaterialAdicionar from './views/CaracteristicaMaterial/CaracteristicaMaterialAdicionar.vue'
import CaracteristicaMaterialEditar from './views/CaracteristicaMaterial/CaracteristicaMaterialEditar.vue'

import Dashboard from './views/Dashboard/Dashboard.vue'
import DashboardGrafico from './views/Dashboard/DashboardGrafico.vue'
import DashboardMaquina from './views/Dashboard/DashboardMaquina.vue'

import Estado from './views/Estado/EstadoTabela.vue'
import EstadoAdicionar from './views/Estado/EstadoAdicionar.vue'
import EstadoEditar from './views/Estado/EstadoEditar.vue'

import GrupoOperador from './views/GrupoOperador/GrupoOperadorTabela.vue'
import GrupoOperadorAdicionar from './views/GrupoOperador/GrupoOperadorAdicionar.vue'
import GrupoOperadorEditar from './views/GrupoOperador/GrupoOperadorEditar.vue'

import GrupoUsuario from './views/GrupoUsuario/GrupoUsuarioTabela.vue'
import GrupoUsuarioAdicionar from './views/GrupoUsuario/GrupoUsuarioAdicionar.vue'
import GrupoUsuarioEditar from './views/GrupoUsuario/GrupoUsuarioEditar.vue'

import Maquina from './views/Maquina/MaquinaTabela.vue'
import MaquinaAdicionar from './views/Maquina/MaquinaAdicionar.vue'
import MaquinaEditar from './views/Maquina/MaquinaEditar.vue'

import Medidor from './views/Medidor/MedidorTabela.vue'
import MedidorAdicionar from './views/Medidor/MedidorAdicionar.vue'
import MedidorEditar from './views/Medidor/MedidorEditar.vue'

import Material from './views/Material/MaterialTabela.vue'
import MaterialAdicionar from './views/Material/MaterialAdicionar.vue'
import MaterialEditar from './views/Material/MaterialEditar.vue'

import Operador from './views/Operador/OperadorTabela.vue'
import OperadorAdicionar from './views/Operador/OperadorAdicionar.vue'
import OperadorEditar from './views/Operador/OperadorEditar.vue'

import OrdemProducao from './views/OrdemProducao/OrdemProducaoTabela.vue'
import OrdemProducaoAdicionar from './views/OrdemProducao/OrdemProducaoAdicionar.vue'
import OrdemProducaoEditar from './views/OrdemProducao/OrdemProducaoEditar.vue'
import OrdemProducaoImportar from './views/OrdemProducao/OrdemProducaoImportar.vue'

import Permissao from './views/Permissao/PermissaoTabela.vue'
import PermissaoAdicionar from './views/Permissao/PermissaoAdicionar.vue'
import PermissaoEditar from './views/Permissao/PermissaoEditar.vue'

import Relatorio from './views/Relatorio/Relatorio.vue'
import RelatorioAuditoria from './views/Relatorio/RelatorioAuditoria.vue'
import RelatorioConfiguracao from './views/Relatorio/RelatorioConfiguracao.vue'
import RelatorioEstado from './views/Relatorio/RelatorioEstado.vue'
import RelatorioEstadoProducao from './views/Relatorio/RelatorioEstadoProducao.vue'
import RelatorioGrafico from './views/Relatorio/RelatorioGrafico.vue'

import TipoCalculadora from './views/TipoCalculadora/TipoCalculadoraTabela.vue'
import TipoCalculadoraAdicionar from './views/TipoCalculadora/TipoCalculadoraAdicionar.vue'
import TipoCalculadoraEditar from './views/TipoCalculadora/TipoCalculadoraEditar.vue'

import Localizacao from './views/Localizacao/LocalizacaoTabela.vue'
import LocalizacaoAdicionar from './views/Localizacao/LocalizacaoAdicionar.vue'
import LocalizacaoEditar from './views/Localizacao/LocalizacaoEditar.vue'

import TipoProcesso from './views/TipoProcesso/TipoProcessoTabela.vue'
import TipoProcessoAdicionar from './views/TipoProcesso/TipoProcessoAdicionar.vue'
import TipoProcessoEditar from './views/TipoProcesso/TipoProcessoEditar.vue'

import Turno from './views/Turno/TurnoTabela.vue'
import TurnoAdicionar from './views/Turno/TurnoAdicionar.vue'
import TurnoEditar from './views/Turno/TurnoEditar.vue'

import Usuario from './views/Usuario/UsuarioTabela.vue'
import UsuarioAdicionar from './views/Usuario/UsuarioAdicionar.vue'
import UsuarioEditar from './views/Usuario/UsuarioEditar.vue'

import VelocidadeMaterial from './views/VelocidadeMaterial/VelocidadeMaterialTabela.vue'
import VelocidadeMaterialAdicionar from './views/VelocidadeMaterial/VelocidadeMaterialAdicionar.vue'
import VelocidadeMaterialEditar from './views/VelocidadeMaterial/VelocidadeMaterialEditar.vue'

import PageNotFound from './views/404.vue';
import Forbidden from './views/403.vue';
import Unauthorized from './views/401.vue';
import Logout from './views/Autenticacao/Logout.vue';

const routes = [
  {
    path: '/atividade',
    name: 'atividade',
    component: Atividade,
    meta: {
      title: 'IDs',
      perms: perms(['visualizar_atividade']),
      ativo: true,
    }
  },
  {
    path: '/atividade/adicionar',
    name: 'atividadeAdicionar',
    component: AtividadeAdicionar,
    meta: {
      title: 'Novo ID',
      perms: perms(['adicionar_atividade']),
      ativo: true,
    }
  },
  {
    path: '/atividade/editar/:id',
    name: 'atividadeEditar',
    component: AtividadeEditar,
    meta: {
      title: 'Editar ID',
      perms: perms(['editar_atividade']),
      ativo: true,
    }
  },
  {
    path: '/calculadora',
    name: 'calculadora',
    component: Calculadora,
    meta: {
      title: 'Calculadoras',
      perms: perms(['visualizar_calculadora']),
      ativo: true,
    }
  },
  {
    path: '/calculadora/adicionar',
    name: 'calculadoraAdicionar',
    component: CalculadoraAdicionar,
    meta: {
      title: 'Nova Calculadora',
      perms: perms(['adicionar_calculadora']),
      ativo: true,
    }
  },
  {
    path: '/calculadora/editar/:id',
    name: 'calculadoraEditar',
    component: CalculadoraEditar,
    meta: {
      title: 'Editar Calculadora',
      perms: perms(['editar_calculadora']),
      ativo: true,
    }
  },
  {
    path: '/caracteristicaCalculadora',
    name: 'caracteristicaCalculadora',
    component: CaracteristicaCalculadora,
    meta: {
      title: 'Características da Calculadora',
      perms: perms(['visualizar_caracteristicaCalculadora']),
      ativo: true,
    }
  },
  {
    path: '/caracteristicaCalculadora/adicionar',
    name: 'caracteristicaCalculadoraAdicionar',
    component: CaracteristicaCalculadoraAdicionar,
    meta: {
      title: 'Nova Característica da Calculadora',
      perms: perms(['adicionar_caracteristicaCalculadora']),
      ativo: true,
    }
  },
  {
    path: '/caracteristicaCalculadora/editar/:id',
    name: 'caracteristicaCalculadoraEditar',
    component: CaracteristicaCalculadoraEditar,
    meta: {
      title: 'Editar Característica da Calculadora',
      perms: perms(['editar_caracteristicaCalculadora']),
      ativo: true,
    }
  },
  {
    path: '/caracteristicaEstado',
    name: 'caracteristicaEstado',
    component: CaracteristicaEstado,
    meta: {
      title: 'Características do Estado',
      perms: perms(['visualizar_caracteristicaEstado']),
      ativo: true,
    }
  },
  {
    path: '/caracteristicaEstado/adicionar',
    name: 'caracteristicaEstadoAdicionar',
    component: CaracteristicaEstadoAdicionar,
    meta: {
      title: 'Nova Característica do Estado',
      perms: perms(['adicionar_caracteristicaEstado']),
      ativo: true,
    }
  },
  {
    path: '/caracteristicaEstado/editar/:id',
    name: 'caracteristicaEstadoEditar',
    component: CaracteristicaEstadoEditar,
    meta: {
      title: 'Editar Característica do Estado',
      perms: perms(['editar_caracteristicaEstado']),
      ativo: true,
    }
  },
  {
    path: '/caracteristicaMaterial',
    name: 'caracteristicaMaterial',
    component: CaracteristicaMaterial,
    meta: {
      title: 'Características do Material',
      perms: perms(['visualizar_caracteristicaMaterial']),
      ativo: true,
    }
  },
  {
    path: '/caracteristicaMaterial/adicionar',
    name: 'caracteristicaMaterialAdicionar',
    component: CaracteristicaMaterialAdicionar,
    meta: {
      title: 'Nova Característica do Material',
      perms: perms(['adicionar_caracteristicaMaterial']),
      ativo: true,
    }
  },
  {
    path: '/caracteristicaMaterial/editar/:id',
    name: 'caracteristicaMaterialEditar',
    component: CaracteristicaMaterialEditar,
    meta: {
      title: 'Editar Característica do Material',
      perms: perms(['editar_caracteristicaMaterial']),
      ativo: true,
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      perms: perms(['visualizar_dashboard']),
      ativo: true,
    }
  },
  {
    path: '/dashboardGrafico/:id',
    name: 'dashboardGrafico',
    component: DashboardGrafico,
    meta: {
      title: 'Dashboard Gráfico',
      perms: perms(['visualizar_dashboard']),
      ativo: true,
    }
  },
  {
    path: '/dashboard/:id',
    name: 'dashboardMaquina',
    component: DashboardMaquina,
    meta: {
      title: 'Dashboard Máquina',
      perms: perms(['visualizar_dashboard']),
      ativo: true,
    }
  },
  {
    path: '/estado',
    name: 'estado',
    component: Estado,
    meta: {
      title: 'Estados',
      perms: perms(['visualizar_estado']),
      ativo: true,
    }
  },
  {
    path: '/estado/adicionar',
    name: 'estadoAdicionar',
    component: EstadoAdicionar,
    meta: {
      title: 'Novo Estado',
      perms: perms(['adicionar_estado']),
      ativo: true,
    }
  },
  {
    path: '/estado/editar/:id',
    name: 'estadoEditar',
    component: EstadoEditar,
    meta: {
      title: 'Editar Estado',
      perms: perms(['editar_estado']),
      ativo: true,
    }
  },
  {
    path: '/grupoOperador',
    name: 'grupoOperador',
    component: GrupoOperador,
    meta: {
      title: 'Grupos de Operadores',
      perms: perms(['visualizar_grupoOperador']),
      ativo: true,
    }
  },
  {
    path: '/grupoOperador/adicionar',
    name: 'grupoOperadorAdicionar',
    component: GrupoOperadorAdicionar,
    meta: {
      title: 'Novo Grupo de Operador',
      perms: perms(['adicionar_grupoOperador']),
      ativo: true,
    }
  },
  {
    path: '/grupoOperador/editar/:id',
    name: 'grupoOperadorEditar',
    component: GrupoOperadorEditar,
    meta: {
      title: 'Editar Grupo de Operador',
      perms: perms(['editar_grupoOperador']),
      ativo: true,
    }
  },
  {
    path: '/grupoUsuario',
    name: 'grupoUsuario',
    component: GrupoUsuario,
    meta: {
      title: 'Grupos de Usuários',
      perms: perms(['visualizar_grupoUsuario']),
      ativo: true,
    }
  },
  {
    path: '/grupoUsuario/adicionar',
    name: 'grupoUsuarioAdicionar',
    component: GrupoUsuarioAdicionar,
    meta: {
      title: 'Novo Grupo de Usuário',
      perms: perms(['adicionar_grupoUsuario']),
      ativo: true,
    }
  },
  {
    path: '/grupoUsuario/editar/:id',
    name: 'grupoUsuarioEditar',
    component: GrupoUsuarioEditar,
    meta: {
      title: 'Editar Grupo de Usuário',
      perms: perms(['editar_grupoUsuario']),
      ativo: true,
    }
  },
  {
    path: '/maquina',
    name: 'maquina',
    component: Maquina,
    meta: {
      title: 'Máquinas',
      perms: perms(['visualizar_maquina']),
      ativo: true,
    }
  },
  {
    path: '/maquina/adicionar',
    name: 'maquinaAdicionar',
    component: MaquinaAdicionar,
    meta: {
      title: 'Nova Máquina',
      perms: perms(['adicionar_maquina']),
      ativo: true,
    }
  },
  {
    path: '/maquina/editar/:id',
    name: 'maquinaEditar',
    component: MaquinaEditar,
    meta: {
      title: 'Editar Máquina',
      perms: perms(['editar_maquina']),
      ativo: true,
    }
  },
  {
    path: '/material',
    name: 'material',
    component: Material,
    meta: {
      title: 'Materiais',
      perms: perms(['visualizar_material']),
      ativo: true,
    }
  },
  {
    path: '/material/adicionar',
    name: 'materialAdicionar',
    component: MaterialAdicionar,
    meta: {
      title: 'Novo Material',
      perms: perms(['adicionar_material']),
      ativo: true,
    }
  },
  {
    path: '/material/editar/:id',
    name: 'materialEditar',
    component: MaterialEditar,
    meta: {
      title: 'Editar Material',
      perms: perms(['editar_material']),
      ativo: true,
    }
  },
  {
    path: '/operador',
    name: 'operador',
    component: Operador,
    meta: {
      title: 'Operadores',
      perms: perms(['visualizar_operador']),
      ativo: true,
    }
  },
  {
    path: '/operador/adicionar',
    name: 'operadorAdicionar',
    component: OperadorAdicionar,
    meta: {
      title: 'Novo Operador',
      perms: perms(['adicionar_operador']),
      ativo: true,
    }
  },
  {
    path: '/operador/editar/:id',
    name: 'operadorEditar',
    component: OperadorEditar,
    meta: {
      title: 'Editar Operador',
      perms: perms(['editar_operador']),
      ativo: true,
    }
  },
  {
    path: '/ordemProducao',
    name: 'OrdemProducao',
    component: OrdemProducao,
    meta: {
      title: 'Ordens de Produção',
      perms: perms(['visualizar_ordemProducao']),
      ativo: true,
    }
  },
  {
    path: '/ordemProducao/adicionar',
    name: 'OrdemProducaoAdicionar',
    component: OrdemProducaoAdicionar,
    meta: {
      title: 'Nova Ordem de Produção',
      perms: perms(['adicionar_ordemProducao']),
      ativo: true,
    }
  },
  {
    path: '/ordemProducao/editar/:id',
    name: 'OrdemProducaoEditar',
    component: OrdemProducaoEditar,
    meta: {
      title: 'Editar Ordem de Produção',
      perms: perms(['editar_ordemProducao']),
      ativo: true,
    }
  },
  {
    path: '/ordemProducao/importar',
    name: 'OrdemProducaoImportar',
    component: OrdemProducaoImportar,
    meta: {
      title: 'Importar Ordem de Produção',
      perms: perms(['adicionar_ordemProducao']),
      ativo: true,
    }
  },
  {
    path: '/permissao',
    name: 'Permissao',
    component: Permissao,
    meta: {
      title: 'Permissões',
      perms: perms(['visualizar_permissao']),
      ativo: true,
    }
  },
  {
    path: '/permissao/adicionar',
    name: 'PermissaoAdicionar',
    component: PermissaoAdicionar,
    meta: {
      title: 'Nova Permissão',
      perms: perms(['adicionar_permissao']),
      ativo: true,
    }
  },
  {
    path: '/permissao/editar/:id',
    name: 'PermissaoEditar',
    component: PermissaoEditar,
    meta: {
      title: 'Editar Permissão',
      perms: perms(['editar_permissao']),
      ativo: true,
    }
  },
  {
    path: '/relatorio',
    name: 'relatorio',
    component: Relatorio,
    meta: {
      title: 'Relatório Geral',
      perms: perms(['visualizar_relatorio']),
      ativo: true,
    }
  },
  {
    path: '/relatorioAuditoria',
    name: 'relatorioAuditoria',
    component: RelatorioAuditoria,
    meta: {
      title: 'Relatório de Auditoria',
      perms: perms(['visualizar_auditoria']),
      ativo: true,
    }
  },
  {
    path: '/relatorio/configuracao/:id',
    name: 'relatorioConfiguracao',
    component: RelatorioConfiguracao,
    meta: {
      title: 'Relatório de Dados',
      perms: perms(['visualizar_relatorio']),
      ativo: true,
    }
  },
  {
    path: '/relatorio/estado/:id',
    name: 'relatorioEstado',
    component: RelatorioEstado,
    meta: {
      title: 'Relatório dos Estados de Produção',
      perms: perms(['visualizar_relatorio']),
      ativo: true,
    }
  },
  {
    path: '/relatorio/estado/:idConf/:idEst',
    name: 'relatorioEstadoProducao',
    component: RelatorioEstadoProducao,
    meta: {
      title: 'Relatório do Estado',
      perms: perms(['visualizar_relatorio']),
      ativo: true,
    }
  },
  {
    path: '/relatorioGrafico',
    name: 'relatorioGrafico',
    component: RelatorioGrafico,
    meta: {
      title: 'Relatório Gráfico',
      perms: perms(['visualizar_relatorio']),
      ativo: true,
    }
  },
  {
    path: '/tipoCalculadora',
    name: 'tipoCalculadora',
    component: TipoCalculadora,
    meta: {
      title: 'Tipos de Calculadora',
      perms: perms(['visualizar_tipoCalculadora']),
      ativo: true,
    }
  },
  {
    path: '/tipoCalculadora/adicionar',
    name: 'tipoCalculadoraAdicionar',
    component: TipoCalculadoraAdicionar,
    meta: {
      title: 'Novo Tipo de Calculadora',
      perms: perms(['adicionar_tipoCalculadora']),
      ativo: true,
    }
  },
  {
    path: '/tipoCalculadora/editar/:id',
    name: 'tipoCalculadoraEditar',
    component: TipoCalculadoraEditar,
    meta: {
      title: 'Editar Tipo de Calculadora',
      perms: perms(['editar_tipoCalculadora']),
      ativo: true,
    }
  },
  {
    path: '/localizacao',
    name: 'localizacao',
    component: Localizacao,
    meta: {
      title: 'Localizações',
      perms: perms(['visualizar_localizacao']),
      ativo: true,
    }
  },
  {
    path: '/localizacao/adicionar',
    name: 'localizacaoAdicionar',
    component: LocalizacaoAdicionar,
    meta: {
      title: 'Nova Localização',
      perms: perms(['adicionar_localizacao']),
      ativo: true,
    }
  },
  {
    path: '/localizacao/editar/:id',
    name: 'localizacaoEditar',
    component: LocalizacaoEditar,
    meta: {
      title: 'Editar Localização',
      perms: perms(['editar_localizacao']),
      ativo: true,
    }
  },
  {
    path: '/tipoProcesso',
    name: 'tipoProcesso',
    component: TipoProcesso,
    meta: {
      title: 'Tipos de Processo',
      perms: perms(['visualizar_tipoProcesso']),
      ativo: true,
    }
  },
  {
    path: '/tipoProcesso/adicionar',
    name: 'tipoProcessoAdicionar',
    component: TipoProcessoAdicionar,
    meta: {
      title: 'Novo Tipo de Processo',
      perms: perms(['adicionar_tipoProcesso']),
      ativo: true,
    }
  },
  {
    path: '/tipoProcesso/editar/:id',
    name: 'tipoProcessoEditar',
    component: TipoProcessoEditar,
    meta: {
      title: 'Editar Tipo de Processo',
      perms: perms(['editar_tipoProcesso']),
      ativo: true,
    }
  },
  {
    path: '/turno',
    name: 'turno',
    component: Turno,
    meta: {
      title: 'Turnos',
      perms: perms(['visualizar_turno']),
      ativo: true,
    }
  },
  {
    path: '/turno/adicionar',
    name: 'turnoAdicionar',
    component: TurnoAdicionar,
    meta: {
      title: 'Novo Turno',
      perms: perms(['adicionar_turno']),
      ativo: true,
    }
  },
  {
    path: '/turno/editar/:id',
    name: 'turnoEditar',
    component: TurnoEditar,
    meta: {
      title: 'Editar Turno',
      perms: perms(['editar_turno']),
      ativo: true,
    }
  },
  {
    path: '/usuario',
    name: 'usuario',
    component: Usuario,
    meta: {
      title: 'Usuários',
      perms: perms(['visualizar_usuario']),
      ativo: true,
    }
  },
  {
    path: '/usuario/adicionar',
    name: 'usuarioAdicionar',
    component: UsuarioAdicionar,
    meta: {
      title: 'Novo Usuário',
      perms: perms(['adicionar_usuario']),
      ativo: true,
    }
  },
  {
    path: '/usuario/editar/:id',
    name: 'usuarioEditar',
    component: UsuarioEditar,
    meta: {
      title: 'Editar Usuário',
      perms: perms(['editar_usuario']),
      ativo: true,
    }
  },
  {
    path: '/velocidadeMaterial',
    name: 'velocidadeMaterial',
    component: VelocidadeMaterial,
    meta: {
      title: 'Velocidades do Material',
      perms: perms(['visualizar_velocidadeMaterial']),
      ativo: true,
    }
  },
  {
    path: '/velocidadeMaterial/adicionar',
    name: 'velocidadeMaterialAdicionar',
    component: VelocidadeMaterialAdicionar,
    meta: {
      title: 'Nova Velocidade do Material',
      perms: perms(['adicionar_velocidadeMaterial']),
      ativo: true,
    }
  },
  {
    path: '/velocidadeMaterial/editar/:id',
    name: 'velocidadeMaterialEditar',
    component: VelocidadeMaterialEditar,
    meta: {
      title: 'Editar Velocidade do Material',
      perms: perms(['editar_velocidadeMaterial']),
      ativo: true,
    }
  },
  {
    path: '/medidor',
    name: 'medidor',
    component: Medidor,
    meta: {
      title: 'Medidores',
      perms: perms(['visualizar_medidor']),
      ativo: true,
    }
  },
  {
    path: '/medidor/adicionar',
    name: 'medidorAdicionar',
    component: MedidorAdicionar,
    meta: {
      title: 'Novo Medidor',
      perms: perms(['adicionar_medidor']),
      ativo: true,
    }
  },
  {
    path: '/medidor/editar/:id',
    name: 'medidorEditar',
    component: MedidorEditar,
    meta: {
      title: 'Editar Medidor',
      perms: perms(['editar_medidor']),
      ativo: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Unauthorized,
    meta: {
      title: 'Login',
      perms: perms(),
      ativo: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      title: 'Logout',
      perms: perms(),
      ativo: true,
    },
  },
  {
    name: '403',
    path: '/forbidden',
    component: Forbidden,
    meta: {
      title: 'Permissão insuficiente',
      perms: perms(),
      ativo: true,
    },
  },
  // 404 (precisa ser a última rota deste arquivo)
  {
    name: '404',
    path: "/:catchAll(.*)*",
    component: PageNotFound,
    meta: {
      title: 'Página não encontrada',
      perms: perms(),
      ativo: true,
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.perms) next(); // página pública
  else if (!to.meta.ativo) next({ name: '404' }); // página pública
  else if (!loginService.isLoggedIn()) next({ name: 'login' }); // não logado
  else if (!loginService.verifyPermissions(to.meta.perms)) next({ name: '403' }); // sem permissão
  else next(); // acesso permitido
});

export default router
