<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm border-card">
            <form  ref="form">
                <div class="container-fluid">
                    <div class="row py-2">
                      <div class="col-sm-6">
                          <label class="form-label">Chave*</label>
                          <input v-model="model.chave" type="text" class="form-control" required>
                          <div class="invalid-feedback">
                            Por favor, preencha o campo Chave.
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <label class="form-label">Nome*</label>
                          <input v-model="model.nome" type="text" class="form-control" required>
                          <div class="invalid-feedback">
                            Por favor, preencha o campo Nome.
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <label class="form-label">Descrição*</label>
                          <input v-model="model.descricao" type="text" class="form-control" required>
                          <div class="invalid-feedback">
                            Por favor, preencha o campo Descrição.
                          </div>
                      </div>
                      <div class="col-sm-6">
                        <base-select
                        label="Tipo de Calculadora"
                        :value="model.id_tipo_calculadora"
                        textField="chave"
                        route="tipoCalculadora"
                        @onSelect="(id) => { model.id_tipo_calculadora = id }"
                        />
                      </div>
                    </div>
                    <div class="row py-2">
                      <confirm-buttons
                        :habExclusao="false"
                        @cancelar="cancelAdd"
                        @confirmar="save"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert';
import BaseSelect from '../../components/BaseSelect.vue';
import ConfirmButtons from "../../components/ConfirmButtons.vue"
import RestResource from "../../services/calculadora";


export default{
  components: { BaseSelect, ConfirmButtons },
  data(){
    return {
      id: null,
      isBusy: false,
      model: {},
    }
  },
  mounted() {
    document.body.style.backgroundColor = 'var(--color-body)';
  },
  methods: {
    save() {
      if (!this.$refs.form.checkValidity()) {
          this.$refs.form.classList.add('was-validated');
          return;
      }      
      RestResource.create(this.model).then(result => {
        if(result.error){
          swal({
          title: "Oops!",
          text:
            result.message,
          icon: "error",
          button: "Continuar..."
          }).then(() => {
          this.isBusy = false;
          });
        } else {
          swal({
            title: "Bom trabalho!",
            text:
              "A calculadora foi inserida na base de dados com sucesso!",
            icon: "success",
            button: "Continuar..."
          }).then(() => {
            this.$router.replace('/calculadora');
            this.isBusy = false;
          });
        }
      },() => {
        swal({
          title: "Oops!",
          text:
            "Ocorreu um problema ao tentar inserir a calculadora.",
          icon: "error",
          button: "Continuar..."
        }).then(() => {
          this.isBusy = false;
        });
      });
    },
    cancelAdd() {
      swal({
        title: "Cancelamento de criação de registro",
        text: "Deseja cancelar a criação da calculadora?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          this.$router.push('/calculadora');
        }
      });
    },

  }
}

</script>