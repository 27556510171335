<template>
  <loading-page v-if="isBusy" />
  <div v-else class="card card-table mt-0 h-100">
    <div v-if="title" class="card-header bg-white text-uppercase fs-5 px-3">
      <div class="row">
        <div class="col-10">
          {{ title }}
        </div>
        <div class="col-2 text-end">
          <i class="bi bi-arrow-down-circle" style="cursor: pointer;" @click="exportToPDF"></i>
        </div>
      </div>    
    </div>
    <div class="card-body pt-0">
      <!-- <span style="position: absolute; top: 46%; left: 39%; font-size: 4rem; font-weight: bold;">{{eficiencia}}%</span> -->
      <Doughnut id="doughnut" :data="chartData" :options="options" height="100%"/>
    </div>
  </div>
</template>

<script>
import LoadingPage from "../LoadingPage.vue";
import Exporter from "vue-chartjs-exporter";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs';
// import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

ChartJS.register( ArcElement, Tooltip, Legend)

export default {
  components: { LoadingPage, Doughnut },
  name: 'DoughnutChart',
  props: {
    title: {
      required: false,
    },
    isBusy: {
      required: false,
    },
    inputData: {
      required: true,
    },
    filter: {
      required: true,
    },
  },
  data() {
    return {
      filtered: [],
      eficiencia: 0,
      options: {        
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: `Início: ${(this.filter.dateStart).replaceAll('-', '/')} - Final: ${(this.filter.dateEnd).replaceAll('-', '/')}`,
            align: 'start',
            font: {
              weight: 'normal'
            }
          },
          legend: {
            display: false
          },         
          tooltip: {
            enabled: false,
            callbacks: {
              label: function(context) {
                const hours = parseInt(context.parsed / 3600000);
                const min = parseInt((context.parsed - (hours * 3600000)) / 60000)
                const seg = parseInt(((context.parsed - (hours * 3600000)) - (min * 60000)) / 1000)
                return ` ${hours.toString().padStart(2,'0')}:${min.toString().padStart(2,'0')}:${seg.toString().padStart(2,'0')}`
              },  
            }
          },
          datalabels: {
            display: true,            
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map(data => {
                  sum += data;
              });
              let percentage = (value*100 / sum).toFixed(2)+"%";
              return !ctx.dataIndex ? percentage : null;
            },
            color: '#434343',
            labels: {
              title: {
                font: {
                  family: 'Akrobat',
                  weight: 'bold',
                  size: 45
                }
              }
            },
            // anchor: 'start',
            // clamp: false,
            align: 'start',
            offset: 20
          }
        }
      }
    };
  },
  computed: {
    chartData() {
      return {
        labels: ['Arco Aberto', 'Máquina Ligada'],
        datasets: [
          {
            backgroundColor: ['#41B883', 'transparent'],
            cutout: '85%',
            borderRadius: 30,
            data: [
              this.filtered.filter((e) => e.estado == 'ARCO_ABERTO').reduce((sum, actual) => sum += new Date(actual.fim) - new Date(actual.inicio),0),   
              this.filtered.filter((e) => e.estado == 'ARCO_FECHADO').reduce((sum, actual) => sum += new Date(actual.fim) - new Date(actual.inicio),0),        
            ],
            hoverBorderColor: 'transparent'
          }
        ]
      }
    },
  },
  mounted() {
    if(this.inputData.length) this.format(this.inputData);
  },
  watch: {
    inputData() {
      if(this.inputData.length) this.format(this.inputData);
    },
  },
  methods:{
    format(data){
      this.filtered = data.filter((x) => x.estado.indexOf('ARCO') >= 0);
    },
    exportToPDF() {
      this.download_status = "Processing...";
      let doughnut = document.getElementById("doughnut");

      const exp = new Exporter([doughnut]);
      exp.export_pdf().then((pdf) => {
        pdf.save("Eficiência.pdf");
        this.download_status = "Download Charts";
      });
    },
  }
};

</script>