<template>
  <div class="container py-5">
    <div class="card card-table">
      <div
        v-if="rows.length"
        class="card-header"
        style="background-color: white"
      >
        <div class="row align-items-center text-center">
          <div class="col-sm-3">
            <span>Ordem de Serviço</span>
            <p class="fs-5">{{ header.ordem_servico }}</p>
          </div>
          <div class="col-sm-3">
            <span>Ordem de Produção</span>
            <p class="fs-5">{{ header.ordem_producao }}</p>
          </div>
          <div class="col-sm-3">
            <span>Início</span>
            <p class="fs-5">
              {{
                new Date(rows[0].inicio).toLocaleString().replaceAll(",", " ")
              }}
            </p>
          </div>
          <div class="col-sm-3">
            <span>Final</span>
            <p class="fs-5">
              {{
                rows[rows.length - 1].fim
                  ? new Date(rows[rows.length - 1].fim)
                      .toLocaleString()
                      .replaceAll(",", " ")
                  : "PRODUZINDO"
              }}
            </p>
          </div>
          <div class="col-sm-2">
            <span>Máquina Ligada</span>
            <p class="fs-5">
              {{
                formatSeconds(
                  rows
                    .map((r) =>
                      r.configuracaoMedicaoEstado.length
                        ? r.configuracaoMedicaoEstado.find(
                            (m) => m.medicao == "MAQUINA_LIGADA"
                          ).valor
                        : 0
                    )
                    .reduce((acc, value) => acc + +value, 0)
                )
              }}
            </p>
          </div>
          <div class="col-sm-2">
            <span>Máquina Pausada</span>
            <p class="fs-5">
              {{
                formatSeconds(
                  rows
                    .map((r) =>
                      r.configuracaoMedicaoEstado.length
                        ? r.configuracaoMedicaoEstado.find(
                            (m) => m.medicao == "MAQUINA_PAUSADA"
                          ).valor
                        : 0
                    )
                    .reduce((acc, value) => acc + +value, 0)
                )
              }}
            </p>
          </div>
          <div class="col-sm-2">
            <span>Máquina Desligada</span>
            <p class="fs-5">
              {{
                formatSeconds(
                  rows
                    .map((r) =>
                      r.configuracaoMedicaoEstado.length
                        ? r.configuracaoMedicaoEstado.find(
                            (m) => m.medicao == "MAQUINA_DESLIGADA"
                          ).valor
                        : 0
                    )
                    .reduce((acc, value) => acc + +value, 0)
                )
              }}
            </p>
          </div>
          <div class="col-sm-2">
            <span>Tempo de Arco Aberto</span>
            <p class="fs-5">
              {{
                formatSeconds(
                  rows
                    .map((r) =>
                      r.configuracaoMedicaoEstado.length
                        ? r.configuracaoMedicaoEstado.find(
                            (m) => m.medicao == "ARCO_ABERTO"
                          ).valor
                        : 0
                    )
                    .reduce((acc, value) => acc + +value, 0)
                )
              }}
            </p>
          </div>
          <div class="col-sm-2">
            <span>Consumo de Material</span>
            <p class="fs-5">
              {{
                (
                  rows
                    .map((r) =>
                      r.configuracaoMedicaoEstado.length
                        ? r.configuracaoMedicaoEstado.find(
                            (m) => m.medicao == "CONSUMO_MATERIAL"
                          ).valor
                        : 0
                    )
                    .reduce((acc, value) => acc + +value, 0) / 1000
                ).toFixed(2)
              }}
              Kg
            </p>
          </div>
          <div class="col-sm-2">
            <span>Eficiência Total</span>
            <p class="fs-5">
              {{
                (
                  rows
                    .map((r) =>
                      r.configuracaoMedicaoEstado.length
                        ? r.configuracaoMedicaoEstado.find(
                            (m) => m.medicao == "EFICIENCIA_MAQUINA"
                          ).valor
                        : 0
                    )
                    .reduce((acc, value) => acc + +value, 0) / rows.length
                ).toFixed(2)
              }}
              %
            </p>
          </div>
        </div>
        <div class="row align-items-center text-center">
          <nav>
            <div class="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
              <button
                class="nav-link active"
                id="nav-configuracao-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-configuracao"
                type="button"
                role="tab"
                aria-controls="nav-configuracao"
                aria-selected="true"
              >
                Configurações
              </button>
              <button
                class="nav-link"
                id="nav-maquina-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-maquina"
                type="button"
                role="tab"
                aria-controls="nav-maquina"
                aria-selected="false"
              >
                Maquinas
              </button>
              <button
                class="nav-link"
                id="nav-id-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-id"
                type="button"
                role="tab"
                aria-controls="nav-id"
                aria-selected="false"
              >
                IDs
              </button>
              <button
                class="nav-link"
                id="nav-material-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-material"
                type="button"
                role="tab"
                aria-controls="nav-material"
                aria-selected="false"
              >
                Materiais
              </button>
              <button
                class="nav-link"
                id="nav-operador-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-operador"
                type="button"
                role="tab"
                aria-controls="nav-operador"
                aria-selected="false"
              >
                Operadores
              </button>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-configuracao"
              role="tabpanel"
              aria-labelledby="nav-configuracao-tab"
              tabindex="0"
            >
              <div class="container p-3">
                <div
                  v-for="row in rows"
                  :key="row.id"
                  class="card card-table mt-1"
                >
                  <div class="row px-2">
                    <div class="col-sm-4">
                      <span>Máquina</span>
                      <p class="fs-5">
                        {{ row.maquinaConfiguracao.nome }}
                      </p>
                    </div>
                    <div class="col-sm-3">
                      <span>Início</span>
                      <p class="fs-5">
                        {{
                          new Date(row.inicio)
                            .toLocaleString()
                            .replaceAll(",", " ")
                        }}
                      </p>
                    </div>
                    <div class="col-sm-3">
                      <span>Final</span>
                      <p class="fs-5">
                        {{
                          row.fim
                            ? new Date(row.fim)
                                .toLocaleString()
                                .replaceAll(",", " ")
                            : "PRODUZINDO"
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>ID</span>
                      <p class="fs-5">
                        {{ row.atividadeConfiguracao.codigo }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Ligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            row.configuracaoMedicaoEstado.length
                              ? row.configuracaoMedicaoEstado.find(
                                  (m) => m.medicao == "MAQUINA_LIGADA"
                                ).valor
                              : 0
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Pausada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            row.configuracaoMedicaoEstado.length
                              ? row.configuracaoMedicaoEstado.find(
                                  (m) => m.medicao == "MAQUINA_PAUSADA"
                                ).valor
                              : 0
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Desligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            row.configuracaoMedicaoEstado.length
                              ? row.configuracaoMedicaoEstado.find(
                                  (m) => m.medicao == "MAQUINA_DESLIGADA"
                                ).valor
                              : 0
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Tempo de Arco Aberto</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            row.configuracaoMedicaoEstado.length
                              ? row.configuracaoMedicaoEstado.find(
                                  (m) => m.medicao == "ARCO_ABERTO"
                                ).valor
                              : 0
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Consumo de Material</span>
                      <p class="fs-5">
                        {{
                          row.configuracaoMedicaoEstado.length
                            ? (
                                row.configuracaoMedicaoEstado.find(
                                  (m) => m.medicao == "CONSUMO_MATERIAL"
                                ).valor / 1000
                              ).toFixed(2)
                            : 0
                        }}
                        Kg
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Eficiência Total</span>
                      <p class="fs-5">
                        {{
                          row.configuracaoMedicaoEstado.length
                            ? parseFloat(
                                row.configuracaoMedicaoEstado.find(
                                  (m) => m.medicao == "EFICIENCIA_MAQUINA"
                                ).valor
                              ).toFixed(2)
                            : 0
                        }}
                        %
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <span>Operador</span>
                      <p class="fs-5">
                        {{ row.operadorConfiguracao.nome }}
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <span>Material</span>
                      <p class="fs-5 text-truncate">
                        {{ row.materialConfiguracao.codigo }} -
                        {{ row.materialConfiguracao.descricao }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-maquina"
              role="tabpanel"
              aria-labelledby="nav-maquina-tab"
              tabindex="0"
            >
              <div class="container p-3">
                <div
                  v-for="maquina in maquinas"
                  :key="maquina"
                  class="card card-table m-0"
                >
                  <div class="row px-2">
                    <div class="col-sm-4">
                      <span>Máquina</span>
                      <p class="fs-5">
                        {{
                          rows.filter((r) => r.id_maquina == maquina)[0]
                            .maquinaConfiguracao.nome
                        }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <span>Início</span>
                      <p class="fs-5">
                        {{
                          new Date(
                            rows.filter(
                              (r) => r.id_maquina == maquina
                            )[0].inicio
                          )
                            .toLocaleString()
                            .replaceAll(",", " ")
                        }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <span>Final</span>
                      <p class="fs-5">
                        {{
                          rows.filter((r) => r.id_maquina == maquina)[
                            rows.filter((r) => r.id_maquina == maquina).length -
                              1
                          ].fim
                            ? new Date(
                                rows.filter((r) => r.id_maquina == maquina)[
                                  rows.filter((r) => r.id_maquina == maquina)
                                    .length - 1
                                ].fim
                              )
                                .toLocaleString()
                                .replaceAll(",", " ")
                            : "PRODUZINDO"
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Ligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_maquina == maquina)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_LIGADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Pausada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_maquina == maquina)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_PAUSADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Desligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_maquina == maquina)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_DESLIGADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Tempo de Arco Aberto</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_maquina == maquina)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "ARCO_ABERTO"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Consumo de Material</span>
                      <p class="fs-5">
                        {{
                          (
                            rows
                              .filter((r) => r.id_maquina == maquina)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "CONSUMO_MATERIAL"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0) / 1000
                          ).toFixed(2)
                        }}
                        Kg
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Eficiência Total</span>
                      <p class="fs-5">
                        {{
                          (
                            rows
                              .filter((r) => r.id_maquina == maquina)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "EFICIENCIA_MAQUINA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0) /
                            rows.filter((r) => r.id_maquina == maquina).length
                          ).toFixed(2)
                        }}
                        %
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>ID</span>
                      <p
                        class="fs-5 mb-1"
                        v-for="row in rows.filter(
                          (r) => r.id_maquina == maquina
                        )"
                        :key="row.id"
                      >
                        {{ row.atividadeConfiguracao.codigo }}
                      </p>
                    </div>
                    <div class="col-sm-10">
                      <span>Material</span>
                      <p
                        class="fs-5 mb-1 text-truncate"
                        v-for="row in rows.filter(
                          (r) => r.id_maquina == maquina
                        )"
                        :key="row.id"
                      >
                        {{ row.materialConfiguracao.codigo }} -
                        {{ row.materialConfiguracao.descricao }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-id"
              role="tabpanel"
              aria-labelledby="nav-id-tab"
              tabindex="0"
            >
              <div class="container p-3">
                <div v-for="id in ids" :key="id" class="card card-table m-0">
                  <div class="row px-2">
                    <div class="col-sm-4">
                      <span>ID</span>
                      <p class="fs-5">
                        {{
                          rows.filter((r) => r.id_atividade == id)[0]
                            .atividadeConfiguracao.codigo
                        }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <span>Início</span>
                      <p class="fs-5">
                        {{
                          new Date(
                            rows.filter((r) => r.id_atividade == id)[0].inicio
                          )
                            .toLocaleString()
                            .replaceAll(",", " ")
                        }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <span>Final</span>
                      <p class="fs-5">
                        {{
                          rows.filter((r) => r.id_atividade == id)[
                            rows.filter((r) => r.id_atividade == id).length -
                              1
                          ].fim
                            ? new Date(
                                rows.filter((r) => r.id_atividade == id)[
                                  rows.filter((r) => r.id_atividade == id)
                                    .length - 1
                                ].fim
                              )
                                .toLocaleString()
                                .replaceAll(",", " ")
                            : "PRODUZINDO"
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Ligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_atividade == id)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_LIGADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Pausada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_atividade == id)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_PAUSADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Desligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_atividade == id)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_DESLIGADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Tempo de Arco Aberto</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_atividade == id)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "ARCO_ABERTO"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Consumo de Material</span>
                      <p class="fs-5">
                        {{
                          (
                            rows
                              .filter((r) => r.id_atividade == id)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "CONSUMO_MATERIAL"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0) / 1000
                          ).toFixed(2)
                        }}
                        Kg
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Eficiência Total</span>
                      <p class="fs-5">
                        {{
                          (
                            rows
                              .filter((r) => r.id_atividade == id)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "EFICIENCIA_MAQUINA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0) /
                            rows.filter((r) => r.id_atividade == id).length
                          ).toFixed(2)
                        }}
                        %
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina</span>
                      <p
                        class="fs-5 mb-1"
                        v-for="row in rows.filter((r) => r.id_atividade == id)"
                        :key="row.id"
                      >
                        {{ row.maquinaConfiguracao.nome }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <span>Operador</span>
                      <p
                        class="fs-5 mb-1"
                        v-for="row in rows.filter((r) => r.id_atividade == id)"
                        :key="row.id"
                      >
                        {{ row.operadorConfiguracao.nome }}
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <span>Material</span>
                      <p
                        class="fs-5 mb-1 text-truncate"
                        v-for="row in rows.filter((r) => r.id_atividade == id)"
                        :key="row.id"
                      >
                        {{ row.materialConfiguracao.codigo }} -
                        {{ row.materialConfiguracao.descricao }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-material"
              role="tabpanel"
              aria-labelledby="nav-material-tab"
              tabindex="0"
            >
              <div class="container p-3">
                <div
                  v-for="material in materiais"
                  :key="material"
                  class="card card-table m-0"
                >
                  <div class="row px-2">
                    <div class="col-sm-6">
                      <span>Material</span>
                      <p class="fs-5 text-truncate">
                        {{
                          rows.filter((r) => r.id_material == material)[0]
                            .materialConfiguracao.codigo
                        }}
                        -
                        {{
                          rows.filter((r) => r.id_material == material)[0]
                            .materialConfiguracao.descricao
                        }}
                      </p>
                    </div>
                    <div class="col-sm-3">
                      <span>Início</span>
                      <p class="fs-5">
                        {{
                          new Date(
                            rows.filter(
                              (r) => r.id_material == material
                            )[0].inicio
                          )
                            .toLocaleString()
                            .replaceAll(",", " ")
                        }}
                      </p>
                    </div>
                    <div class="col-sm-3">
                      <span>Final</span>
                      <p class="fs-5">
                        {{
                          rows.filter((r) => r.id_material == material)[
                            rows.filter((r) => r.id_material == material).length -
                              1
                          ].fim
                            ? new Date(
                                rows.filter((r) => r.id_material == material)[
                                  rows.filter((r) => r.id_material == material)
                                    .length - 1
                                ].fim
                              )
                                .toLocaleString()
                                .replaceAll(",", " ")
                            : "PRODUZINDO"
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Ligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_material == material)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_LIGADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Pausada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_material == material)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_PAUSADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Desligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_material == material)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_DESLIGADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Tempo de Arco Aberto</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_material == material)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "ARCO_ABERTO"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Consumo de Material</span>
                      <p class="fs-5">
                        {{
                          (
                            rows
                              .filter((r) => r.id_material == material)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "CONSUMO_MATERIAL"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0) / 1000
                          ).toFixed(2)
                        }}
                        Kg
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Eficiência Total</span>
                      <p class="fs-5">
                        {{
                          (
                            rows
                              .filter((r) => r.id_material == material)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "EFICIENCIA_MAQUINA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0) /
                            rows.filter((r) => r.id_material == material).length
                          ).toFixed(2)
                        }}
                        %
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina</span>
                      <p
                        class="fs-5 mb-1"
                        v-for="row in rows.filter(
                          (r) => r.id_material == material
                        )"
                        :key="row.id"
                      >
                        {{ row.maquinaConfiguracao.nome }}
                      </p>
                    </div>
                    <div class="col-sm-8">
                      <span>Operador</span>
                      <p
                        class="fs-5 mb-1"
                        v-for="row in rows.filter(
                          (r) => r.id_material == material
                        )"
                        :key="row.id"
                      >
                        {{ row.operadorConfiguracao.nome }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>ID</span>
                      <p
                        class="fs-5 mb-1"
                        v-for="row in rows.filter(
                          (r) => r.id_material == material
                        )"
                        :key="row.id"
                      >
                        {{ row.atividadeConfiguracao.codigo }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-operador"
              role="tabpanel"
              aria-labelledby="nav-operador-tab"
              tabindex="0"
            >
              <div class="container p-3">
                <div
                  v-for="operador in operadores"
                  :key="operador"
                  class="card card-table m-0"
                >
                  <div class="row px-2">
                    <div class="col-sm-6">
                      <span>Operador</span>
                      <p class="fs-5">
                        {{
                          rows.filter((r) => r.id_operador == operador)[0]
                            .operadorConfiguracao.nome
                        }}
                      </p>
                    </div>
                    <div class="col-sm-3">
                      <span>Início</span>
                      <p class="fs-5">
                        {{
                          new Date(
                            rows.filter(
                              (r) => r.id_operador == operador
                            )[0].inicio
                          )
                            .toLocaleString()
                            .replaceAll(",", " ")
                        }}
                      </p>
                    </div>
                    <div class="col-sm-3">
                      <span>Final</span>
                      <p class="fs-5">
                        {{
                          rows.filter((r) => r.id_operador == operador)[
                            rows.filter((r) => r.id_operador == operador).length -
                              1
                          ].fim
                            ? new Date(
                                rows.filter((r) => r.id_operador == operador)[
                                  rows.filter((r) => r.id_operador == operador)
                                    .length - 1
                                ].fim
                              )
                                .toLocaleString()
                                .replaceAll(",", " ")
                            : "PRODUZINDO"
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Ligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_operador == operador)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_LIGADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Pausada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_operador == operador)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_PAUSADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina Desligada</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_operador == operador)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "MAQUINA_DESLIGADA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Tempo de Arco Aberto</span>
                      <p class="fs-5">
                        {{
                          formatSeconds(
                            rows
                              .filter((r) => r.id_operador == operador)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "ARCO_ABERTO"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0)
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Consumo de Material</span>
                      <p class="fs-5">
                        {{
                          (
                            rows
                              .filter((r) => r.id_operador == operador)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "CONSUMO_MATERIAL"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0) / 1000
                          ).toFixed(2)
                        }}
                        Kg
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Eficiência Total</span>
                      <p class="fs-5">
                        {{
                          (
                            rows
                              .filter((r) => r.id_operador == operador)
                              .map(
                                (m) =>
                                  m.configuracaoMedicaoEstado.find(
                                    (m) => m.medicao == "EFICIENCIA_MAQUINA"
                                  ).valor || 0
                              )
                              .reduce((acc, value) => acc + +value, 0) /
                            rows.filter((r) => r.id_operador == operador).length
                          ).toFixed(2)
                        }}
                        %
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>Máquina</span>
                      <p
                        class="fs-5 mb-1"
                        v-for="row in rows.filter(
                          (r) => r.id_operador == operador
                        )"
                        :key="row.id"
                      >
                        {{ row.maquinaConfiguracao.nome }}
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <span>ID</span>
                      <p
                        class="fs-5 mb-1"
                        v-for="row in rows.filter(
                          (r) => r.id_operador == operador
                        )"
                        :key="row.id"
                      >
                        {{ row.atividadeConfiguracao.codigo }}
                      </p>
                    </div>
                    <div class="col-sm-8">
                      <span>Material</span>
                      <p
                        class="fs-5 mb-1 text-truncate"
                        v-for="row in rows.filter(
                          (r) => r.id_operador == operador
                        )"
                        :key="row.id"
                      >
                        {{ row.materialConfiguracao.codigo }} -
                        {{ row.materialConfiguracao.descricao }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from "../../components/BaseTable.vue";
import LoadingPage from "../../components/LoadingPage.vue";
import ConfigGraphicTimeline from "../../components/Charts/ConfigGraphicTimeline.vue";
import RestResource from "../../services/medicaoEstado";
import RestEstado from "../../services/estado";
import RestConfig from "../../services/configuracao";

export default {
  components: { BaseTable, LoadingPage, ConfigGraphicTimeline },
  data() {
    return {
      id: null,
      isBusy: true,
      rows: [],
      header: {},
      maquinas: [],
      ids: [],
      materiais: [],
      operadores: [],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    if (this.id) {
      this.search();
    } else {
      swal("Oops!", "Configuração não encontrada", "error");
    }
  },
  methods: {
    search() {
      this.isBusy = true;
      RestResource.getCalcByConfiguracao(this.id).then((result) => {
        this.rows = result;
        this.header = {
          ordem_producao: result.length
            ? result[0].ordemProducaoConfiguracao.codigo
            : "",
          ordem_servico: result.length
            ? result[0].ordemProducaoConfiguracao.ordem_servico
            : "",
        };
        const maquinas = result.map((r) => r.id_maquina);
        this.maquinas = maquinas.filter(
          (item, index) => maquinas.indexOf(item) === index
        );
        const ids = result.map((r) => r.id_atividade);
        this.ids = ids.filter((item, index) => ids.indexOf(item) === index);
        const materiais = result.map((r) => r.id_material);
        this.materiais = materiais.filter(
          (item, index) => materiais.indexOf(item) === index
        );
        const operadores = result.map((r) => r.id_operador);
        this.operadores = operadores.filter(
          (item, index) => operadores.indexOf(item) === index
        );
      });
    },
    searchHelpers() {
      RestConfig.getOne(this.id).then((result) => {
        this.info = result;
        this.isBusy = false;
      });
      RestEstado.getAllWithoutPagination({
        withEstadoProducao: true,
        idConfiguracao: this.id,
      }).then((result) => {
        this.estados = result.rows;
        this.isBusy = false;
      });
    },
    formatDate(fim, inicio, pause = false) {
      let horas = "00";
      let min = "00";
      let seg = "00";
      if (fim && !inicio && !pause) {
        horas = parseInt(fim / 1000 / 60 / 60)
          .toString()
          .padStart(2, "0");
        min = parseInt((fim - parseInt(horas) * 1000 * 60 * 60) / 1000 / 60)
          .toString()
          .padStart(2, "0");
        seg = parseInt(
          (fim - parseInt(horas) * 1000 * 60 * 60 - parseInt(min) * 1000 * 60) /
            1000
        )
          .toString()
          .padStart(2, "0");
        return `${horas}:${min}:${seg}`;
      } else {
        let milis = new Date(fim).getTime() - new Date(inicio).getTime();
        if (pause) {
          const estadoMaquina = this.estados.find((e) => e.chave == "MAQUINA");
          if (estadoMaquina) {
            milis =
              milis -
              estadoMaquina.estadoEstadoProducao
                .filter((e) => e.estado == "MAQUINA_PAUSADA")
                .reduce(
                  (sum, actual) =>
                    (sum += new Date(actual.fim) - new Date(actual.inicio)),
                  0
                );
          }
        }
        if (milis) {
          horas = parseInt(milis / 1000 / 60 / 60)
            .toString()
            .padStart(2, "0");
          min = parseInt((milis - parseInt(horas) * 1000 * 60 * 60) / 1000 / 60)
            .toString()
            .padStart(2, "0");
          seg = parseInt(
            (milis -
              parseInt(horas) * 1000 * 60 * 60 -
              parseInt(min) * 1000 * 60) /
              1000
          )
            .toString()
            .padStart(2, "0");
        }
        return `${horas}:${min}:${seg}`;
      }
    },
    formatSeconds(seconds) {
      let horas = "00";
      let min = "00";
      let seg = "00";
      if (!isNaN(+seconds)) {
        horas = parseInt(+seconds / 60 / 60)
          .toString()
          .padStart(2, "0");
        min = parseInt((+seconds - parseInt(horas) * 60 * 60) / 60)
          .toString()
          .padStart(2, "0");
        seg = parseInt(
          +seconds - parseInt(horas) * 60 * 60 - parseInt(min) * 60
        )
          .toString()
          .padStart(2, "0");
      }
      return `${horas}:${min}:${seg}`;
    },
  },
};
</script>
