<template>
  <loading-page v-if="isBusy" />
  <div v-else class="container">
    <div class="row m-3">
      <bar :data="chartData" :options="options" height="200"/>
    </div>
  </div>
</template>

<script>
import LoadingPage from "../LoadingPage.vue";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, TimeScale } from 'chart.js'
import { Bar } from 'vue-chartjs';
import 'chartjs-adapter-date-fns';

ChartJS.register(Title, Tooltip, Legend, TimeScale, BarElement )

export default {
  components: { LoadingPage, Bar },
  name: 'TimeChart',
  props: {
    isBusy: {
      required: false,
    },
    inputData: {
      required: true,
    },
    config: {
      required: false,
    },
  },
  data() {
    return {
      firstEvent: new Date(),
      lastEvent: null,
      labels: [],
      colors: [
        { label: 'ARCO ABERTO', backgroundColor: '#00FF00', data: [] },
        { label: 'MAQUINA LIGADA', backgroundColor: '#FFFF00', data: [] },
        { label: 'MAQUINA PAUSADA', backgroundColor: '#FF8000', data: [] },
        // { label: 'MAQUINA DESLIGADA', backgroundColor: '#FF0000', data: [] },
        // { label: 'ARCO FECHADO', backgroundColor: '#0000FF', data: [] },
        // { label: 'DESCONHECIDO', backgroundColor: '#808080', data: [] },
      ],
      formatted: [],
      options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.8,
        plugins: {
          datalabels: {
            display: false
          },          
          tooltip: {
            callbacks: {
              title: () => "",
              afterBody: (items) => `${new Date(items[0].raw.x[0]).toLocaleString()} - ${new Date(items[0].raw.x[1]).toLocaleString()}`,
              label: (item) => item.dataset.label
            }
          }
        },
        scales: {
          x: {
            // stacked: true,
            min: this.config ? this.config.inicio : this.getMin(),
            max: this.config ? this.config.fim : new Date(),
            type: 'time',
          },
          y: {
            stacked: true,
            beginAtZero: true
          }
        }
      }
    };
  },
  computed: {
    chartData() {
      return {
        // labels: this.labels,
        datasets: this.formatted,
      }
    },
  },
  mounted() {
    if(this.inputData.length) this.format(this.inputData);
  },
  watch: {
    inputData() {
      if(this.inputData.length) this.format(this.inputData);
    },
  },
  methods:{
    getMin(){
      let minValue = new Date();
      this.inputData.forEach((estado) => {
        estado.estadoEstadoProducao.sort((a, b) => a.inicio < b.inicio)
        if (estado.estadoEstadoProducao[0].inicio < minValue) minValue = estado.estadoEstadoProducao[0].inicio;
      });
      return minValue;
    },
    buscaMaterial(estados){
      const idMaterial = [];
      estados.forEach(estado => {
        if(!idMaterial.find((m) => m.id === estado.configuracaoEstadoProducao.materialConfiguracao.id)){
          idMaterial.push(estado.configuracaoEstadoProducao.materialConfiguracao);
        }
      });
      return idMaterial;
    },
    format(dados){
      let chartData = this.colors;
      dados.forEach((estado) => {
        estado.estadoEstadoProducao.forEach((producao) => {
          let label = chartData.find((x) => x.label == producao.estado.replaceAll('_', ' '));
          if (label) {
            label.data.push(
              { x: [new Date(producao.inicio), new Date(producao.fim)], y: this.config.maquinaConfiguracao.nome }
            )
          }
        });
      });
      this.formatted = chartData;
    },
  },
};

</script>

