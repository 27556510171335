'use strict';

import api from './api';

export default {
  /**
   * create: Conecta com o backend para utilização do método POST para criar um calculadora
   * @param {Object} data Informações do que será usado na criação
   */
  create(data) {
    return api.execute('post', `/server/calculadora`, null, data);
  },

  /**
   * update: Conecta com o backend para utilização do método PUT para editar um calculadora
   * @param {Object} data Informações do que será usado na edição
   * @param {*} id Primary key
   */
  update(id, data) {
    return api.execute('put', `/server/calculadora/${id}`, null, data);
  },

  /**
   * getAll: Conecta com o backend para utilização do método GET para receber um ou mais calculadoras como retorno
   */
  getAll(page, rowsPerPage = 20, filters = {}) {
    let queryString = ``;
    for (var key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key) && filters[key]) {
        queryString += `&${key}=${filters[key]}`;
      }
    }
    return api.execute('get', `/server/calculadora?${queryString}`, page || 1, {rowsPerPage:rowsPerPage});
  },
  
  /**
   * getAllWithoutPagination: Conecta com o backend para utilização do método GET para receber um ou mais calculadoras como retorno sem paginação
   */
  getAllWithoutPagination() {
    return api.execute('get', `/server/calculadora/all`, null, null, true);
  },

  /**
   * getAllByMaquinaWithoutPagination: Conecta com o backend para utilização do método GET para receber um ou mais calculadoras como retorno sem paginação
   * @param {*} idMaquina Primary key
   */
  getAllByMaquinaWithoutPagination(idMaquina) {
    return api.execute('get', `/server/calculadora/?idMaquina=${idMaquina}`, null, null, true);
  },

  /**
   * getAllByMaquina: Conecta com o backend para utilização do método GET para receber um ou mais calculadoras como retorno
   * @param {*} idMaquina Primary key
   * @param {Number} page Número de paginação
   */
  getAllByMaquina(idMaquina, page, rowsPerPage = 20) {
    return api.execute('get', `/server/calculadora/?idMaquina=${idMaquina}`, page || 1,{rowsPerPage:rowsPerPage});
  },

  /**
   * getOne: Conecta com o backend para utilização do método GET para receber um calculadora como retorno
   * @param {Number} id Primary key
   */
  getOne(id) {
    return api.execute('get', `/server/calculadora/${id}`);
  },

  /**
   * delete: Conecta com o backend para utilização do método DELETE para receber um calculadora
   * @param {Number} id Primary key
   */
  delete(id) {
    return api.execute('delete', `/server/calculadora/${id}`);
  },
}