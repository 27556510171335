<template>
  <div v-if="this.row" class="container pt-6">
    <div class="row">
      <div class="col-8 col-md-10">
        <div class="row py-1">
          <div class="col-12 col-md-3">
            <div class="container-fluid">
              <div
                class="row align-items-center bg-white rounded-top"
                :class="`bt-${row.colorMaquina} text-${row.colorMaquina}`"
              >
                <div class="col-auto"><i class="bi bi-clipboard py-1"></i></div>
                <div class="col p-0 text-truncate">
                  <span class="fs-5">Ordem de Produção</span>
                </div>
              </div>
              <div
                class="row align-items-center rounded-bottom bg-gray text-white py-1"
              >
                <span class="fs-4 text-truncate">{{
                  row.maquinaConfiguracao.length
                    ? row.maquinaConfiguracao[0].ordemProducaoConfiguracao
                        .codigo
                    : "SEM ORDEM"
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="container-fluid">
              <div
                class="row align-items-center bg-white rounded-top"
                :class="`bt-${row.colorMaquina} text-${row.colorMaquina}`"
              >
                <div class="col-auto"><i class="bi bi-person py-1"></i></div>
                <div class="col p-0 text-truncate">
                  <span class="fs-5">Operador</span>
                </div>
              </div>
              <div
                class="row align-items-center rounded-bottom bg-gray text-white py-1"
              >
                <span class="fs-4 text-truncate">{{
                  row.maquinaConfiguracao.length
                    ? row.maquinaConfiguracao[0].operadorConfiguracao.nome
                    : "SEM OPERADOR"
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="container-fluid">
              <div
                class="row align-items-center bg-white rounded-top"
                :class="`bt-${row.colorMaquina} text-${row.colorMaquina}`"
              >
                <div class="col-auto"><i class="bi bi-boxes py-1"></i></div>
                <div class="col p-0 text-truncate">
                  <span class="fs-5">ID</span>
                </div>
              </div>
              <div
                class="row align-items-center rounded-bottom bg-gray text-white py-1"
              >
                <span class="fs-4 text-truncate">{{
                  row.maquinaConfiguracao.length
                    ? row.maquinaConfiguracao[0].atividadeConfiguracao.descricao
                    : "SEM ATIVIDADE"
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="container-fluid">
              <div
                class="row align-items-center bg-white rounded-top"
                :class="`bt-${row.colorMaquina} text-${row.colorMaquina}`"
              >
                <div class="col-auto"><i class="bi bi-boxes py-1"></i></div>
                <div class="col p-0 text-truncate">
                  <span class="fs-5">Ordem de Serviço</span>
                </div>
              </div>
              <div
                class="row align-items-center rounded-bottom bg-gray text-white py-1"
              >
                <span class="fs-4 text-truncate">{{
                  row.maquinaConfiguracao.length
                    ? row.maquinaConfiguracao[0].ordemProducaoConfiguracao
                        .ordem_servico
                    : "SEM ORDEM DE SERVIÇO"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row py-1">
          <div class="col-12 col-md-4">
            <div class="container-fluid">
              <div
                class="row align-items-center bg-white rounded-top"
                :class="`bt-${row.colorMaquina} text-${row.colorMaquina}`"
              >
                <div class="col-auto"><i class="bi bi-cpu py-1"></i></div>
                <div class="col p-0 text-truncate">
                  <span class="fs-5">Máquina</span>
                </div>
              </div>
              <div
                class="row align-items-center rounded-bottom bg-gray text-white py-1"
              >
                <span class="fs-4 text-truncate"
                  >{{ row.mac }} / {{ row.tipoProcessoMaquina.nome }}</span
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="container-fluid">
              <div
                class="row align-items-center bg-white rounded-top"
                :class="`bt-${row.colorMaquina} text-${row.colorMaquina}`"
              >
                <div class="col-auto"><i class="bi bi-calendar py-1"></i></div>
                <div class="col p-0 text-truncate">
                  <span class="fs-5">Última Configuração</span>
                </div>
              </div>
              <div
                class="row align-items-center rounded-bottom bg-gray text-white py-1"
              >
                <span class="fs-4 text-truncate">{{
                  row.maquinaConfiguracao.length
                    ? new Date(row.maquinaConfiguracao[0].inicio)
                        .toLocaleString()
                        .replaceAll(",", " -")
                    : "SEM CONFIGURAÇÃO"
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="container-fluid">
              <div
                class="row align-items-center bg-white rounded-top"
                :class="`bt-${row.colorMaquina} text-${row.colorMaquina}`"
              >
                <div class="col-auto"><i class="bi bi-box py-1"></i></div>
                <div class="col p-0 text-truncate">
                  <span class="fs-5"
                    >Material
                    {{
                      row.maquinaConfiguracao.length
                        ? " - " +
                          row.maquinaConfiguracao[0].materialConfiguracao.codigo
                        : ""
                    }}</span
                  >
                </div>
              </div>
              <div
                class="row align-items-center rounded-bottom bg-gray text-white py-1"
              >
                <span
                  class="fs-4 text-truncate tooltiph"
                  data-bs-toggle="tooltip"
                  :data-bs-title="
                    row.maquinaConfiguracao.length
                      ? row.maquinaConfiguracao[0].materialConfiguracao
                          .descricao
                      : 'SEM MATERIAL'
                  "
                >
                  {{
                    row.maquinaConfiguracao.length
                      ? row.maquinaConfiguracao[0].materialConfiguracao
                          .descricao
                      : "SEM MATERIAL"
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 col-md-2 text-center text-white py-1">
        <div class="container-fluid" style="height: 100%">
          <div
            class="row align-items-center bg-gray rounded-top"
            style="height: 50%"
          >
            <div class="col p-0">
              <span class="fs-6">TEMPO MAQUINA LIGADA</span>
              <h6 class="fs-4 m-0">
                {{
                  row.maquinaConfiguracao.length
                    ? formatDate(
                        row.maquinaConfiguracao[0].fim || new Date(),
                        row.maquinaConfiguracao[0].inicio
                      )
                    : "00:00:00"
                }}
              </h6>
            </div>
          </div>
          <div
            class="row align-items-center bg-gray rounded-bottom"
            style="height: 50%"
            :class="`bg-${row.colorMaquina}`"
          >
            <div class="col p-0">
              <h6 class="fs-2 m-0 bold">
                {{
                  row.colorMaquina == "online"
                    ? "PRODUZINDO"
                    : row.colorMaquina == "warning"
                    ? "EM PAUSA"
                    : row.online
                    ? "LIGADA"
                    : "DESLIGADA"
                }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" style="height: 20rem">
      <div class="col-12 col-md-8 p-0">
        <div class="row py-1 text-white text-center h-100">
          <div class="col">
            <div
              class="row align-items-center mx-1 rounded-top"
              :class="` bg-${row.colorArco}`"
              style="height: 10%"
            >
              <div class="col p-0">
                <span class="fs-5 bold">ARCO</span>
              </div>
            </div>
            <div
              class="row mx-1 p-2 rounded-bottom bg-white"
              style="height: 90%"
            >
              <div class="col-4 col-md-3">
                <div
                  class="row align-items-center bg-gray rounded-top"
                  style="height: 20%"
                >
                  <span class="fs-4">ESTADO</span>
                </div>
                <div
                  class="row align-items-center rounded-bottom"
                  :class="`bg-${row.colorArco}`"
                  style="height: 45%"
                >
                  <span v-if="row.maquinaMaquinaMedidor.length" class="fs-3">{{
                    row.maquinaMaquinaMedidor
                      .find((m) => m.chave == "CORRENTE")
                      .estado?.situacao_arco.replaceAll("_", " ") ||
                    "INDEFINIDO"
                  }}</span>
                  <span v-else class="fs-3">SEM ESTADO ARCO</span>
                </div>
                <div
                  class="row align-items-center rounded pt-2"
                  style="height: 35%"
                >
                  <div
                    class="col rounded h-100 p-2"
                    :class="`bg-${row.colorArco}`"
                  >
                    <h6 class="fs-5 m-0">TEMPO ARCO ABERTO</h6>
                    <span class="fs-4">
                      {{
                        formatSeconds(
                          row.maquinaMedicaoEstado.length
                            ? row.maquinaMedicaoEstado.find(
                                (c) => c.medicao == "ARCO_ABERTO"
                              ).valor || 0
                            : 0
                        )
                      }}
                      <!-- {{
                      row.maquinaMedicaoEstado.length
                        ? formatDate(
                            row.estadoArco.fim || new Date(),
                            row.estadoArco.inicio
                          )
                        : "00:00:00"
                    }} -->
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-8 col-md-9 text-black">
                <div class="row pb-1" style="height: 50%">
                  <div class="col-12 col-md-4 text-center">
                    <div
                      class="container-fluid rounded border"
                      style="height: 100%"
                    >
                      <div class="row align-items-center" style="height: 40%">
                        <div class="col h-100">
                          <span class="fs-6">CORRENTE MÍNIMA</span>
                        </div>
                      </div>
                      <div class="row align-items-center" style="height: 60%">
                        <div class="col h-100">
                          <span class="fs-2 bold">
                            {{
                              parseFloat(
                                row.maquinaMedicaoEstado.length
                                  ? row.maquinaMedicaoEstado.find(
                                      (c) => c.medicao == "CORRENTE_MINIMA"
                                    ).valor || 0
                                  : 0
                              ).toFixed(1)
                            }}
                            A</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                    <div
                      class="container-fluid rounded border"
                      style="height: 100%"
                    >
                      <div class="row align-items-center" style="height: 40%">
                        <div class="col h-100">
                          <span class="fs-6">CORRENTE MÉDIA</span>
                        </div>
                      </div>
                      <div class="row align-items-center" style="height: 60%">
                        <div class="col h-100">
                          <span class="fs-2 bold"
                            >{{
                              parseFloat(
                                row.maquinaMedicaoEstado.length
                                  ? row.maquinaMedicaoEstado.find(
                                      (c) => c.medicao == "CORRENTE_MEDIA"
                                    ).valor || 0
                                  : 0
                              ).toFixed(1)
                            }}
                            A</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                    <div
                      class="container-fluid rounded border"
                      style="height: 100%"
                    >
                      <div class="row align-items-center" style="height: 40%">
                        <div class="col h-100">
                          <span class="fs-6">CORRENTE MÁXIMA</span>
                        </div>
                      </div>
                      <div class="row align-items-center" style="height: 60%">
                        <div class="col h-100">
                          <span class="fs-2 bold"
                            >{{
                              parseFloat(
                                row.maquinaMedicaoEstado.length
                                  ? row.maquinaMedicaoEstado.find(
                                      (c) => c.medicao == "CORRENTE_MAXIMA"
                                    ).valor || 0
                                  : 0
                              ).toFixed(1)
                            }}
                            A</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pt-1" style="height: 50%">
                  <div class="col-12 col-md-4 text-center">
                    <div
                      class="container-fluid rounded border"
                      style="height: 100%"
                    >
                      <div class="row align-items-center" style="height: 40%">
                        <div class="col h-100">
                          <span class="fs-6">VELOCIDADE MÍNIMA</span>
                        </div>
                      </div>
                      <div class="row align-items-center" style="height: 60%">
                        <div class="col h-100">
                          <span class="fs-2 bold"
                            >{{
                              parseFloat(
                                row.maquinaMedicaoEstado.length
                                  ? row.maquinaMedicaoEstado.find(
                                      (c) => c.medicao == "VELOCIDADE_MINIMA"
                                    ).valor || 0
                                  : 0
                              ).toFixed(1)
                            }}
                            m/min</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                    <div
                      class="container-fluid rounded border"
                      style="height: 100%"
                    >
                      <div class="row align-items-center" style="height: 40%">
                        <div class="col h-100">
                          <span class="fs-6">VELOCIDADE MÉDIA</span>
                        </div>
                      </div>
                      <div class="row align-items-center" style="height: 60%">
                        <div class="col h-100">
                          <span class="fs-2 bold"
                            >{{
                              parseFloat(
                                row.maquinaMedicaoEstado.length
                                  ? row.maquinaMedicaoEstado.find(
                                      (c) => c.medicao == "VELOCIDADE_MEDIA"
                                    ).valor || 0
                                  : 0
                              ).toFixed(1)
                            }}
                            m/min</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                    <div
                      class="container-fluid rounded border"
                      style="height: 100%"
                    >
                      <div class="row align-items-center" style="height: 40%">
                        <div class="col h-100">
                          <span class="fs-6">VELOCIDADE MÁXIMA</span>
                        </div>
                      </div>
                      <div class="row align-items-center" style="height: 60%">
                        <div class="col h-100">
                          <span class="fs-2 bold"
                            >{{
                              parseFloat(
                                row.maquinaMedicaoEstado.length
                                  ? row.maquinaMedicaoEstado.find(
                                      (c) => c.medicao == "VELOCIDADE_MAXIMA"
                                    ).valor || 0
                                  : 0
                              ).toFixed(1)
                            }}
                            m/min</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row py-1 text-white text-center" style="height: 50%">
          <div class="col">
            <div
              class="row align-items-center rounded-top"
              :class="`bg-${row.colorMaquina}`"
              style="height: 20%"
            >
              <div class="col p-0">
                <span class="fs-5 bold">MÁQUINA</span>
              </div>
            </div>
            <div class="row p-1 rounded-bottom bg-white" style="height: 80%">
              <div class="col text-black">
                <div class="row h-100">
                  <div class="col-4 text-center">
                    <div
                      class="container-fluid rounded border"
                      style="height: 100%"
                    >
                      <div class="row align-items-center" style="height: 30%">
                        <span class="fs-7">TENSÃO MÍNIMA</span>
                      </div>
                      <div class="row align-items-center" style="height: 70%">
                        <span class="fs-2 bold"
                          >{{
                            parseFloat(
                              row.maquinaMedicaoEstado.length
                                ? row.maquinaMedicaoEstado.find(
                                    (c) => c.medicao == "TENSAO_MINIMA"
                                  ).valor || 0
                                : "0"
                            ).toFixed(1)
                          }}
                          V</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-4 text-center">
                    <div
                      class="container-fluid rounded border"
                      style="height: 100%"
                    >
                      <div class="row align-items-center" style="height: 30%">
                        <span class="fs-7">TENSÃO MÉDIA</span>
                      </div>
                      <div class="row align-items-center" style="height: 70%">
                        <span class="fs-2 bold"
                          >{{
                            parseFloat(
                              row.maquinaMedicaoEstado.length
                                ? row.maquinaMedicaoEstado.find(
                                    (c) => c.medicao == "TENSAO_MEDIA"
                                  ).valor || 0
                                : "0"
                            ).toFixed(1)
                          }}
                          V</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-4 text-center">
                    <div
                      class="container-fluid rounded border"
                      style="height: 100%"
                    >
                      <div class="row align-items-center" style="height: 30%">
                        <span class="fs-7">TENSÃO MÁXIMA</span>
                      </div>
                      <div class="row align-items-center" style="height: 70%">
                        <span class="fs-2 bold"
                          >{{
                            parseFloat(
                              row.maquinaMedicaoEstado.length
                                ? row.maquinaMedicaoEstado.find(
                                    (c) => c.medicao == "TENSAO_MAXIMA"
                                  ).valor || 0
                                : "0"
                            ).toFixed(1)
                          }}
                          V</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row py-1 text-white text-center" style="height: 50%">
          <div class="col">
            <div
              class="row align-items-center rounded-top"
              :class="`bg-${row.colorMaquina}`"
              style="height: 20%"
            >
              <div class="col p-0">
                <span class="fs-5 bold">CONTROLE DE MATERIAL</span>
              </div>
            </div>
            <div class="row p-1 rounded-bottom bg-white" style="height: 80%">
              <div class="col text-black">
                <div class="row" style="height: 100%">
                  <div class="col mx-1 rounded border text-center">
                    <div class="row align-items-center" style="height: 30%">
                      <span class="fs-7">CONSUMO DE MATERIAL</span>
                    </div>
                    <div class="row align-items-center" style="height: 70%">
                      <span class="fs-2 bold"
                        >{{
                          parseFloat(
                            row.maquinaMedicaoEstado.length
                              ? row.maquinaMedicaoEstado.find(
                                  (c) => c.medicao == "CONSUMO_MATERIAL"
                                ).valor / 1000 || 0
                              : 0
                          ).toFixed(1)
                        }}
                        Kg</span
                      >
                    </div>
                  </div>
                  <!-- <div class="col mx-1 rounded border text-center">
                    <div class="row align-items-center" style="height: 30%">
                      <span class="fs-7">VELOCIDADE ATUAL</span>
                    </div>
                    <div class="row align-items-center" style="height: 70%">
                      <span class="fs-2 bold"
                        >{{
                          parseFloat(
                            row.maquinaMedicaoEstado.length
                              ? row.maquinaMedicaoEstado.find(
                                  (c) => c.medicao == "VELOCIDADE"
                                ).valor || 0
                              : 0
                          ).toFixed(1)
                        }}
                        m/min</span
                      >
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RestResource from "../../services/dashboard";
import LoadingPage from "../../components/LoadingPage.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.js";
import moment from "moment";

export default {
  components: {
    LoadingPage,
  },
  data() {
    return {
      tooltips: null,
      id: null,
      isBusy: true,
      row: null,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    if (this.id) {
      this.search();
    } else {
      swal("Oops!", "Máquina não encontrada", "error");
    }
  },
  updated() {
    if (!this.tooltips) {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      this.tooltips = [...tooltipTriggerList].map(
        (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
      );
    }
    this.tooltips.map((t) => t.hide());
  },
  methods: {
    formatDate(fim, inicio) {
      let horas = "00";
      let min = "00";
      let seg = "00";
      const milis = new Date(fim).getTime() - new Date(inicio).getTime();
      if (milis) {
        horas = parseInt(milis / 1000 / 60 / 60)
          .toString()
          .padStart(2, "0");
        min = parseInt((milis - parseInt(horas) * 1000 * 60 * 60) / 1000 / 60)
          .toString()
          .padStart(2, "0");
        seg = parseInt(
          (milis -
            parseInt(horas) * 1000 * 60 * 60 -
            parseInt(min) * 1000 * 60) /
            1000
        )
          .toString()
          .padStart(2, "0");
      }
      return `${horas}:${min}:${seg}`;
    },
    formatSeconds(seconds) {
      let horas = "00";
      let min = "00";
      let seg = "00";
      if (!isNaN(+seconds)) {
        horas = parseInt(+seconds / 60 / 60)
          .toString()
          .padStart(2, "0");
        min = parseInt((+seconds - parseInt(horas) * 60 * 60) / 60)
          .toString()
          .padStart(2, "0");
        seg = parseInt(
          +seconds - parseInt(horas) * 60 * 60 - parseInt(min) * 60
        )
          .toString()
          .padStart(2, "0");
      }
      return `${horas}:${min}:${seg}`;
    },
    search() {
      if (this.$route.name != "dashboardMaquina") return;
      RestResource.getOne(this.id).then((result) => {
        if (result) {
          if (
            result.maquinaEstadoProducao.length &&
            result.maquinaEstadoProducao[0].estado == "MAQUINA_PAUSADA" &&
            !result.maquinaEstadoProducao[0].fim
          ) {
            result.colorMaquina = "warning";
          } else if (
            result.maquinaConfiguracao.length &&
            !result.maquinaConfiguracao[0].fim
          ) {
            result.colorMaquina = "online";
          } else {
            result.colorMaquina = "secondary";
          }
          if (
            result.maquinaMaquinaMedidor.length &&
            result.maquinaMaquinaMedidor.find((m) => m.chave == "CORRENTE")
              .estado?.situacao_arco == "ARCO_ABERTO"
          ) {
            result.colorArco = "online";
          } else {
            result.colorArco = "secondary";
          }
          result.online =
            moment().diff(result.last_sync, "YYYY-MM-DDTHH:mm:ss", "minutes") <
            1000 * 60 * 10
              ? true
              : false;
          this.row = result;
        }
        this.isBusy = false;
        setTimeout(this.search, 1000 * 10);
      });
    },
  },
};
</script>

<style scoped>
.card {
  margin-top: 0.5rem;
}
.pt-6 {
  padding-top: 5rem !important;
}
.col-auto > .bi {
  font-size: 1.5rem;
}
.card-white {
  background-color: whitesmoke !important;
}
.carousel {
  background-color: lightgray;
}
.border-online {
  border: 0.2rem solid #41b731 !important;
}
.bt-online {
  border-top: 0.3rem solid #41b731 !important;
}
.bt-danger {
  border-top: 0.3rem solid #dc3545 !important;
}
.bt-secondary {
  border-top: 0.3rem solid #6c757d !important;
}
.bt-warning {
  border-top: 0.3rem solid #ffc107 !important;
}
.bold {
  font-weight: bold;
}
.bg-gray {
  background-color: #adb5bd;
}
.bg-online {
  background-color: #41b731;
}
.bg-danger {
  background-color: #dc3545;
}
.bg-warning {
  background-color: #ffc107;
}
.text-online {
  color: #41b731;
}
.fs-7 {
  font-size: 0.9rem !important;
}
</style>
