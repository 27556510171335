<template>
    <div class="select-label d-flex align-items-center justify-content-end">
        <label for="" class="me-4" style="white-space: nowrap">Itens por página:</label>
        <select v-model="selectedValue" class="form-select form-select-sm custom-select" aria-label=".form-select-sm">
            <option v-for="option in pageOptions" :key="option" selected>{{ option }}</option>
        </select>
    </div>
</template>

<script>

export default {
  name: 'ItemsPerPage',
  props: {
    rowsPerPage: {
      type: Number,
      required: true,
    },
  },
  emits: {
    onSelect: {
        required: true
    }
  },
  data() {
    return {
      selectedValue: null,
      pageOptions: [10, 20, 50, 100],
    };
  },
  mounted() {
    this.selectedValue = this.rowsPerPage;
  },
  watch: {
    selectedValue() {
      if(this.selectedValue != this.rowsPerPage) this.$emit('onSelect', this.selectedValue);
    },
    rowsPerPage() {
      if (this.selectedValue != this.rowsPerPage) this.selectedValue = this.rowsPerPage;
    },
  },
};

</script>
