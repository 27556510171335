'use strict';

import api from './api';

export default {
  /**
   * create: Conecta com o backend para utilização do método POST para criar uma auditoria
   * @param {Object} data Informações do que será usado na criação
   */
  create(data) {
    return api.execute('post', `/server/auditoria`, null, data);
  },

  /**
   * update: Conecta com o backend para utilização do método PUT para editar uma auditoria
   * @param {Object} data Informações do que será usado na edição
   * @param {*} id Primary key
   */
  update(id, data) {
    return api.execute('put', `/server/auditoria/${id}`, null, data);
  },

  /**
   * getAll: Conecta com o backend para utilização do método GET para receber uma ou mais auditorias como retorno
   */
    getAll(page, rowsPerPage = 20, filters = {}) {
    let queryString = ``;
    for (var key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key) && filters[key]) {
        queryString += `&${key}=${filters[key]}`;
      }
    }  
    return api.execute('get', `/server/auditoria?${queryString}`, page || 1, {rowsPerPage:rowsPerPage});
  },

  /**
   * getAllByUsuarioWithoutPagination: Conecta com o backend para utilização do método GET para receber uma ou mais auditorias como retorno sem paginação
   * @param {*} idUsuario Primary key
   */
  getAllByUsuarioWithoutPagination(idUsuario) {
    return api.execute('get', `/server/auditoria/?idUsuario=${idUsuario}`, null, null, true);
  },

  /**
   * getAllByUsuario: Conecta com o backend para utilização do método GET para receber uma ou mais auditorias como retorno
   * @param {*} idUsuario Primary key
   * @param {Number} page Número de paginação
   */
  getAllByUsuario(idUsuario, page, rowsPerPage = 20) {
    return api.execute('get', `/server/auditoria/?idUsuario=${idUsuario}`, page || 1,{rowsPerPage:rowsPerPage});
  },

  /**
   * getOne: Conecta com o backend para utilização do método GET para receber uma auditoria como retorno
   * @param {Number} id Primary key
   */
  getOne(id) {
    return api.execute('get', `/server/auditoria/${id}`);
  },

  /**
   * delete: Conecta com o backend para utilização do método DELETE para receber uma auditoria
   * @param {Number} id Primary key
   */
  delete(id) {
    return api.execute('delete', `/server/auditoria/${id}`);
  },
}