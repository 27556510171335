<template>
  <NavBar :pageTitle="pageTitle" />
  <div>
    <!-- <transition name="fade" mode="out-in">
      <router-view />
    </transition> -->
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <!-- <router-view /> -->
</template>

<script>
import './assets/fonts/style.css';
import './assets/style/global.css';
import './assets/style/buttons.css';
import './assets/style/color.css';

import NavBar from './components/Bar/NavBar.vue';

export default {
name: 'App',
components: {
  NavBar,
},
data() {
  return {
    pageTitle: '',
  };
},
watch: {
  $route() {
    this.setPageTitle();
  },
},
methods: {
  setPageTitle() {
    this.pageTitle = this.$route.meta.title || '';
  },
},
created() {
  this.setPageTitle();
},
}
</script>

<style>

</style>
