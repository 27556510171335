<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm">
            <form ref="form">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-sm-4">
                            <label class="form-label">Chave*</label>
                            <input v-model="model.chave" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Chave.
                            </div>
                        </div>
                        <div class="col-sm-4">
                          <base-select
                          label="Material"
                          :value="model.id_material"
                          textField="descricao"
                          route="material"
                          @onSelect="(id) => { model.id_material = id }"
                          />
                        </div>
                        <div class="col-sm-4">
                            <label class="form-label">Valor</label>
                            <input v-model="model.valor" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Valor.
                            </div>
                        </div>
                    </div>
                    <div class="row py-2">
                      <confirm-buttons
                        :habExclusao="false"
                        @cancelar="cancelAdd"
                        @confirmar="save"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert';
import BaseSelect from '../../components/BaseSelect.vue';
import ConfirmButtons from "../../components/ConfirmButtons.vue"
import RestResource from "../../services/caracteristicaMaterial";


export default{
  components: { ConfirmButtons, BaseSelect },
  data(){
    return {
      id: null,
      isBusy: false,
      model: {
        chave: '',
        valor: '',
        id_material: null,
      },
      material: {
        options: [],
        disabled: true
      },
    }
  },
  methods: {
    save() {
      if (!this.$refs.form.checkValidity()) {
          this.$refs.form.classList.add('was-validated');
          return;
      }

      RestResource.create(this.model).then(result => {
        if(result.error){
          swal({
          title: "Oops!",
          text:
            result.message,
          icon: "error",
          button: "Continuar..."
          }).then(() => {
          this.isBusy = false;
          });
        } else {
          swal({
            title: "Bom trabalho!",
            text:
              "A caracteristica do material foi inserida na base de dados com sucesso!",
            icon: "success",
            button: "Continuar..."
          }).then(() => {
            this.$router.replace('/caracteristicaMaterial');
            this.isBusy = false;
          });
        }
      },() => {
        swal({
          title: "Oops!",
          text:
            "Ocorreu um problema ao tentar inserir a caracteristica do material.",
          icon: "error",
          button: "Continuar..."
        }).then(() => {
          this.isBusy = false;
        });
      });
    },
    cancelAdd() {
      swal({
        title: "Cancelamento de criação de registro",
        text: "Deseja cancelar a criação da caracteristica do material?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          this.$router.push('/caracteristicaMaterial');
        }
      });
    },

  }
}

</script>
