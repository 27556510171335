<template>
  <div class="container py-5">
    <div class="card mb-4 rounded-3 shadow-sm">
      <div class="container-fluid">
        <div class="row py-2">
          <div class="col">
            <label class="form-label">Selecionar Planilha .CSV</label>
            <input
              type="file"
              class="form-control"
              accept=".csv"
              @change="
                (e) => {
                  isBusy = true;
                  handleFileUpload(e);
                }
              "
            />
            <div class="form-text" id="basic-addon4">
              Inserir um arquivo .csv com 3 colunas (OS, OP e ID)
            </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col">
            <div class="row">
              <label class="form-label">
                Pré-Visualização das Informações da Planilha
              </label>
            </div>
            <loading-page v-if="isBusy" />
            <div
              v-if="!isBusy && csvFile.length"
              class="row p-2"
              style="max-height: 40vh; overflow: auto"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">OS (Ordem de Serviço)</th>
                    <th scope="col">OP (Ordem de Produção)</th>
                    <th scope="col">ID (Identificador)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="op in csvFile" :key="op">
                    <td>{{ op[0] }}</td>
                    <td>{{ op[1] }}</td>
                    <td>{{ op[2] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col text-end">
            <button
              @click="saveCsv"
              type="button"
              class="btn btn-success text-light btn-no-hover"
              :disabled="isBusy || !csvFile.length"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import pako from "pako";
import swal from "sweetalert";
import LoadingPage from "@/components/LoadingPage.vue";
import RestResource from "../../services/ordemProducao";

export default {
  components: { LoadingPage },
  data() {
    return {
      isBusy: false,
      csvFile: [],
    };
  },
  methods: {
    handleFileUpload(event) {
      const csvFile = event.target.files[0];
      if (!csvFile) {
        alert("Please select a file!");
        return;
      }

      Papa.parse(csvFile, {
        complete: this.handleParseComplete,
        error: this.handleParseError,
      });
    },
    handleParseComplete(results) {
      if (!results.data.length || results.data[0].length > 3) {
        return this.handleParseError("Erro de Formatação!");
      }
      results.data.shift();
      this.csvFile = results.data;
      this.isBusy = false;
    },
    handleParseError(error) {
      swal({
        title: "Oops!",
        text: `Error reading CSV file:, ${error}`,
        icon: "error",
        button: "Continuar...",
      });
      this.isBusy = false;
    },
    async saveCsv() {
      this.isBusy = true;
      // const compactFile = await pako.gzip(JSON.stringify(['teste']));
      const compactFile = pako.gzip(JSON.stringify(this.csvFile));
      console.log(compactFile.length, compactFile.byteLength / (1024 * 1024))
      RestResource.importOrdemFromCsv(compactFile).then(
        (result) => {
          if (result.error) {
            swal({
              title: "Oops!",
              text: result.message,
              icon: "error",
              button: "Continuar...",
            }).then(() => {
              this.isBusy = false;
            });
          } else {
            swal({
              title: "Bom trabalho!",
              text: "A ordem de produção foi atualizada na base de dados com sucesso!",
              icon: "success",
              button: "Continuar...",
            }).then(() => {
              this.$router.replace("/ordemProducao");
              this.isBusy = false;
            });
          }
        },
        () => {
          swal({
            title: "Oops!",
            text: "Ocorreu um problema ao tentar atualizar a ordem de produção.",
            icon: "error",
            button: "Continuar...",
          }).then(() => {
            this.isBusy = false;
          });
        }
      );
    },
  },
};
</script>
