<template>
  <label v-if="label" class="form-label">{{ label }}</label>
  <div class="d-flex input-group">
    <select v-model="selectedValue" class="form-select" required>
        <option v-for="option in options" 
        :key="option.value" 
        :value="option.value">{{ option.text }}</option>
      </select>
  </div>
</template>

<script>

export default {
  name: 'BaseTable',
  props: {
    label: {
      required: false,
    },
    value: {
      required: true,
    },
    textField: {
      required: false,
    },
    options: {
      required: true
    }
  },
  emits: {
    onSelect: {
      required: true
    }
  },
  data() {
    return {
      textSelect: 'nome',
      selectedValue: null,
      rest: null,
    };
  },
  mounted() {
    this.selectedValue = this.value;
    if(this.textField) this.textSelect = this.textField;
  },
  watch: {
    selectedValue() {
      this.$emit('onSelect', this.selectedValue);
    },
    value() {
      if (this.selectedValue != this.value) this.selectedValue = this.value;
    },
  },
  methods: {
    
  }
};

</script>

<style scoped>
.form-label {
  font-weight: bold;
}
</style>