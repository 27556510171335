<template>
  <loading-page v-if="isBusy" />
  <div v-else class="container py-5">
    <div class="card mb-4 rounded-3 shadow-sm border-card">
      <div class="card-header bg-white">
        <h5 class="py-2 title-list">Para acessar essa página é necessario se autenticar</h5>
      </div>
      <div class="card-body p-0">
        <form @submit.prevent="login">
          <div class="mb-3">
            <div class="input-form">
                <h6 for="Email" class="form-label search">Email</h6>
                <input v-model="email" type="email" class="form-control">
            </div>
            <div class="input-form">
                <h6 for="Senha" class="form-label search">Senha</h6>
                <input v-model="password" type="password" class="form-control">
            </div>
          </div>
          <div class="text-end pt-5">
            <button :disabled="isBusy" type="submit" class="btn btn-pesquisar text-light">
                <i class="bi bi-box-arrow-in-right" style="font-size: 1.3rem;"></i>
                Entrar
            </button>    
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert';
import LoadingPage from '../../components/LoadingPage.vue';
import loginService from '../../services/login';


export default{
  components: { LoadingPage },
  data(){
    return{
      isBusy: false,
      email: '',
      password: '',
      error: '',
      success: false,
      user: {}
    }      
  },
  methods: {
    async login(){
      this.isBusy = true;
      this.error = '';
      this.success = false;
      try {
        let sucesso = await loginService.login(this.email, this.password);
        this.user  = await loginService.getUser();

        if (sucesso) {
          this.success = true;
          new Promise(res => setTimeout(res, 1000));

          this.$router.replace(this.$route.params.next || '/');
          this.isBusy = false;
          this.email = '',
          this.password = '',
          this.success = false;
        } else {
          throw new Error('E-mail ou senha inválidos');
        }
      } catch (error) {
        this.isBusy = false;
        this.error = error.toString() || 'Erro desconhecido, por favor tente novamente.';
        swal("Oops!", this.error, "error");

      }
    },
    logout () {
      loginService.logout();
    },
    validate () {
      this.isBusy = true;
      if(loginService.isLoggedIn()) {
        this.success = true;
        this.$router.replace(this.$route.params.next || '/');
      } else {
        this.isBusy = false;
      }
    },
  }
}

</script>

