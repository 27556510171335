<template>
  <div>
    <label class="form-label">{{ label }}</label>
    <div class="d-flex input-group dropdown">
      <input v-model="filterValue" class="form-control" :list="'my-list-id-'+route">
      <router-link v-if="!hideLink" :to="`/${route}`" type="button" class="btn btn-info text-light btn-no-hover">
        Gerenciar
      </router-link>
    </div>
    <!-- <ul class="dropdown-menu" :class="route" @click="hideDropdown">
      <li v-for="option in options.options" :key="option.value" @click="selectOption(option)" class="dropdown-item">{{ option.text }}</li>
    </ul> -->
    <datalist :id="'my-list-id-'+route">
      <option v-for="option in options.options" :key="option.value">{{ option.text }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  nome: 'BaseSelect',
  props: {
    label: {
      required: true,
    },
    value: {
      required: true,
    },
    textField: {
      required: false,
    },
    hideLink: {
      required: false
    },
    route: {
      required: true,
    },
  },
  emits: {
    onSelect: {
      required: true
    }
  },
  data() {
    return {
      selected: {},
      textSelect: 'nome',
      selectedValue: null,
      filterValue: '',
      options: {
        options: [],
        disabled: true
      },
      rest: null,
    };
  },
  created() {
    this.search();
  },
  mounted() {
    this.selectedValue = this.value;
    if(this.textField) this.textSelect = this.textField;
  },
  watch: {
    filterValue() {
      if(this.filterValue != this.selected.text) this.selectedValue = null;
      this.search();
    },
    selectedValue() {
      this.$emit('onSelect', this.selectedValue);
    },
    value() {
      if (this.selectedValue != this.value) this.selectedValue = this.value;
      this.search();
    },
  },
  methods: {    
    search() {
      this.options.options = [];
      const service = require("../services/"+ this.route)
      service.default.getAll(1, 5, { [this.textSelect]: this.filterValue, id: this.selectedValue }).then(result => {
        this.options.options = result.rows.map(el => {
          return { value: el.id, text: el[this.textSelect] };
        });
          this.options.disabled = false;
          if (this.selectedValue && result.rows.find(x => x.id == this.selectedValue)) {
            this.selectOption(this.options.options.find(x => x.value == this.selectedValue))            
          };
        }
      );
    },
    selectOption(selected){
      this.selected = selected;
      this.selectedValue = selected.value;
      this.filterValue = selected.text;
      this.hideDropdown();
    },
    toggleDropdown(){
      const index = document.querySelector(`.dropdown-menu.${this.route}`).classList.value.indexOf('show');
      if (index >= 0) document.querySelector(`.dropdown-menu.${this.route}`).classList.remove('show');
      else {
        this.search()
        document.querySelector(`.dropdown-menu.${this.route}`).classList.add('show');
      }
    },
    hideDropdown(){
      document.querySelector(`.dropdown-menu.${this.route}`).classList.remove('show');
    }
  }
};

</script>

<style scoped>
.form-label {
  font-weight: bold;
}
</style>