<template> 
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header search py-3">
                <h1>Filtros</h1>
            </div>
            <div class="card-body p-0">
                <form>
                    <div class="container-fluid p-0">
                        <div class="row m-3">
                            <div class="col-sm-3">
                                <label for="" class="form-label search">Estado</label>
                                <input v-model="filter.estadoProducao" class="form-control">
                            </div>
                            <div class="col-sm-3">
                                <label for="" class="form-label search">Operador</label>
                                <input v-model="filter.operador" class="form-control">
                            </div>
                            <div class="col-sm-3">
                                <label for="" class="form-label search">Data Inicial</label>
                                <input v-model="filter.dateStart" type="datetime-local" class="form-control">
                            </div>
                            <div class="col-sm-3">
                                <label for="" class="form-label search">Data Final</label>
                                <input v-model="filter.dateEnd" type="datetime-local" class="form-control">
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-sm-6">
                                <button @click="clearFilters" type="button" class="btn LimparFiltros">
                                    Limpar Filtros
                                </button>
                            </div>
                            <div class="col-sm-6 text-end">
                                <button @click="search" type="button" class="btn btn-success btn-pesquisar">
                                    <i class="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="card card-table">
            <div class="card-header" style="background-color: white;">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-sm-6 title-list">
                            <h4>Relatório de Estados</h4>
                        </div>
                        <div class="col-sm-6 text-end">
                            <button @click="exportar" type="button" class="btn btn-adicionar">
                                <i class="bi bi-file-earmark-spreadsheet"></i>
                            </button>
                        </div>
                    </div>
                      <div class="row align-items-center">
                        <div class="col-sm-6">
                            <pagination     
                            :currentPage="currentPage"
                            :totalRows="totalRows"
                            :rowsPerPage="rowsPerPage"
                            @changePage="changePage"
                            />
                        </div>
                        <div class="col-sm-6 text-end">
                            <items-per-page
                            :rowsPerPage="rowsPerPage"
                            @onSelect="selectRows"
                            />
                        </div>
                    </div>
                </div>
            </div>            
            <div class="card-body">
                <base-table
                :isBusy="isBusy"
                :fields="fields"
                :rows="rows"
                :editRoute="id"
                />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Pagination from '../../components/Pagination.vue';
import ItemsPerPage from '../../components/ItemsPerPage.vue';
import BaseTable from '../../components/BaseTable.vue';
import RestResource from "../../services/estadoProducao";
import RestConfig from "../../services/configuracao";


export default{
  components: { Pagination, ItemsPerPage, BaseTable },
  data(){
    return {
      id: null,
      isBusy: true,
      currentPage: 1,
      rowsPerPage: 20,
      totalRows: 0,
      pageOptions: [10, 20, 50, 100],
      rows: [],
      filter: {
        dateStart: '',
        dateEnd: '',
        estadoProducao: null,
      },
      fields: [
        { key: 'estado', label: 'Estado' },
        { key: 'operadorEstadoProducao.nome', label: 'Operador' },
        { key: 'inicio', label: 'Inicio', format: function (x) { return new Date(x.inicio).toLocaleString() } },
        { key: 'fim', label: 'Final', format: function (x) { return new Date(x.fim).toLocaleString() } },
        { key: 'vizualize', label: 'Visualizar' },
      ],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    if (this.id) {
      this.searchConfig();
      this.search();
    } else {
      swal("Oops!", "Configuração não encontrada", "error");
    }
  },
  methods:{
    exportar() {
      this.isBusy = true;
      RestResource.getCsv(this.id, this.filter).then(result => {
        const blob = new Blob([result], { type: 'text/csv' });    
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', 'FCW-RelatórioDetalhado.csv');
        a.click()
        this.isBusy = false;
      });
    },
    searchConfig() {
      this.isBusy = true;
      RestConfig.getOne(this.id).then(result => {
        this.config = result;
        this.filter.dateStart = moment(this.config.inicio).format('YYYY-MM-DDTHH:mm');
        this.filter.dateEnd = moment(this.config.fim).format('YYYY-MM-DDTHH:mm');
      });
    },
    search() {
      this.isBusy = true;
      RestResource.getAll(this.currentPage, this.rowsPerPage, { idConfiguracao: this.id, ...this.filter }).then(result => {
        this.rows = result.rows;
        this.totalRows = result.count;
        this.isBusy = false;
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.search();
    },
    selectRows(rowsPerPage) {
      this.rowsPerPage = rowsPerPage;
      this.search();
    },
  }

}

</script>