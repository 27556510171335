<template>
  <loading-page v-if="isBusy" />
  <div v-else class="card card-table mt-0 h-100">
    <div class="card-header bg-white text-uppercase fs-5 px-3">
      <div class="row">
        <div class="col-10">LINHA DO TEMPO</div>
        <!-- <div class="col-2 text-end">
          <i class="bi bi-arrow-down-circle" style="cursor: pointer;" @click="exportToPDF"></i>
        </div> -->
      </div>
    </div>
    <div class="card-body h-100 pt-0">
      <div class="row align-items-center h-100">
        <div class="col-12 h-100">
          <Line :data="chartData" :options="chartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingPage from "../LoadingPage.vue";
// import Exporter from "vue-chartjs-exporter";
import zoomPlugin from "chartjs-plugin-zoom";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  zoomPlugin,
  chartjsPluginAnnotation
);

export default {
  components: { LoadingPage, Line },
  name: "MmiChart",
  props: {
    isBusy: {
      required: false,
    },
    medicoes: {
      required: true,
    },
    filter: {
      required: true,
    },
    configuracoes: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    chartData() {
      return {
        labels: this.formatLabels(),
        datasets: this.getDatasets(),
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            callbacks: {
              // title: (item) => { new Date(item[0].label).toLocaleString()},
              afterBody: (items) =>
                `${items[0].raw.y.toFixed(2)} ${
                  items[0].dataset.label.indexOf("TENSAO") >= 0 ? "V" : "A"
                }`,
              label: (item) => item.dataset.label,
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
            },
            pan: {
              enabled: true,
              mode: "x",
            },
          },
          annotation: {
            annotations: this.getAnnotations(),
            // annotations: {
            //   arco: {
            //     drawTime: "afterDatasetsDraw",
            //     type: "line",
            //     mode: "horizontal",
            //     scaleID: "y",
            //     value: 30,
            //     borderColor: "green",
            //     borderWidth: 2,
            //     borderDash: [6, 3], // opcional: para criar um efeito de linha tracejada
            //   },
            //   box1: {
            //     type: "box",
            //     xMin: this.configuracoes.map(),
            //     xMax: 2,
            //     yMin: 50,
            //     yMax: 70,
            //     backgroundColor: "rgba(255, 99, 132, 0.25)",
            //   },
            // },
          },
        },
        scales: {
          x: {
            type: "time",
            time: {
              displayFormats: {
                hour: "HH:mm",
                minute: "HH:mm:ss",
                second: "HH:mm:ss",
              },
            },
          },
          y: {
            beginAtZero: true,
          },
        },
      };
    },
  },
  methods: {
    formatLabels() {
      let labels = [];
      if (this.medicoes.length) {
        const diff =
          this.filter.view ||
          (new Date(this.filter.dateEnd).getTime() -
            new Date(this.filter.dateStart).getTime()) /
            5;
        for (
          let index = new Date(this.filter.dateStart).getTime();
          index < new Date(this.filter.dateEnd).getTime();
          index += diff
        ) {
          labels.push(index);
        }
        labels.push(new Date(this.filter.dateEnd).getTime());
      }
      return labels;
    },
    getDatasets() {
      const identificadores = this.medicoes.map((r) => r.id_variavel);
      const unicIds = identificadores.filter(
        (item, index) => identificadores.indexOf(item) === index
      );
      const datasets = unicIds.map((id) => {
        const color = this.randomColor(
          this.medicoes.find((m) => m.id_variavel === id).descricao
        );
        return {
          label: this.medicoes.find((m) => m.id_variavel === id).descricao,
          fill: false,
          data: this.medicoes
            .filter((m) => m.id_variavel === id)
            .map((m) => ({
              x: new Date(m.periodo + ":").getTime(),
              y: m.media,
            })),
          borderColor: color,
          backgroundColor: color,
        };
      });
      return datasets;
    },
    randomColor(descricao, transparencia = 1) {
      const baseColors = [
        {
          nome: "CORRENTE",
          cores: [
            "#d58300",
            "#e38e00",
            "#f19900",
            "#ffa500",
            "#ffb11b",
            "#ffbd2c",
            "#ffc93b",
          ],
        }, // Tons de amarelo
        {
          nome: "TENSAO",
          cores: [
            "#00d200",
            "#00e100",
            "#00f000",
            "#00ff00",
            "#36ff26",
            "#50ff3b",
            "#66ff4d",
          ],
        }, // Tons de verde
        {
          nome: "CONFIG",
          cores: [
            "#6A5ACD",
            "#836FFF",
            "#6959CD",
            "#483D8B",
            "#191970",
            "#000080",
            "#00008B",
            "#0000CD",
            "#0000FF",
            "#6495ED",
            "#4169E1",
            "#1E90FF",
            "#00BFFF",
            "#87CEFA",
            "#87CEEB",
            "#ADD8E6",
            "#4682B4",
            "#B0C4DE",
          ],
        }, // Tons de azul
        {
          nome: "default",
          cores: [
            "#df0000",
            "#ea0000",
            "#f40000",
            "#ff0000",
            "#ff1d0b",
            "#ff2e16",
            "#ff3b1f",
          ],
        }, // Cores padrão para outros tipos
      ];
      // const letters = "0123456789ABCDEF";
      // let color = "#";
      // for (let i = 0; i < 6; i++) {
      //   color += letters[Math.floor(Math.random() * 16)];
      // }
      const colors =
        baseColors.find((b) => descricao.indexOf(b.nome) >= 0)?.cores ||
        baseColors.find((b) => b.nome == "default").cores;
      const randomIndex = Math.floor(Math.random() * colors.length);
      let color = colors[randomIndex];
      color = `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(
        color.slice(3, 5),
        16
      )}, ${parseInt(color.slice(5, 7), 16)}, ${transparencia})`;
      return color;
    },
    getAnnotations() {
      let annotations = {
        arco: {
          drawTime: "afterDatasetsDraw",
          type: "line",
          mode: "horizontal",
          scaleID: "y",
          value: 30,
          borderColor: "green",
          borderWidth: 2,
          borderDash: [6, 3], // opcional: para criar um efeito de linha tracejada
        },
      };
      this.configuracoes.map((c) => {
        annotations["config-" + c.id] = {
          type: "box",
          xMin:
            new Date(c.inicio).getTime() <
            new Date(this.filter.dateStart).getTime()
              ? new Date(this.filter.dateStart).getTime()
              : new Date(c.inicio).getTime(),
          xMax: c.fim
            ? new Date(c.fim).getTime() >
              new Date(this.filter.dateEnd).getTime()
              ? new Date(this.filter.dateEnd).getTime()
              : new Date(c.fim).getTime()
            : new Date().getTime(),
          yMin: 0,
          yMax: 30,
          // yMax: c.configuracaoMedicaoEstado.find((m) => m.medicao === 'CORRENTE_MAXIMA').valor,
          backgroundColor: this.randomColor("CONFIG", 0.25),
        };
      });
      return annotations;
    },
  },
};
</script>
