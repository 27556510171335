'use strict';

import api from './api';

export default {
  /**
   * create: Conecta com o backend para utilização do método POST para criar uma medicao de estado
   * @param {Object} data Informações do que será usado na criação
   */
  create(data) {
    return api.execute('post', `/server/medicaoEstado`, null, data);
  },

  /**
   * update: Conecta com o backend para utilização do método PUT para editar uma medicao de estado
   * @param {Object} data Informações do que será usado na edição
   * @param {*} id Primary key
   */
  update(id, data) {
    return api.execute('put', `/server/medicaoEstado/${id}`, null, data);
  },

  /**
   * getAll: Conecta com o backend para utilização do método GET para receber uma ou mais medições de estado como retorno
   */
    getAll(page, rowsPerPage = 20, filters = {}) {
    let queryString = ``;
    for (var key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key) && filters[key]) {
        queryString += `&${key}=${filters[key]}`;
      }
    }
  
    return api.execute('get', `/server/medicaoEstado?${queryString}`, page || 1, {rowsPerPage:rowsPerPage});
  },

  /**
   * getAllByEstadoProducaoWithoutPagination: Conecta com o backend para utilização do método GET para receber uma ou mais medições de estado como retorno sem paginação
   * @param {*} idEstadoProducao Primary key
   */
  getAllByEstadoProducaoWithoutPagination(idEstadoProducao) {
    return api.execute('get', `/server/medicaoEstado/?idEstadoProducao=${idEstadoProducao}`, null, null, true);
  },

  /**
   * getAllByEstadoProducao: Conecta com o backend para utilização do método GET para receber uma ou mais medições de estado como retorno
   * @param {*} idEstadoProducao Primary key
   * @param {Number} page Número de paginação
   */
  getAllByEstadoProducao(idEstadoProducao, page, rowsPerPage = 20) {
    return api.execute('get', `/server/medicaoEstado/?idEstadoProducao=${idEstadoProducao}`, page || 1,{rowsPerPage:rowsPerPage});
  },

  /**
   * getAllByConfiguracaoWithoutPagination: Conecta com o backend para utilização do método GET para receber uma ou mais medições de estado como retorno sem paginação
   * @param {*} idConfiguracao Primary key
   */
  getAllByConfiguracaoWithoutPagination(idConfiguracao) {
    return api.execute('get', `/server/medicaoEstado/?idConfiguracao=${idConfiguracao}`, null, null, true);
  },

  /**
   * getAllByConfiguracao: Conecta com o backend para utilização do método GET para receber uma ou mais medições de estado como retorno
   * @param {*} idConfiguracao Primary key
   * @param {Number} page Número de paginação
   */
  getAllByConfiguracao(idConfiguracao, page, rowsPerPage = 20) {
    return api.execute('get', `/server/medicaoEstado/?idConfiguracao=${idConfiguracao}`, page || 1,{rowsPerPage:rowsPerPage});
  },

  /**
   * getCalcByConfiguracao: Conecta com o backend para utilização do método GET para receber uma ou mais medições de estado como retorno
   * @param {*} idConfiguracao Primary key
   * @param {Number} page Número de paginação
   */
  getCalcByConfiguracao(idConfiguracao) {
    return api.execute('get', `/server/medicaoEstado/getConfig/${idConfiguracao}`, null, null, true);
  },

  /**
   * getCalcByConfiguracao: Conecta com o backend para utilização do método GET para receber uma ou mais medições de estado como retorno
   * @param {*} idConfiguracao Primary key
   * @param {Number} page Número de paginação
   */
  getCsvByConfiguracao(idConfiguracao) {
    return api.execute('get', `/server/medicaoEstado/getCsv/${idConfiguracao}`, null, null, true);
  },

  /**
   * getOne: Conecta com o backend para utilização do método GET para receber uma medicao de estado como retorno
   * @param {Number} id Primary key
   */
  getOne(id) {
    return api.execute('get', `/server/medicaoEstado/${id}`);
  },

  /**
   * delete: Conecta com o backend para utilização do método DELETE para receber uma medicao de estado
   * @param {Number} id Primary key
   */
  delete(id) {
    return api.execute('delete', `/server/medicaoEstado/${id}`);
  },
}