<template>
  <loading-page v-if="false" />
  <div v-else class="card card-table mt-0 h-100">
    <div class="card-header bg-white text-uppercase fs-5 px-3">
      <div class="row">
        <div class="col-10">
          GRÁFICO DE BARRA
        </div>
      </div> 
    </div>
    <div class="card-body pt-0">
      <bar :data="chartData" :options="options" height="100"/>
    </div>
  </div>
</template>

<script>
import LoadingPage from "../LoadingPage.vue";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, TimeScale} from 'chart.js'
import { Bar } from 'vue-chartjs';
import 'chartjs-adapter-date-fns';

ChartJS.register(Title, Tooltip, Legend, BarElement, TimeScale)

export default {
  components: { LoadingPage, Bar },
  name: 'BarChart',
  data() {
    return { 
      options: {
        indexAxis: 'y',
        responsive: true,
        plugins: {
          datalabels: {
            display: false
          },
          // tooltip: {
          //   callbacks: {
          //     title: () => "",
          //     afterBody: (items) => `${new Date(items[0].raw.x[0]).toLocaleString()} - ${new Date(items[0].raw.x[1]).toLocaleString()}`,
          //     label: (item) => item.dataset.label
          //   }
          // }
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            // stacked: true,
            min: new Date(),
            max: new Date(new Date().getTime()+60),
            type: 'time',
          },
          y: {
            stacked: true,
            // min: new Date(),
            // max: new Date(new Date().getTime()+60),
            // type: 'time',
          }
        }
      }
    };
  },
  computed: {
    chartData() {
      return {
        labels: ['Máquina 01'],
        datasets: [
          {
            label: 'MAQUINA DESLIGADA',
            data: [
              [new Date(new Date().getTime()+0), new Date(new Date().getTime()+5)],
              [new Date(new Date().getTime()+10), new Date(new Date().getTime()+15)],
            ],
            backgroundColor: '#FF0000',
            // stack: 'Stack 0',
          },
          {
            label: 'MAQUINA LIGADA',
            data: [
              [new Date(new Date().getTime()+5), new Date(new Date().getTime()+10)],
              [new Date(new Date().getTime()+15), new Date(new Date().getTime()+20)],
            ],
            backgroundColor: '#00FF00',
            // stack: 'Stack 0',
          },
          {
            label: 'ARCO ABERTO',
            data: [
              [new Date(new Date().getTime()+15), new Date(new Date().getTime()+20)]
            ],
            backgroundColor: '#0000FF',
            // stack: 'Stack 2',
          },
        ]
      }
    },
  },
  mounted() {
    if(this.inputData) this.format(this.inputData);
  },
  watch: {
    inputData() {
      if(this.inputData) this.format(this.inputData);
    },
  },
  methods:{
    buscaMaterial(estados){
      const idMaterial = [];
      estados.forEach(estado => {
        if(!idMaterial.find((m) => m.id === estado.configuracaoEstadoProducao.materialConfiguracao.id)){
          idMaterial.push(estado.configuracaoEstadoProducao.materialConfiguracao);
        }
      });
      return idMaterial;
    },
    format(dados){
      dados = dados.filter((x) => x.estado.indexOf('ARCO') >= 0);
      const materiais = this.buscaMaterial(dados);
      const chartData = [];
      materiais.forEach(material => {
        const filtered = dados.filter(x => x.configuracaoEstadoProducao.materialConfiguracao.id == material.id);
        chartData.push({
          color: this.materials.find((m) => m.id === material.id ).color,
          label: material.descricao.substring(6,20),
          data: filtered.reduce((sum, actual) => 
            sum += parseFloat(actual.estadoProducaoMedicaoEstado.find(x => x.medicao == 'CONSUMO_MATERIAL').valor), 0
          ),
        })
      });
      this.formatted = chartData;
    },
    exportToPDF() {
      this.download_status = "Processing...";
      let doughnut = document.getElementById("bar");

      const exp = new Exporter([doughnut]);
      exp.export_pdf().then((pdf) => {
        pdf.save("ConsumoDeMaterial.pdf");
        this.download_status = "Download Charts";
      });
    },
  },
};

</script>