<template>
  <div class="col-sm-6">
    <div class="btn-group">
      <button @click="$emit('cancelar')" type="button" class="btn btn-dark text-light btn-no-hover">
        Cancelar
      </button>
      <button v-if="habExclusao" @click="$emit('excluir')" type="button" class="btn btn-danger text-light btn-no-hover">
        Excluir
      </button>
    </div>
  </div>
  <div class="col-sm-6 text-end">
    <span class="fs-6 pe-4">*Campos Obrigatórios</span>
    <button @click="$emit('confirmar')" type="button" class="btn btn-success text-light btn-no-hover">
      Salvar
    </button>
  </div>
</template>

<script>

export default {
  name: 'ConfirmButtons',
  props: {
    habExclusao: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    cancelar: {
        required: true
    },
    confirmar: {
        required: true
    },
    excluir: {
        required: false
    },
  },
};

</script>