<template>
  <div class="container py-5">
    <div class="card mb-4 rounded-3 shadow-sm">
      <div class="card-header search" style="padding-right: 1rem !important">
        <div class="row">
          <div class="col-10">
            <h1 class="bold">Filtros</h1>
            <p class="m-0">Utilize os campos abaixo para refinar o relatório</p>
          </div>
          <div class="col-2 text-end">
            <button
              @click="exportar"
              type="button"
              class="btn btn-success btn-lg"
            >
              <i class="bi bi-file-earmark-spreadsheet"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <form>
          <div class="container-fluid p-0">
            <div class="row m-3">
              <div class="col-sm-4">
                <label for="" class="form-label search">Data Inicial</label>
                <input
                  v-model="filter.dateStart"
                  type="datetime-local"
                  class="form-control"
                  :max="moment(filter.dateEnd).format('YYYY-MM-DDTHH:mm')"
                />
              </div>
              <div class="col-sm-4">
                <label for="" class="form-label search">Data Final</label>
                <input
                  v-model="filter.dateEnd"
                  type="datetime-local"
                  class="form-control"
                  :max="moment().endOf('day').format('YYYY-MM-DDTHH:mm')"
                />
              </div>
              <div class="col-sm-4">
                <label for="" class="form-label search">Máquina</label>
                <input v-model="filter.maquina" class="form-control" />
              </div>
              <!-- <div class="col-sm-4">
                <base-combobox
                  label="Máquina"
                  :value="filter.idMaquina"
                  textField="nome"
                  route="maquina"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idMaquina = id;
                    }
                  "
                />
              </div> -->
              <!-- <div class="col-sm-3">
                <base-combobox
                  label="Ordem de Produção"
                  :value="filter.idOrdemProducao"
                  textField="codigo"
                  route="ordemProducao"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idOrdemProducao = id;
                    }
                  "
                />
              </div> -->
              <!-- <div class="col-sm-3">
                <base-combobox
                  label="ID"
                  :value="filter.idAtividade"
                  textField="codigo"
                  route="atividade"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idAtividade = id;
                    }
                  "
                />
              </div> -->
              <!-- <div class="col-sm-3">
                <base-combobox
                  label="Material"
                  :value="filter.idMaterial"
                  textField="descricao"
                  route="material"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idMaterial = id;
                    }
                  "
                />
              </div> -->
            </div>
            <div class="row m-3">
              <div class="col-sm-4">
                <label for="" class="form-label search">Ordem de Serviço</label>
                <input v-model="filter.ordemServico" class="form-control" />
              </div>
              <div class="col-sm-4">
                <label for="" class="form-label search"
                  >Ordem de Produção</label
                >
                <input v-model="filter.ordemProducao" class="form-control" />
              </div>
              <div class="col-sm-4">
                <select-local
                  label="Intervalo"
                  :value="filter.steps"
                  textField="text"
                  :options="viewList"
                  @onSelect="
                    (val) => {
                      filter.steps = val;
                    }
                  "
                />
              </div>
              <!-- <div class="col-sm-2">                              
                              <select-local
                              label="Visulização"
                              :value="filter.view"
                              textField="text"
                              :options="viewList"
                              @onSelect="(id) => { filter.view = id }"
                              />
                            </div> -->
              <!-- <div class="col-sm-2">
                              <base-combobox
                              label="Turno"
                              :value="filter.idTurno"
                              textField="nome"
                              route="turno"
                              :hideLink="true"
                              @onSelect="(id) => { filter.idTurno = id }"
                              />
                            </div> -->
              <!-- <div class="col-sm-4">
                <base-combobox
                  label="Operador"
                  :value="filter.idOperador"
                  textField="nome"
                  route="operador"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idOperador = id;
                    }
                  "
                />
              </div> -->
            </div>
            <div class="row pt-2">
              <div class="col-sm-6">
                <button
                  @click="clearFilters"
                  type="button"
                  class="btn LimparFiltros"
                >
                  Limpar Filtros
                </button>
              </div>
              <div class="col-sm-6 text-end">
                <button
                  @click="search"
                  type="button"
                  class="btn btn-success btn-pesquisar"
                >
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <loading-page v-if="isBusy" />
    <div v-else-if="maquinas.length">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6 p-1" style="height: 20rem">
            <medicoes-config :isBusy="isBusy" :maquinas="maquinas" :configuracoes="configuracoes" />
          </div>
          <div class="col-12 col-md-6 p-1" style="height: 20rem">
            <info-material :isBusy="isBusy" :configuracoes="configuracoes" />
          </div>
        </div>
        <div class="row" style="height: 30rem">
          <div class="col-12 p-1 h-100">
            <!-- <time-chart :isBusy="isBusy" :medicoes="medicoes" /> -->
            <mmi-chart :isBusy="isBusy" :medicoes="medicoes" :filter="filter" :configuracoes="configuracoes" />
            <!-- <bar-chart/> -->
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <span class="fs-2">Sem Informações No Período Selecionado</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import BaseCombobox from "@/components/BaseCombobox.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import SelectLocal from "@/components/SelectLocal.vue";
import MedicoesConfig from "@/components/Charts/MedicoesConfig.vue";
import DoughnutChart from "@/components/Charts/DoughnutChart.vue";
import TimeChart from "@/components/Charts/TimeChart.vue";
import BarChart from "@/components/Charts/BarChart.vue";
import InfoMaterial from "@/components/Charts/InfoMaterial.vue";
import TimeMaterialChart from "@/components/Charts/TimeMaterialChart.vue";
import MmiChart from "@/components/Charts/MmiChart.vue";
import LoadingPage from "../../components/LoadingPage.vue";
import RestResource from "../../services/estadoProducao";

export default {
  components: {
    BaseSelect,
    SelectLocal,
    BaseCombobox,
    MedicoesConfig,
    DoughnutChart,
    TimeChart,
    BarChart,
    LoadingPage,
    TimeMaterialChart,
    InfoMaterial,
    MmiChart,
  },
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      rowsPerPage: 20,
      totalRows: 0,
      maquinas: [],
      configuracoes: [],
      medicoes: [],
      filter: {
        dateStart: moment().startOf("day").format("YYYY-MM-DDTHH:mm"),
        dateEnd: moment().endOf("day").format("YYYY-MM-DDTHH:mm"),
        steps: "minutos",
        view: 0,
      },
      viewList: [
        { value: "dias", text: "1 Dia" },
        { value: "horas", text: "1 Hora" },
        { value: "minutos", text: "1 Minuto" },
        { value: "segundos", text: "1 Segundo" },
      ],
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    formatDate(date) {
      const ano = date.getFullYear();
      const mes = (date.getMonth() + 1).toString().padStart(2, 0);
      const dia = date.getDate().toString().padStart(2, 0);
      const hora = date.getHours().toString().padStart(2, 0);
      const minuto = date.getMinutes().toString().padStart(2, 0);
      return `${ano}-${mes}-${dia}`;
      // return `${ano}-${mes}-${dia}T${hora}:${minuto}`
    },
    exportar() {
      this.isBusy = true;
      RestResource.getChartCsv(this.filter).then((result) => {
        const blob = new Blob([result], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "FCW-RelatórioGráfico.csv");
        a.click();
        this.isBusy = false;
      });
    },
    printScreen() {
      window.print();
    },
    search() {
      this.isBusy = true;
      RestResource.getChart(this.filter).then((result) => {
        this.maquinas = result.maquinas;
        this.configuracoes = result.configuracoes.map((c) => {
          if(!c.configuracaoMedicaoEstado.length) c.configuracaoMedicaoEstado = c.calculos;
          return c;
        });
        this.medicoes = result.medicoes;
        this.isBusy = false;
      });
    },
    clearFilters() {
      (this.filter = {
        dateStart: moment().startOf("day").format("YYYY-MM-DDTHH:mm"),
        dateEnd: moment().endOf("day").format("YYYY-MM-DDTHH:mm"),
        steps: "minutos",
        ordemServico: null,
        idOperador: null,
        idMaquina: null,
        idMaterial: null,
        idAtividade: null,
        idOrdemProducao: null,
      }),
        (this.rows = []);

      this.search();
    },
    changePage(page) {
      this.currentPage = page;
      this.search();
    },
    selectRows(rowsPerPage) {
      this.rowsPerPage = rowsPerPage;
      this.search();
    },
    moment(...args) {
      return moment(...args);
    },
  },
};
</script>
