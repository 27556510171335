<template>
  <div class="container">
    <login ref="login" />
  </div>
</template>

<script>

import Login from './Autenticacao/Login.vue';

export default {
  components: {
    Login,
  },
  mounted () {
    this.$refs.login.validate();
  }
};
</script>
