<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm border-card">
            <form ref="form">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-sm-6">
                            <label class="form-label">Nome*</label>
                            <input v-model="model.nome" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Nome.
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="form-label">E-mail*</label>
                            <input v-model="model.email" type="email" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo E-mail.
                            </div>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-sm-4">
                            <label class="form-label">Senha*</label>
                            <input v-model="model.senha" type="password" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Senha.
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <label class="form-label">Status</label>
                            <div class="form-check form-switch" >
                                <input v-model="model.ativo" class="form-check-input" type="checkbox" role="switch">
                                <label class="form-check-label">Ativo</label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                          <base-select
                          label="Grupo Usuário"
                          :value="model.id_grupo_usuario"
                          textField="nome"
                          route="grupoUsuario"
                          @onSelect="(id) => { model.id_grupo_usuario = id }"
                          />
                        </div>
                    </div>
                    <div class="row py-2">
                      <confirm-buttons
                        :habExclusao="true"
                        @cancelar="cancelAdd"
                        @excluir="excluir"
                        @confirmar="save"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import BaseSelect from '../../components/BaseSelect.vue';
import ConfirmButtons from "../../components/ConfirmButtons.vue"
import RestResource from "../../services/usuario";


export default{
  components: { ConfirmButtons, BaseSelect },
  data(){
    return {
      id: null,
      isBusy: false,
      model: {
        nome: '',
        email: '',
        senha: '',
        ativo: '',
        id_grupo_usuario: '',
      },
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    if (this.id) {
      this.search();
    } else {
      swal("Oops!", "Usuário não encontrado", "error");
    }
  },
  methods: {
    search() {
      this.isBusy = true;
      RestResource.getOne(this.id).then(result => {
        this.model = Object.assign({}, result);
      });
    },
    save() {
      if (!this.$refs.form.checkValidity()) {
          this.$refs.form.classList.add('was-validated');
          return;
      }

      RestResource.update(this.model.id, this.model).then(result => {
        if(result.error){
          swal({
          title: "Oops!",
          text:
            result.message,
          icon: "error",
          button: "Continuar..."
          }).then(() => {
          this.isBusy = false;
          });
        } else {
          swal({
            title: "Bom trabalho!",
            text:
              "O usuário foi atualizado na base de dados com sucesso!",
            icon: "success",
            button: "Continuar..."
          }).then(() => {
            this.$router.replace('/usuario');
            this.isBusy = false;
          });
        }
      },() => {
        swal({
          title: "Oops!",
          text:
            "Ocorreu um problema ao tentar atualizar o usuário.",
          icon: "error",
          button: "Continuar..."
        }).then(() => {
          this.isBusy = false;
        });
      });
    },

    excluir() {
      swal({
        title: "Excluir",
        text:
          "Tem certeza que deseja excluir o usuário?\nEsta ação é definitiva e sem retorno.",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          RestResource.delete(this.model.id).then(() => {
            swal({
              title: "Bom trabalho!",
              text: "O usuário foi removida da base de dados com sucesso!",
              icon: "success",
              button: "Continuar..."
            }).then(() => {
              this.$router.push('/usuario');
            });
          }, e => {
            swal({
              title: "Oops!",
              text: e.response.data && e.response.data.error && e.response.data.error.errorMessage || 'Ocorreu um problema ao tentar remover o usuário.',
              icon: "error",
              button: "Continuar..."
            });
          });
        }
      });
    },
    cancelAdd() {
      swal({
        title: "Cancelamento de edição de registro",
        text: "Deseja cancelar a edição do usuário?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          this.$router.push('/usuario');
        }
      });
    },

  }
}

</script>
