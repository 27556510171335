<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header search py-3">
                <h1>Pesquisar</h1>
            </div>
            <div class="card-body p-0">
                <form>
                    <div class="container-fluid p-0">
                        <div class="row m-3">
                            <div class="col-sm-4">
                                <label for="" class="form-label search">Chave</label>
                                <input v-model="filter.chave" class="form-control" aria-label="State">
                            </div>
                            <div class="col-sm-4">
                                <label for="" class="form-label search">Nome</label>
                                <input v-model="filter.nome" class="form-control" aria-label="State">
                            </div>
                            <div class="col-sm-4">
                                <label for="" class="form-label search">Descrição</label>
                                <input v-model="filter.descricao" class="form-control" aria-label="State">
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-sm-6">
                                <button @click="clearFilters" type="button" class="btn LimparFiltros">
                                    Limpar Filtros
                                </button>
                            </div>
                            <div class="col-sm-6 text-end">
                                <button @click="search" type="button" class="btn text-light btn-pesquisar">
                                    <i class="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    
        <div class="card card-table">
            <div class="card-header" style="background-color: white;">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-sm-6 title-list">
                            <h4>Lista de Estados</h4>
                        </div>
                        <div class="col-sm-6 text-end">
                            <router-link to="estado/adicionar" type="button" class="btn btn-adicionar ms-1">
                                Adicionar
                            </router-link>
                        </div>
                    </div>
                      <div class="row align-items-center">
                        <div class="col-sm-6">
                            <pagination     
                            :currentPage="currentPage"
                            :totalRows="totalRows"
                            :rowsPerPage="rowsPerPage"
                            @changePage="changePage"
                            />
                        </div>
                        <div class="col-sm-6 text-end">
                            <items-per-page
                            :rowsPerPage="rowsPerPage"
                            @onSelect="selectRows"
                            />
                        </div>
                    </div>
                </div>
            </div>            
            <div class="card-body">
                <base-table
                :isBusy="isBusy"
                :fields="fields"
                :rows="rows"
                editRoute='estado/editar'
                />
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '../../components/Pagination.vue';
import ItemsPerPage from '../../components/ItemsPerPage.vue';
import BaseSelect from '../../components/BaseSelect.vue';
import BaseTable from '../../components/BaseTable.vue';
import RestResource from "../../services/estado";


export default{
  components: { Pagination, ItemsPerPage, BaseSelect, BaseTable },
  data() {
    return {
      isBusy: true,
      currentPage: 1,
      rowsPerPage: 20,
      totalRows: 0,
      pageOptions: [10, 20, 50, 100],
      rows: [],
      filter: {},
      fields: [
        { key: 'chave', label: 'Chave'},
        { key: 'nome', label: 'Nome'},
        { key: 'descricao', label: 'Descrição'},
        { key: 'edit', label: 'Editar'},
      ],
    };
  },
  created() {
    this.search();
  },
  methods:{
    search() {
      this.isBusy = true;
      RestResource.getAll(this.currentPage, this.rowsPerPage, this.filter).then(result => {
        this.rows = result.rows;
        this.totalRows = result.count;
        this.isBusy = false;
      });
    },
    clearFilters() {
      this.filter.chave = {};

      this.currentPage = 1;
      this.rows = [];
      this.totalRows = 0;

      this.search();
    },
    changePage(page) {
      this.currentPage = page;
      this.search();
    },
    selectRows(rowsPerPage) {
      this.rowsPerPage = rowsPerPage;
      this.search();
    },
  }
}

</script>

