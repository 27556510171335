<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header search py-3">
                <h1>Filtros</h1>
                <p class="m-0">Utilize os campos abaixo para refinar o relatório</p>
            </div>
            <div class="card-body p-0">
                <form>
                    <div class="container-fluid p-0">
                        <div class="row m-4">
                            <div class="col-sm-4">
                                <label for="" class="form-label search">Data Inicial</label>
                                <input v-model="filter.dateStart" type="datetime-local" class="form-control">
                            </div>
                            <div class="col-sm-4">
                                <label for="" class="form-label search">Data Final</label>
                                <input v-model="filter.dateEnd" type="datetime-local" class="form-control">
                            </div>
                            <div class="col-sm-4">
                                <label for="" class="form-label search">Descrição</label>
                                <input v-model="filter.descricao" class="form-control">
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-6">
                                <button @click="clearFilters" type="button" class="btn LimparFiltros">
                                    Limpar Filtros
                                </button>
                            </div>
                            <div class="col-6 text-end">
                                <button @click="search" type="button" class="btn btn-success btn-pesquisar">
                                    <i class="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="card card-table">
            <div class="card-header" style="background-color: white;">
                <div class="container-fluid">
                      <div class="row align-items-center">
                        <div class="col-sm-6 py-1">
                            <pagination     
                            :currentPage="currentPage"
                            :totalRows="totalRows"
                            :rowsPerPage="rowsPerPage"
                            @changePage="changePage"
                            />
                        </div>
                        <div class="col-sm-6 py-1 text-end">
                            <items-per-page
                            :rowsPerPage="rowsPerPage"
                            @onSelect="selectRows"
                            />
                        </div>
                    </div>
                </div>
            </div>            
            <div v-if="rows.length" class="card-body">
                <base-table
                :isBusy="isBusy"
                :fields="fields"
                :rows="rows"
                editRoute="/relatorio/configuracao"
                />
            </div>
            <div v-else class="card-body text-center">
              <span class="fs-2">Sem Informações No Período Selecionado</span>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '../../components/Pagination.vue';
import ItemsPerPage from '../../components/ItemsPerPage.vue';
import BaseSelect from "../../components/BaseSelect.vue"
import BaseTable from '../../components/BaseTable.vue';
import LoadingPage from '../../components/LoadingPage.vue';
import RestResource from "../../services/auditoria";


export default{
  components: { Pagination, ItemsPerPage, BaseSelect, BaseTable, LoadingPage },
  data(){
    return {
      isBusy: true,
      currentPage: 1,
      rowsPerPage: 20,
      totalRows: 0,
      rows: [],
      filter: {
        dateStart: this.formatDate(new Date(new Date().getTime() - (1000*60*60*24*2))),
        dateEnd: this.formatDate(new Date(new Date().getTime() + (1000*60*60*24*1))),
        idUsuario: null,
        idOperador: null,
        descricao: null,
      },
      fields: [
        { key: 'usuarioAuditoria.nome', label: 'Responsável' },
        { key: 'mac', label: 'Arduino' },
        { key: 'descricao', label: 'Descrição' },
        { key: 'data_hora', label: 'Data', format: function (x) { return !x ? null : new Date(x.data_hora).toLocaleString().replaceAll(',',' ') } },
      ],
    };
  },
  mounted(){
    this.search();
  },
  methods:{
    formatDate(date) {
        const ano = date.getFullYear();
        const mes = (date.getMonth() +1).toString().padStart(2,0);
        const dia = date.getDate().toString().padStart(2,0);
        const hora = date.getHours().toString().padStart(2,0);
        const minuto = date.getMinutes().toString().padStart(2,0);
        return `${ano}-${mes}-${dia}T${hora}:${minuto}`
    },
    search() {
      this.isBusy = true;
      RestResource.getAll(this.currentPage, this.rowsPerPage, this.filter).then(result => {
        this.rows = result.rows;
        this.totalRows = result.count;
        this.isBusy = false;
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.search();
    },
    selectRows(rowsPerPage) {
      this.rowsPerPage = rowsPerPage;
      this.search();
    },
  }

}

</script>

