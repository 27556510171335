<template>
    <div class="NavBar d-flex">      
      <SideBar :barraVisivel="barraVisivel" @barra-toggle="atualizarBarra" />     
      <nav class="navbar navbar-expand-lg navbar-light custom-navbar tcs-nav-blue" :style="{ width: '15%' }">
        <div class="container list fs-3" @click="mostrarBarra">
            <!-- <i class="bi bi-list"  @click="mostrarBarra" :style="{ visibility: $route.path === '/login' ? 'hidden' : 'visible' }"></i> -->
            <span class="navbar-brand text-light fs-2 ps-2">
              <i class="bi bi-list"   :style="{ visibility: $route.path === '/login' ? 'hidden' : 'visible' }"></i>
              {{ windowWidth > 1000 ? 'FCW | DELP' : ''}}
            </span>
        </div>
      </nav>
  
      <nav class="navbar navbar-expand-lg navbar-expand-md navbar-light custom-navbar tcs-nav-white">
        <div class="container-fluid ps-0">
          <div class="row justify-content-between align-items-center" style="width:100%">
            <div class="col col-auto">
              <span class="nav-link text-secondary fs-2 ps-1">
                <button v-if="$route.path !== '/' && $route.path !== '/login'" @click="() => { $router.back() }" class="btn bi bi-arrow-left fs-3 ps-1 pe-0"></button>
                {{ pageTitle }}</span>
            </div>
            <div class="col col-auto" v-if="$route.path !== '/login'">
              <div class="row align-items-center">
                <div class="col col-auto">
                  <h6 class="form-label mb-0 user">{{ usuario || 'Desconhecido' }}</h6>
                  <label class="form-label mb-0 user">{{ cargo }}</label>
                </div>
                <div class="col col-auto p-0">
                  <button @click="sair" class="btn bi bi-box-arrow-right fs-3 px-0"></button>
                </div>
              </div>
            </div>
          </div>
          <!-- <button v-if="$route.path !== '/' && $route.path !== '/login'" @click="() => { $router.back() }" class="btn bi bi-arrow-left fs-3 ps-1 pe-0"></button> 
          <div class="">
            <ul class="navbar-nav">
              <li class="nav-item d-flex align-items-center">
                <span class="nav-link text-secondary fs-2 ps-1">{{ pageTitle }}</span>
              </li>
            </ul>
          </div>
          <ul v-if="$route.path !== '/login'" class="navbar-nav nav-item d-flex align-items-center">
            <li class="usuario text-center">
                <h6 class="form-label mb-0 user">{{ usuario || 'Desconhecido' }}</h6>
                <label class="form-label mb-0 user">{{ cargo }}</label>
            </li>
            
            <li class="nav-item d-flex align-items-center" @click="sair">
              <a class="nav-link sair" href="#">
                <i class="bi bi-box-arrow-right text-dark ms-3 fs-2"></i>
              </a>
            </li>
          </ul> -->
        </div>
      </nav>
    </div>
  </template>
  
<script>
import RestResource from "../../services/login";
import SideBar from './SideBar.vue';

export default {
  name: 'NavBar',
  components: {
      SideBar,
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {      
			usuario: '',
			cargo: '',
			descricao: '',
      barraVisivel: true,
      windowWidth: window.innerWidth,
      navLimit: '15%',
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  updated() {
    this.getUser();
  },
  methods: {
    mostrarBarra() {
      this.barraVisivel = !this.barraVisivel;
    },
    atualizarBarra() {
      this.barraVisivel = true;
    },
		getUser() {
			const user = RestResource.getUser();
			this.usuario = user ? user.nome : '';
			this.cargo = user ? user.grupo : '';
			this.descricao = user ? user.descricao : '';
		},
    sair() {
			this.usuario = '',
			this.cargo = '',
			this.descricao = '',
      this.$router.push('/logout');
    },
    voltar() {
      this.$router.back();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      // this.navLimit = window.innerWidth > 1 ? '15%' : '4.7rem';
    }
  }
};
</script>
  
<style scoped>
.NavBar {
    box-shadow: 0px 1px 15px 0px lightgray;
    width: 100%;
    z-index: 2;
    position: fixed;
    height: 65px;
}
.tcs-nav-blue {
    background: linear-gradient(to right, var( --color--primary), var( --color--primary));
}
.tcs-nav-white {
    background: linear-gradient(to right, var(--color-white), var(--color-white));
    width: 85%;
}
.list{
    color: var(--color-white);
    cursor: pointer;
}
.person{
  background-color: #6c757d;
}
.mmi a{
    background: #12a5c2;
}
.user{
  color: #b7b7b7;
}
.nav-link {
    padding: 0;
}
@media (max-width: 700px) {
  .navbar-nav.nav-item d-flex align-items-center{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .person{
      padding: 0px 5px 0 5px;
  }
  .bi-person{
    font-size: 1.5em;
  }
  .mmi{
    padding: 5px;
  }
  .bi-box-arrow-right{
    font-size: 1.5em;
  }
  .user{
    font-size: 0.7em;
  }
  .tcs-logo{
    display: none;
  }
}
  </style>
  