<template>
  <div class="container py-5">
    <div class="card mb-4 rounded-3 shadow-sm border-card">
      <div class="card-header bg-white text-center">
        <h5 class="py-2">Página não encontrada</h5>
      </div>
      <div class="card-body text-center">
        <div class="btn-group">
          <router-link class="btn btn-success text-light btn-no-hover" :to="{ name: 'dashboard', params: { next: $route.params.next } }">
            Dashboard
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
