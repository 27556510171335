<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm border-card">
            <form ref="form">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-sm-6">
                            <label class="form-label">Nome*</label>
                            <input v-model="model.nome" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Nome.
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="form-label">E-mail*</label>
                            <input v-model="model.email" type="email" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo E-mail.
                            </div>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-sm-4">
                            <label class="form-label">Senha*</label>
                            <input v-model="model.senha" type="password" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Senha.
                            </div>
                        </div>
                        <div class="col-sm-2" >
                            <label class="form-label">Status</label>
                            <div class="form-check form-switch" >
                                <input v-model="model.ativo" class="form-check-input" type="checkbox" role="switch">
                                <label class="form-check-label">Ativo</label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                          <base-select
                          label="Grupo Usuário"
                          :value="model.id_grupo_usuario"
                          textField="nome"
                          route="grupoUsuario"
                          @onSelect="(id) => { model.id_grupo_usuario = id }"
                          />
                        </div>
                    </div>
                    <div class="row py-2">
                      <confirm-buttons
                        :habExclusao="false"
                        @cancelar="cancelAdd"
                        @confirmar="save"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert';
import BaseSelect from '../../components/BaseSelect.vue';
import ConfirmButtons from "../../components/ConfirmButtons.vue"
import RestResource from "../../services/usuario";


export default{
  components: { ConfirmButtons, BaseSelect },
  data(){
    return {
      id: null,
      isBusy: false,
      model: {
        nome: '',
        email: '',
        senha: '',
        ativo: '',
        id_grupo_usuario: '',
      },
      grupoUsuario: {
        options: [],
        disabled: true
      },
    }
  },
  methods: {
    save() {
      if (!this.$refs.form.checkValidity()) {
          this.$refs.form.classList.add('was-validated');
          return;
      }
      
      RestResource.create(this.model).then(result => {
        if(result.error){
          swal({
          title: "Oops!",
          text:
            result.message,
          icon: "error",
          button: "Continuar..."
          }).then(() => {
          this.isBusy = false;
          });
        } else {
          swal({
            title: "Bom trabalho!",
            text:
              "O usuário foi inserida na base de dados com sucesso!",
            icon: "success",
            button: "Continuar..."
          }).then(() => {
            this.isBusy = false;
          });
        }
      },() => {
        swal({
          title: "Oops!",
          text:
            "Ocorreu um problema ao tentar inserir o usuário.",
          icon: "error",
          button: "Continuar..."
        }).then(() => {
          this.isBusy = false;
        });
      });
    },
    cancelAdd() {
      swal({
        title: "Cancelamento de criação de registro",
        text: "Deseja cancelar a criação do usuário?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          this.$router.push('/usuario');
        }
      });
    },

  }
}

</script>