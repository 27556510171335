<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm">
            <form ref="form">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-6">
                            <label class="form-label">Código OP*</label>
                            <input v-model="model.codigo" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Código OP.
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Ordem de Serviço*</label>
                            <input v-model="model.ordem_servico" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Ordem de Serviço.
                            </div>
                        </div>
                        <!-- <div class="col-4">
                            <label class="form-label">Descrição*</label>
                            <input v-model="model.descricao" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Descrição.
                            </div>
                        </div> -->
                    </div>
                    <div class="row py-2">
                        <label class="form-label">IDs</label>
                        <div class="col-sm-3" v-for="atividade in atividades" :key="atividade.id">
                          <div class="form-check form-switch">
                            <input class="form-check-input" v-model="atividade.checked" type="checkbox" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                              {{ atividade.codigo }} - ( {{ atividade.descricao }} )
                            </label>
                          </div>
                        </div>
                    </div>
                    <div class="row py-2">
                      <confirm-buttons
                        :habExclusao="true"
                        @cancelar="cancelAdd"
                        @excluir="excluir"
                        @confirmar="save"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert';
import ConfirmButtons from "../../components/ConfirmButtons.vue"
import RestResource from "../../services/ordemProducao";
import RestAtividade from "../../services/atividade";


export default{
  components: { ConfirmButtons },
  data(){
    return {
      id: null,
      isBusy: false,
      model: {
        descricao: '',
        codigo: '',
        atividade: [],
      },
      atividades: [],
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    if (this.id) {
      this.search();
    } else {
      swal("Oops!", "Ordem de Produção não encontrado", "error");
    }
  },
  methods: {
    search() {
      this.isBusy = true;
      RestResource.getOne(this.id).then(result => {
        this.model = Object.assign({}, result);
        this.searchHelpers();
      });
    },
    searchHelpers() {
      this.isBusy = true;
      RestAtividade.getAllWithoutPagination({fromUsuario: true}).then(result => {
        this.atividades = result.rows;
        this.atividades.map((p) => {
          p.checked = this.model.atividade.indexOf(p.id) >= 0;
          return p
        })
        this.isBusy = false;
      });
    },
    save() {
      if (!this.$refs.form.checkValidity()) {
          this.$refs.form.classList.add('was-validated');
          return;
      }

      RestResource.update(this.model.id, this.model).then(result => {
        if(result.error){
          swal({
          title: "Oops!",
          text:
            result.message,
          icon: "error",
          button: "Continuar..."
          }).then(() => {
          this.isBusy = false;
          });
        } else {
          swal({
            title: "Bom trabalho!",
            text:
              "A ordem de produção foi atualizado na base de dados com sucesso!",
            icon: "success",
            button: "Continuar..."
          }).then(() => {
            this.$router.replace('/ordemProducao');
            this.isBusy = false;
          });
        }
      },() => {
        swal({
          title: "Oops!",
          text:
            "Ocorreu um problema ao tentar atualizar a ordem de produção.",
          icon: "error",
          button: "Continuar..."
        }).then(() => {
          this.isBusy = false;
        });
      });
    },

    excluir() {
      swal({
        title: "Excluir",
        text:
          "Tem certeza que deseja excluir a ordem de produção?\nEsta ação é definitiva e sem retorno.",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          RestResource.delete(this.model.id).then(() => {
            swal({
              title: "Bom trabalho!",
              text: "A ordem de produção foi removida da base de dados com sucesso!",
              icon: "success",
              button: "Continuar..."
            }).then(() => {
              this.$router.push('/ordemProducao');
            });
          }, e => {
            swal({
              title: "Oops!",
              text: e.response.data && e.response.data.error && e.response.data.error.errorMessage || 'Ocorreu um problema ao tentar remover a ordem de produção.',
              icon: "error",
              button: "Continuar..."
            });
          });
        }
      });
    },
    cancelAdd() {
      swal({
        title: "Cancelamento de edição de registro",
        text: "Deseja cancelar a edição da ordem de produção?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          this.$router.push('/ordemProducao');
        }
      });
    },

  }
}

</script>
