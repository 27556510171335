<template>
    <div v-if="$route.path !== '/login'">
        <div :style="{ width: larguraBarra }" :class="['sidebar', { 'sidebar-aberta': barraVisivel }]">

            <div class="container">
                <ul class="list-group align-items-center">
                    <li class="list-group-item rounded-2 p-1 ps-3 w-100" v-for="link in links" :key="link" :class="{'bg-white': link.children.map(c => '/'+c.route).indexOf($route.path) >= 0}">
                        <div 
                        v-if="!link.hasOwnProperty('route') && verifyPermissions(link.children.map(c => c.perms))" 
                        class="btn-group dropend w-100">
                            <button  type="button" class="p-0 d-flex align-items-center bg-transparent border-0 btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" :class="{'show-icon': !barraExpandida}">
                                <i :class="`bi bi-${link.icon}-fill ${link.children.map(c => '/'+c.route).indexOf($route.path) >= 0 ? 'icon-color' : ''}`"></i>
                                <p v-if="paragrafoVisivel" class="fs-6 ms-2 m-0" :class="`${link.children.map(c => '/'+c.route).indexOf($route.path) >= 0 ? 'icon-color' : ''}`">
                                    {{ link.title }}
                                </p>
                            </button>
                            <ul class="dropdown-menu">
                                <li 
                                class="dropdown-item" v-for="child in link.children" :key="child">
                                    <router-link 
                                    :to="`/${child.route}`" 
                                    class="btn bg-transparent border-0 btn-no-hover d-flex"
                                    v-if="verifyPermissions(child.perms)">
                                        <!-- <i :class="`bi bi-${child.icon}-fill me-2`"></i> -->
                                        {{ child.title }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div 
                        v-else-if="verifyPermissions(link.perms)" 
                        class="btn-group dropend w-100">
                            <router-link 
                            :to="`/${link.route}`"  
                            class="p-0 d-flex align-items-center bg-transparent border-0 btn btn-secondary "  
                            :class="{'show-icon': !barraExpandida}">
                                <i :class="`bi bi-${link.icon}-fill ${`/${link.route}` == $route.path ? 'icon-color' : ''}`"></i>
                                <p v-if="paragrafoVisivel" class="fs-6 ms-2 m-0" :class="`${`/${link.route}` == $route.path ? 'icon-color' : ''}`">
                                    {{ link.title }}
                                </p>
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
            <button @click="toggleExpandir" @mouseover="showIcon = true" @mouseleave="showIcon = false" class="btn btn-primary btn-ex float-end">
                <i v-if="!barraExpandida" class="bi bi-chevron-right text-dark" v-show="showIcon"></i>
                <i v-else class="bi bi-chevron-left text-dark" v-show="showIcon"></i>
            </button>
        </div>

    </div>
</template>
  
<script>
import loginService from '../../services/login';
import { perms } from '../../helpers/auth';

export default {
    name: 'SideBar',
    props: {
        barraVisivel: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        links: [{
            icon: 'grid-1x2',
            title: 'Dashboard',
            route: '',
            perms: ['visualizar_dashboard'],
            children: [{
                route: '',
            }]
        },{
            icon: 'file-earmark-bar-graph',
            title: 'Relatórios',
            perms: ['visualizar_relatorio'],
            children: [{
                icon: 'easel',
                title: 'Relatório Gráfico',
                route: 'relatorioGrafico',
                perms: ['visualizar_relatorio'],
            },{
                icon: 'easel',
                title: 'Relatório Geral',
                route: 'relatorio',
                perms: ['visualizar_relatorio'],
            }]
        },{
            icon: 'diagram-3',
            title: 'Rastreabilidade',
            perms: ['visualizar_rastreabilidade'],
            children: [{
                icon: 'bar-chart',
                title: 'Ordens de Produção',
                route: 'ordemProducao',
                perms: ['visualizar_ordemProducao'],
            },{
                icon: 'bar-chart',
                title: 'IDs',
                route: 'atividade',
                perms: ['visualizar_atividade'],
            },{
                icon: 'bar-chart',
                title: 'Máquinas',
                route: 'maquina',
                perms: ['visualizar_maquina'],
            },{
                icon: 'bar-chart',
                title: 'Tipos de Processo',
                route: 'tipoProcesso',
                perms: ['visualizar_tipoProcesso'],
            },{
                icon: 'bar-chart',
                title: 'Localizações',
                route: 'localizacao',
                perms: ['visualizar_localizacao'],
            },{
                icon: 'person',
                title: 'Operadores',
                route: 'operador',
                perms: ['visualizar_operador'],
            },{
                icon: 'person',
                title: 'Grupos de Operadores',
                route: 'grupoOperador',
                perms: ['visualizar_grupoOperador'],
            }]
        },{
            icon: 'hexagon',
            title: 'Materiais',
            route: 'material',
            perms: ['visualizar_material'],
            children: [{
                route: 'material',
            //    icon: 'hexagon',
            //    title: 'Materiais',
            //    route: 'material',
            //    perms: ['visualizar_material'],
            //},{
            //    icon: 'hexagon',
            //    title: 'Características do Material',
            //    route: 'caracteristicaMaterial',
            //    perms: ['visualizar_caracteristicaMaterial'],
            //},{
            //    icon: 'hexagon',
            //    title: 'Velocidades do Material',
            //    route: 'velocidadeMaterial',
            //    perms: ['visualizar_velocidadeMaterial'],
            }]
        },{
            icon: 'clock',
            title: 'Turnos',
            route: 'turno',
            perms: ['visualizar_turno'],
            children: [{
                route: 'turno',
            }]
        },{
            icon: 'person',
            title: 'Usuários',
            perms: ['visualizar_usuario'],
            children: [{
                icon: 'person',
                title: 'Usuários',
                route: 'usuario',
                perms: ['visualizar_usuario'],
            },{
                icon: 'person',
                title: 'Grupos de Usuários',
                route: 'grupoUsuario',
                perms: ['visualizar_grupoUsuario'],
            },{
                icon: 'person',
                title: 'Permissões',
                route: 'permissao',
                perms: ['visualizar_permissao'],
            }]
        },{
            icon: 'gear',
            title: 'Configuração',
            perms: ['visualizar_configuracao'],
            children: [{
                icon: 'gear',
                title: 'Importar Ordem de Produção',
                route: 'ordemProducao/importar',
                perms: ['adicionar_ordemProducao'],
            },{
                icon: 'gear',
                title: 'Medidores',
                route: 'medidor',
                perms: ['visualizar_medidor'],
            },{
                icon: 'gear',
                title: 'Estados',
                route: 'estado',
                perms: ['visualizar_estado'],
            },{
                icon: 'gear',
                title: 'Características de Estado',
                route: 'caracteristicaEstado',
                perms: ['visualizar_caracteristicaEstado'],
            },{
                icon: 'gear',
                title: 'Tipos de Calculadora',
                route: 'tipoCalculadora',
                perms: ['visualizar_tipoCalculadora'],
            },{
                icon: 'gear',
                title: 'Calculadoras',
                route: 'calculadora',
                perms: ['visualizar_calculadora'],
            },{
                icon: 'gear',
                title: 'Características da Calculadora',
                route: 'caracteristicaCalculadora',
                perms: ['visualizar_caracteristicaCalculadora'],
            }]
        },{
            icon: 'database',
            title: 'Histórico',
            route: 'relatorioAuditoria',
            perms: ['visualizar_auditoria'],
            children: [{
                route: 'relatorioAuditoria',
            }]
        }],

        showBackground: '50px',
        showIcon: false,

        paragrafoVisivel: false,
        barraExpandida: false,
        larguraBarra: '4.7rem',
      };
    },
    methods: {
        verifyPermissions(perm){
            return loginService.verifyPermissions(perm);
        },
        expandOffcanvas() {
            this.offcanvasExpanded = !this.offcanvasExpanded;
            this.showBackground = this.barraExpandida ? '220px' : '50px';
        },
        toggleExpandir() {
            this.barraExpandida = !this.barraExpandida;
            this.showBackground = this.barraExpandida ? '220px' : '50px';
            this.larguraBarra = this.barraExpandida ? '15%' : '4.7rem';
            this.paragrafoVisivel = !this.paragrafoVisivel;
            this.$emit('barra-toggle'); 
        },
    },
  };
</script>
  
<style scoped>
.dropend .dropdown-toggle.show-icon::after {
  display: none;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: #87CEFA;
}
.sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  height: 100vh;
  background-color: #87CEFA;
  transition: left 0.3s ease, width 0.3s ease;
  margin-top: 65px;
}
.sidebar-aberta {
  left: 0;
}
.btn-ex {
  height: 2rem;
  background-color: white !important;
  transition: transform 0.3s ease;
  padding: 0.1rem !important;
}
.btn-ex:hover {
  background-color: rgb(255, 255, 255) !important;
  transform: scale(1.5);
  box-shadow: 0px 0 1px 1px rgb(202, 202, 202);
  padding: 2px !important;
}
.list-group-item:not(.background-icone):not(.barra-expandida):hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.background-icone{
  background-color: var(--color-white);
  transition: left 0.3s ease, width 0.3s ease;
}
.background-icone p{
  color: #87CEFA;
}
.icon-color {
  color: #87CEFA;
}
.bi {
  font-size: 1.2rem;
}
.list-group {
  --bs-list-group-bg: none;
  --bs-list-group-border-color: none;
}
button > p {
    font-weight: bold;
}
</style>
  