<template> 
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header search py-3">
                <h1>Pesquisar</h1>
            </div>
            <div class="card-body p-0">
                <form>
                    <div class="container-fluid p-0">
                        <div class="row m-3">
                            <div class="col-sm-4">
                                <label for="" class="form-label search">Nome</label>
                                <input v-model="filter.nome" class="form-control" aria-label="State">
                            </div>
                            <div class="col-sm-4">
                                <label for="" class="form-label search">Descrição</label>
                                <input v-model="filter.descricao" class="form-control" aria-label="State">
                            </div>
                            <div class="col-sm-4 text-center">                            
                            <label class="form-label">Tipo</label>
                                <div class="row text-start">
                                <div class="col-sm-6">
                                    <div class="form-check form-switch">
                                    <input v-model="filter.operador" @click="() => { filter.usuario = null }" class="form-check-input" type="checkbox" role="switch" required>
                                    <label class="form-check-label">Operador</label>
                                    <div class="invalid-feedback">
                                        Por favor, preencha o campo Corrente.
                                    </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-check form-switch">
                                    <input v-model="filter.usuario" @click="() => { filter.operador = null }" class="form-check-input" type="checkbox" role="switch" required>
                                    <label class="form-check-label">Usuário</label>
                                    <div class="invalid-feedback">
                                        Por favor, preencha o campo Corrente.
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-sm-6">
                                <button @click="clearFilters" type="button" class="btn LimparFiltros">
                                    Limpar Filtros
                                </button>
                            </div>
                            <div class="col-sm-6 text-end">
                                <button @click="search" type="button" class="btn btn-success btn-pesquisar">
                                    <i class="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    
        <div class="card card-table">
            <div class="card-header" style="background-color: white;">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-sm-6 title-list">
                            <h4>Lista de Permissões</h4>
                        </div>
                        <div class="col-sm-6 text-end">
                            <router-link to="permissao/adicionar" type="button" class="btn btn-adicionar ms-1">
                                Adicionar
                            </router-link>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <pagination     
                            :currentPage="currentPage"
                            :totalRows="totalRows"
                            :rowsPerPage="rowsPerPage"
                            @changePage="changePage"
                            />
                        </div>
                        <div class="col-sm-6 text-end">
                            <div class="select-label d-flex align-items-center justify-content-end">
                                <label for="" class="form-label  me-4" style="white-space: nowrap">Itens por página:</label>
                                <select v-model="rowsPerPage" class="form-select form-select-sm custom-select" aria-label=".form-select-sm">
                                    <option v-for="option in pageOptions" :key="option" selected>{{ option }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <div class="card-body">
                <base-table
                :isBusy="isBusy"
                :fields="fields"
                :rows="rows"
                editRoute='permissao/editar'
                />
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '../../components/Pagination.vue';
import ItemsPerPage from '../../components/ItemsPerPage.vue';
import BaseSelect from '../../components/BaseSelect.vue';
import BaseTable from '../../components/BaseTable.vue';
import RestResource from "../../services/permissao";


export default{
  components: { Pagination, ItemsPerPage , BaseSelect, BaseTable },
  data() {
    return {
      isBusy: true,
      currentPage: 1,
      rowsPerPage: 20,
      totalRows: 0,
      pageOptions: [10, 20, 50, 100],
      rows: [],
      filter: {},
      fields: [
        { key: 'nome', label: 'Nome'},
        { key: 'descricao', label: 'Descrição'},
        { key: 'usuario', label: 'Tipo', format: function (x) { return !x.usuario ? 'Operador' : 'Usuário' }},
        { key: 'edit', label: 'Editar'},
      ],
    };
  },
  created() {
    this.search();
  },
  mounted() {
    document.body.style.backgroundColor = 'var(--color-body)';
  },
  methods:{
    search() {
      this.isBusy = true;
      RestResource.getAll(this.currentPage, this.rowsPerPage, this.filter).then(result => {
        this.rows = result.rows;
        this.totalRows = result.count;
        this.isBusy = false;
      });
    },
    clearFilters() {
      this.filter = {};

      this.currentPage = 1;
      this.rows = [];
      this.totalRows = 0;

      this.search();
    },
    changePage(page) {
      this.currentPage = page;
      this.search();
    },
    selectRows(rowsPerPage) {
      this.rowsPerPage = rowsPerPage;
      this.search();
    },
  }
}

</script>

<style scoped>
.card-header.search {
    background-color: var(--color-border-card);
    padding: 1rem 0 1rem 1.5rem;
    color: var(--color-white);
}
.card.card-table {
  border-top: 0.6rem solid var(--color-border-card);
}
.btn-salvar{
    border-radius: 15px 0px 5px 0;
    padding-left:60px;
    padding-right:60px;
}
.form-label.search {
    font-weight: bold;
}
</style>

