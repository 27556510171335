<template>
  <div class="container py-5">
    <div class="card mb-4 rounded-3 shadow-sm border-card">
      <div class="card-header bg-white text-center">
        <h5 class="py-2">Você não possui permissão suficiente para acessar esta página</h5>
      </div>
      <div class="card-body text-center">
        <div class="btn-group">
          <button class="btn btn-dark text-light btn-no-hover" @click="$router.back()">
            Voltar
          </button>
          <router-link class="btn btn-success text-light btn-no-hover" :to="{ name: 'logout', params: { next: $route.params.next } }">
            Trocar Usuário
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
