<template>
  <loading-page v-if="isBusy" />
  <div v-else class="card card-table mt-0 h-100">
    <div class="card-header bg-white text-uppercase fs-5 px-3">
      <div class="row">
        <div class="col-10">Fator de Operação</div>
        <!-- <div class="col-2 text-end">
          <i class="bi bi-arrow-down-circle" style="cursor: pointer;" @click="exportToPDF"></i>
        </div> -->
      </div>
    </div>
    <div class="card-body h-100 pt-0 text-center">
      <div class="row h-100">
        <div class="col-6 h-100">
          <Pie id="pie" :data="chartData" :options="options" height="100%" />
        </div>
        <div class="col-6 py-2 align-items-center">
          <h5>TEMPO ARCO ABERTO</h5>
          <h3>
            {{
              formatSeconds(
                parseInt(
                  maquinas
                    .map(
                      (m) =>
                        m.calculos.find((c) => c.medicao == "ARCO_ABERTO").valor
                    )
                    .reduce((acc, val) => acc + +val, 0)
                )
              )
            }}
          </h3>
          <h5>TAXA DE DEPOSIÇÃO</h5>
          <h3>
            {{
              (
                parseFloat(
                  configuracoes
                    .map(
                      (m) =>
                        m.calculos.find((c) => c.medicao == "CONSUMO_MATERIAL")
                          .valor
                    )
                    .reduce((acc, val) => acc + +val, 0) / 1000
                ) /
                parseFloat(
                  (maquinas
                    .map(
                      (m) =>
                        m.calculos.find((c) => c.medicao == "MAQUINA_LIGADA").valor
                    )
                    .reduce((acc, val) => acc + +val, 0) || 1) / 60 / 60
                ) 
              ).toFixed(2)
            }}
            Kg/h
          </h3>
          <h5>FATOR DE OPERAÇÃO</h5>
          <h3>
            {{
              (
                maquinas
                  .map(
                    (m) =>
                      m.calculos.find((c) => c.medicao == "EFICIENCIA_MAQUINA")
                        .valor
                  )
                  .reduce((acc, val) => acc + +val, 0) / maquinas.length
              ).toFixed(2)
            }}
            %
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingPage from "../LoadingPage.vue";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

ChartJS.register(ChartJSPluginDatalabels, ArcElement, Tooltip, Legend);

export default {
  components: { LoadingPage, Pie },
  name: "MedicoesConfig",
  props: {
    isBusy: {
      required: false,
    },
    maquinas: {
      required: true,
    },
    configuracoes: {
      required: true,
    },
  },
  data() {
    return {
      eficiencia: 0,
      arcoAberto: 0,
      arcoFechado: 0,
      deposicao: 0,
      filtered: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            // text: `Início: ${(this.filter.dateStart).replaceAll('-', '/')} - Final: ${(this.filter.dateEnd).replaceAll('-', '/')}`,
            align: "start",
            font: {
              weight: "normal",
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return percentage;
            },
            color: "#fff",
            labels: {
              title: {
                font: {
                  weight: "bold",
                  size: 15,
                },
              },
            },
          },
          tooltip: {
            callbacks: {
              title: () => '',
              afterBody: (context) => {
                const maquinas = this.maquinas;
                const medicao = context[0].label
                  .toString()
                  .toUpperCase()
                  .replaceAll(" ", "_");

                let tooltipLabel = ``;

                maquinas.forEach((m, i) => {
                  const valor =
                    m.calculos.find((c) => c.medicao === medicao)?.valor || 0;
                  const formattedValue = this.formatSeconds(parseInt(valor));
                  const machineName = `${m.nome}`;
                  tooltipLabel += `${machineName}: ${formattedValue}\n`;
                });

                return tooltipLabel.trim();
              },
              label: (context) => {
                const medicao = context.label
                  .toString()
                  .toUpperCase()

                return ` ${medicao}`;
              },
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: ["Arco Aberto", "Arco Fechado"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: [
              parseInt(
                this.maquinas
                  .map(
                    (m) =>
                      m.calculos.find((c) => c.medicao == "ARCO_ABERTO").valor
                  )
                  .reduce((acc, val) => acc + +val, 0)
              ),
              parseInt(
                this.maquinas
                  .map(
                    (m) =>
                      m.calculos.find((c) => c.medicao == "ARCO_FECHADO").valor
                  )
                  .reduce((acc, val) => acc + +val, 0)
              ),
            ],
            borderWidth: 0,
          },
        ],
      };
    },
  },
  methods: {
    formatSeconds(milis) {
      let horas = "00";
      let min = "00";
      let seg = "00";
      horas = parseInt(milis / 60 / 60)
        .toString()
        .padStart(2, "0");
      min = parseInt((milis - parseInt(horas) * 60 * 60) / 60)
        .toString()
        .padStart(2, "0");
      seg = parseInt(milis - parseInt(horas) * 60 * 60 - parseInt(min) * 60)
        .toString()
        .padStart(2, "0");
      return `${horas}:${min}:${seg}`;
    },
  },
};
</script>

