<template>
  <loading-page v-if="isBusy" />
  <div v-else class="card card-table mt-0 h-100">
    <div v-if="title" class="card-header bg-white text-uppercase fs-5 px-3">
      <div class="row">
        <div class="col-10">
          {{ title }}
        </div>
        <div class="col-2 text-end">
          <i class="bi bi-arrow-down-circle" style="cursor: pointer;" @click="exportToPDF"></i>
        </div>
      </div> 
    </div>
    <div class="card-body h-100 pt-0">
      <div class="row align-items-center h-100">
        <div class="col-12 h-100">
          <Line :data="chartData" :options="options"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import LoadingPage from "../LoadingPage.vue";
import Exporter from "vue-chartjs-exporter";
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Line } from 'vue-chartjs';

ChartJS.register( zoomPlugin, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export default {
  components: { LoadingPage, Line },
  name: 'LineChart',
  props: {
    title: {
      required: false,
    },
    isBusy: {
      required: false,
    },
    inputData: {
      required: true,
    },
    filter: {
      required: true
    }
  },
  data() {
    return {
      diff: 0,
      steps: 200,
      filtered: [],
      view: { auto: true },
      materials: [
        { id: 1, color: '#41B883'},
        { id: 2, color: '#E46651'},
        { id: 3, color: '#00D8FF'},
        { id: 4, color: '#DD1B16'},
        { id: 5, color: '#41B883'},
        { id: 6, color: '#41B883'}
      ],
      tempoArcoTotal: 0,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 2,
            // pointStyle: 'dash'
          }
        },
        plugins: {
          title: {
            display: true,
            text: `Início: ${(this.filter.dateStart).replaceAll('-', '/')} - Final: ${(this.filter.dateEnd).replaceAll('-', '/')}`,
            align: 'start',
            font: {
              weight: 'normal'
            }
          },
          legend: {
            display: true
          },
          datalabels: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                // return context.raw >= 1000 ? `${ (context.raw/1000).toFixed(0) } Kg` : `${ parseFloat(context.raw).toFixed(0) } g`;
                return `${context.dataset.label} - ${ parseFloat(context.raw).toFixed(0) } g`;
              },
              title: function(context) {
                // return context[0].dataset.label + ' - ' + new Date(parseInt(context[0].label)).toLocaleString().replaceAll(',', '')
                return new Date(parseInt(context[0].label)).toLocaleString().replaceAll(',', '');
              }
            }
          },
        },
        scales: {
          x: {
            display: true,
            ticks: {
              callback: function(value, index, ticks) {
                const show = parseInt(ticks.length / 5);
                // return index % show || ticks.length == index+1 ? null : new Date(this.getLabelForValue(value)).toLocaleTimeString().substring(0,5);
                return index % show || ticks.length == index+1 ? null : new Date(this.getLabelForValue(value)).toLocaleString();
                // return new Date(this.getLabelForValue(value)).toLocaleString();
              }
            }
          },
          y: {
            display: true,
            type: 'linear',
            position: 'left',
            ticks: {
              callback: function(value) {
                // return value >= 1000 ? `${(value/1000).toFixed(2) } Kg` : `${value } g`;
                return `${ parseFloat(value).toFixed(0) } g`;
              }
            }
          },
        }
      }
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.formatLabels(),
        datasets: this.getDatasets(),
      }
    },
  },
  mounted() {
    if(this.inputData.length) this.format(this.inputData);
  },
  watch: {
    inputData() {
      if(this.inputData.length) this.format(this.inputData);
    },
  },
  methods:{
    formatLabels(){
      let labels = [];
      if(this.filtered.length){
        const diff = this.filter.view || (moment(this.filter.dateEnd).endOf("day").valueOf() - moment(this.filter.dateStart).valueOf()) / this.steps;
        for (
          let index = moment(this.filter.dateStart).valueOf();
          index < moment(this.filter.dateEnd).endOf("day").valueOf();
          index += diff
        ) {
          labels.push(index);
        }
        labels.push(moment(this.filter.dateEnd).endOf("day").valueOf());
      }
      return labels
    },
    getDatasets(){
      let datasets = [];
      const materiais = this.getMaterials();
      materiais.forEach(material => {
        datasets.push({
          label: material.descricao.substring(6,20),
            backgroundColor: this.materials.find(m => m.id == material.id).color,
            data: this.formatDataMaterial(material.id),
            borderColor: this.materials.find(m => m.id == material.id).color,
            yAxisID: 'y',
            tension: 0.1
        });
      });
      return datasets;
    },
    getMaterials(){
      const materiais = [];
      if(this.filtered.length){
        this.filtered.forEach(estado => {
          if(!materiais.find(m => m.id == estado.configuracaoEstadoProducao.materialConfiguracao.id)){
            materiais.push(estado.configuracaoEstadoProducao.materialConfiguracao);
          }
        });
      }
      return materiais;
    },
    formatDataMaterial(id) {
      const data = [0];
      if(this.filtered.length){
        const labels = this.formatLabels();
        for (let index = 1; index < labels.length; index++) {
          const middle = this.filtered.filter(x => labels[index-1] <= new Date(x.inicio).getTime() && labels[index] >= new Date(x.fim).getTime() && x.configuracaoEstadoProducao.materialConfiguracao.id == id);
          const without = this.filtered.filter(x => labels[index-1] > new Date(x.inicio).getTime() || labels[index] < new Date(x.fim).getTime() && x.configuracaoEstadoProducao.materialConfiguracao.id == id);
          const started = without.filter(x => labels[index-1] <= new Date(x.inicio).getTime() && labels[index] > new Date(x.inicio).getTime() && x.configuracaoEstadoProducao.materialConfiguracao.id == id);
          const finished = without.filter(x => labels[index-1] <= new Date(x.fim).getTime() && labels[index] > new Date(x.fim).getTime() && x.configuracaoEstadoProducao.materialConfiguracao.id == id);
          const contain = without.filter(x => new Date(x.inicio).getTime() < labels[index-1]  && new Date(x.fim).getTime() > labels[index] && x.configuracaoEstadoProducao.materialConfiguracao.id == id);
          let value = 0;
          if(middle.length) value += middle.reduce((sum, actual) => sum += parseFloat(actual.estadoProducaoMedicaoEstado.find(x => x.medicao == 'CONSUMO_MATERIAL').valor), 0);
          if(started.length) value += started.reduce((sum, actual) => {
            const total = ((labels[index] - new Date(actual.inicio).getTime()) * parseFloat(actual.estadoProducaoMedicaoEstado.find(x => x.medicao == 'CONSUMO_MATERIAL').valor)) / (new Date(actual.fim).getTime() - new Date(actual.inicio).getTime());
            return sum += total;
          }, 0);
          if(finished.length) value += finished.reduce((sum, actual) => {
            const total = ((new Date(actual.fim).getTime() - labels[index -1]) * parseFloat(actual.estadoProducaoMedicaoEstado.find(x => x.medicao == 'CONSUMO_MATERIAL').valor)) / (new Date(actual.fim).getTime() - new Date(actual.inicio).getTime());
            return sum += total;
          }, 0);
          if(contain.length) value += contain.reduce((sum, actual) => {
            const total = ((labels[index] - labels[index -1]) * parseFloat(actual.estadoProducaoMedicaoEstado.find(x => x.medicao == 'CONSUMO_MATERIAL').valor) / (new Date(actual.fim).getTime() - new Date(actual.inicio).getTime()));
            return sum += total;
          }, 0);
          data.push( (value).toFixed(0) )
        }
        // data.push(0);
      }
      return data;
    },
    format(data){
      this.filtered = data.filter((x) => x.estado.indexOf('ARCO') >= 0);   
    },
    exportToPDF() {
      this.download_status = "Processing...";
      let doughnut = document.getElementById("line");

      const exp = new Exporter([doughnut]);
      exp.export_pdf().then((pdf) => {
        pdf.save("TempoDeArco.pdf");
        this.download_status = "Download Charts";
      });
    }
  },
};

</script>