<template>
  <div class="container">
    <login ref="login" />
  </div>
</template>

<script>
import Login from './Login.vue';

export default {
  components: {
    Login,
  },
  mounted () {
    this.$refs.login.logout();
    this.$router.replace(this.$route.params.next || '/login');
  }
};
</script>
