<template>
  <div class="container py-6">
    <loading-page v-if="!rows.length" />
    <div v-else class="row">
      <div
        class="col-lg-3 col-md-6 col-sm-6 col-xm-12"
        v-for="row in rows"
        :key="row.id"
      >
        <router-link
          class="card rounded-3 border"
          :class="`border-${row.colorMaquina}`"
          :to="`/dashboard/${row.id}`"
          style="text-decoration: none"
        >
          <div
            class="card-header rounded-top-1 text-white p-0"
            style="background-color: darkgray"
          >
            <div class="row align-items-stretch w-100 m-0">
              <div class="col rounded-2">
                <span class="fs-4 bold">{{ row.nome }}</span>
              </div>
              <div
                class="col-auto rounded-top-2 rounded-start-2 align-items-center"
                :class="`bg-${row.colorMaquina}`"
              >
                <span style="vertical-align: -webkit-baseline-middle">
                  {{
                    row.colorMaquina == "online"
                      ? "PRODUZINDO"
                      : row.colorMaquina == "warning"
                      ? "EM PAUSA"
                      : row.online
                      ? "LIGADA"
                      : "DESLIGADA"
                  }}
                  <span
                    class="position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle"
                    :class="`bg-${row.online ? 'online' : 'danger'}`"
                    data-bs-toggle="tooltip"
                  :data-bs-title="
                    moment(row.last_sync).format('DD/MM/YYYY HH:mm:ss')
                  "
                    
                  ></span>
                </span>
              </div>
            </div>
          </div>
          <div class="card-body pb-0">
            <div class="row align-items-center">
              <div
                class="col p-0 text-truncate text-center border rounded mx-1"
                :class="`border-${row.colorMaquina} color-${row.colorMaquina}`"
              >
                <span class="title">INÍCIO</span>
                <h6
                  v-if="row.maquinaConfiguracao.length"
                  style="font-size: 0.8rem"
                >
                  {{
                    new Date(row.maquinaConfiguracao[0].inicio)
                      .toLocaleString()
                      .replaceAll(",", " -")
                  }}
                </h6>
                <h6 v-else style="font-size: 0.8rem">Aguardando Início</h6>
              </div>
              <div
                class="col p-0 text-truncate text-center border rounded mx-1"
                :class="`border-${row.colorMaquina} color-${row.colorMaquina}`"
              >
                <span class="title">TEMPO PERCORRIDO</span>
                <h6
                  v-if="row.maquinaConfiguracao.length"
                  style="font-size: 0.8rem"
                >
                  {{
                    formatDate(
                      row.maquinaConfiguracao[0].fim || new Date(),
                      row.maquinaConfiguracao[0].inicio
                    )
                  }}
                </h6>
                <h6 style="font-size: 0.8rem" v-else>SEM TEMPO</h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto">
                <i
                  class="bi bi-person bg-light p-1 rounded border"
                  :class="`text-${row.colorMaquina}`"
                ></i>
              </div>
              <div class="col p-0 text-truncate">
                <span class="title">OPERADOR</span>
                <h6 v-if="row.maquinaConfiguracao.length">
                  {{ row.maquinaConfiguracao[0].operadorConfiguracao.nome }}
                </h6>
                <h6 v-else>Aguardando Operador</h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto">
                <i
                  class="bi bi-clipboard bg-light p-1 rounded border"
                  :class="`text-${row.colorMaquina}`"
                ></i>
              </div>
              <div class="col-auto p-0 text-truncate">
                <span class="title">OP</span>
                <h6 v-if="row.maquinaConfiguracao.length">
                  {{
                    row.maquinaConfiguracao[0].ordemProducaoConfiguracao.codigo
                  }}
                </h6>
                <h6 v-else>Aguardando Ordem</h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto">
                <i
                  class="bi bi-boxes bg-light p-1 rounded border"
                  :class="`text-${row.colorMaquina}`"
                ></i>
              </div>
              <div class="col p-0 text-truncate">
                <span class="title">ID</span>
                <h6 v-if="row.maquinaConfiguracao.length">
                  {{ row.maquinaConfiguracao[0].atividadeConfiguracao.codigo }}
                  -
                  {{
                    row.maquinaConfiguracao[0].atividadeConfiguracao.descricao
                  }}
                </h6>
                <h6 v-else>Aguardando ID</h6>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto">
                <i
                  class="bi bi-box bg-light p-1 rounded border"
                  :class="`text-${row.colorMaquina}`"
                ></i>
              </div>
              <div class="col p-0 text-truncate">
                <span class="title">MATERIAL</span>
                <h6
                  v-if="row.maquinaConfiguracao.length"
                  class="text-truncate me-2"
                  data-bs-toggle="tooltip"
                  :data-bs-title="
                    row.maquinaConfiguracao[0].materialConfiguracao.descricao
                  "
                >
                  {{ row.maquinaConfiguracao[0].materialConfiguracao.codigo }} -
                  {{
                    row.maquinaConfiguracao[0].materialConfiguracao.descricao
                  }}
                </h6>
                <h6 v-else>Aguardando Material</h6>
              </div>
            </div>
          </div>
          <div class="card-footer text-center bg-transparent">
            <div class="row">
              <div
                class="col p-0 text-truncate text-center border rounded text-light"
                :class="`bg-${row.colorArco}`"
              >
                <span class="title">ESTADO DO ARCO</span>
                <h6 v-if="row.maquinaMaquinaMedidor">
                  {{
                    row.maquinaMaquinaMedidor
                      .find((m) => m.chave == "CORRENTE")
                      .estado?.situacao_arco?.replaceAll("_", " ") ||
                    "INDEFINIDO"
                  }}
                </h6>
                <h6 v-else>SEM ARCO</h6>
              </div>
            </div>
            <div class="row my-1" v-if="row">
              <div
                class="col p-0 text-truncate text-center border rounded text-light"
                :class="`bg-${row.colorArco}`"
              >
                <span class="title">PRIMEIRO ARCO ABERTO</span>
                <h6 v-if="row.primeiroArcoAberto" style="font-size: 0.8rem">
                  {{
                    moment(row.primeiroArcoAberto.data_hora).format('DD/MM/YYYY HH:mm:ss')
                  }}
                </h6>
                <h6 v-else style="font-size: 0.8rem">Aguardando Início</h6>
              </div>
              <div
                class="col p-0 text-truncate text-center border rounded text-light"
                :class="`bg-${row.colorArco}`"
              >
                <span class="title">TOTAL DE ARCO ABERTO</span>
                <h6 v-if="row.maquinaMedicaoEstado" style="font-size: 0.8rem">
                  {{
                    formatSeconds(
                      row.maquinaMedicaoEstado.length
                        ? row.maquinaMedicaoEstado.find(
                            (c) => c.medicao == "ARCO_ABERTO"
                          ).valor || 0
                        : 0
                    )
                  }}
                </h6>
                <h6 v-else style="font-size: 0.8rem">Aguardando Arco Aberto</h6>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingPage from "../../components/LoadingPage.vue";
import RestResource from "../../services/dashboard";
import bootstrap from "bootstrap/dist/js/bootstrap.js";
import moment from "moment";

export default {
  components: {
    LoadingPage,
  },
  data() {
    return {
      moment: moment,
      tooltips: null,
      isBusy: true,
      rows: [],
    };
  },
  created() {
    this.search();
  },
  updated() {
    if (!this.tooltips) {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      this.tooltips = [...tooltipTriggerList].map(
        (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
      );
    }
    this.tooltips.map((t) => t.hide());
  },
  methods: {
    formatDate(fim, inicio) {
      let horas = "00";
      let min = "00";
      let seg = "00";
      const milis = new Date(fim).getTime() - new Date(inicio).getTime();
      if (milis) {
        horas = parseInt(milis / 1000 / 60 / 60)
          .toString()
          .padStart(2, "0");
        min = parseInt((milis - parseInt(horas) * 1000 * 60 * 60) / 1000 / 60)
          .toString()
          .padStart(2, "0");
        seg = parseInt(
          (milis -
            parseInt(horas) * 1000 * 60 * 60 -
            parseInt(min) * 1000 * 60) /
            1000
        )
          .toString()
          .padStart(2, "0");
      }
      return `${horas}:${min}:${seg}`;
    },
    formatSeconds(seconds) {
      let horas = "00";
      let min = "00";
      let seg = "00";
      if (!isNaN(+seconds)) {
        horas = parseInt(+seconds / 60 / 60)
          .toString()
          .padStart(2, "0");
        min = parseInt((+seconds - parseInt(horas) * 60 * 60) / 60)
          .toString()
          .padStart(2, "0");
        seg = parseInt(
          +seconds - parseInt(horas) * 60 * 60 - parseInt(min) * 60
        )
          .toString()
          .padStart(2, "0");
      }
      return `${horas}:${min}:${seg}`;
    },
    search() {
      if (this.$route.name != "dashboard") return;
      RestResource.getAll().then((result) => {
        if (result.length) {
          this.rows = result.map((m) => {
            // m.estadoArco =
            //   m.maquinaEstadoProducao?.find(
            //     (e) => e.estado.indexOf("ARCO") >= 0
            //   ) || {};
            // m.estadoMaquina =
            //   m.maquinaEstadoProducao?.find(
            //     (e) => e.estado.indexOf("MAQUINA") >= 0
            //   ) || {};
            if (
              m.maquinaEstadoProducao.length &&
              m.maquinaEstadoProducao[0].estado == "MAQUINA_PAUSADA" &&
              !m.maquinaEstadoProducao[0].fim
            ) {
              m.colorMaquina = "warning";
            } else if (
              m.maquinaConfiguracao.length &&
              !m.maquinaConfiguracao[0].fim
            ) {
              m.colorMaquina = "online";
            } else {
              m.colorMaquina = "secondary";
            }
            if (
              m.maquinaMaquinaMedidor &&
              m.maquinaMaquinaMedidor.find((m) => m.chave == "CORRENTE").estado
                ?.situacao_arco == "ARCO_ABERTO"
            ) {
              m.colorArco = "online";
            } else {
              m.colorArco = "secondary";
            }
            m.online =
              moment().diff(m.last_sync, "YYYY-MM-DDTHH:mm:ss", "minutes") <
              1000 * 60 * 10
                ? true
                : false;
            return m;
          });
          setTimeout(this.search, 1000 * 10);
        }
        this.isBusy = false;
      });
    },
  },
};
</script>

<style scoped>
.card {
  margin-top: 0.5rem;
}
.py-6 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
.col-auto > .bi {
  font-size: 1.5rem;
}
.title {
  font-size: 0.8rem;
}
.border-online {
  border-left: 0.3rem solid #41b731 !important;
  border-color: #41b731 !important;
}
.border-secondary {
  border-left: 0.3rem solid gray !important;
}
.border-warning {
  border-left: 0.3rem solid #ffc107 !important;
}
.bg-online {
  background-color: #41b731;
}
.text-online {
  color: #41b731;
}
.bold {
  font-weight: bold;
}
</style>
