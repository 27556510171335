<template>
  <loading-page v-if="isBusy" />
  <div v-else class="card card-table mt-0 h-100">
    <div class="card-header bg-white text-uppercase fs-5 px-3">
      <div class="row">
        <div class="col-10">
          LINHA DO TEMPO
        </div>
        <!-- <div class="col-2 text-end">
          <i class="bi bi-arrow-down-circle" style="cursor: pointer;" @click="exportToPDF"></i>
        </div> -->
      </div> 
    </div>
    <div class="card-body h-100 pt-0">
      <div class="row align-items-center h-100">
        <div class="col-12 h-100">
          <bar :data="chartData" :options="options" height="200"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import LoadingPage from "../LoadingPage.vue";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, TimeScale } from 'chart.js'
import { Bar } from 'vue-chartjs';
import 'chartjs-adapter-date-fns';

ChartJS.register(Title, Tooltip, Legend, TimeScale, BarElement )

export default {
  components: { LoadingPage, Bar },
  name: 'TimeChart',
  props: {
    isBusy: {
      required: false,
    },
    estados: {
      required: true,
    },    
  },
  data() {
    return {
      labels: [],
      formatted: [],
      colors: [
        { label: 'ARCO ABERTO', backgroundColor: '#00FF00', data: [] },
        { label: 'MAQUINA LIGADA', backgroundColor: '#FFFF00', data: [] },
        { label: 'MAQUINA PAUSADA', backgroundColor: '#FF8000', data: [] },
        { label: 'MAQUINA DESLIGADA', backgroundColor: '#FF0000', data: [] },
        // { label: 'ARCO FECHADO', backgroundColor: '#0000FF', data: [] },
        // { label: 'DESCONHECIDO', backgroundColor: '#808080', data: [] },
      ],
      options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.7,
        plugins: {
          legend: {
            display: true
          },
          datalabels: {
            display: false
          },
          tooltip: {
            callbacks: {
              title: () => "",
              afterBody: (items) => `${new Date(items[0].raw.x[0]).toLocaleString()} - ${new Date(items[0].raw.x[1]).toLocaleString()}`,
              label: (item) => item.dataset.label
            }
          }
        },
        scales: {
          x: {
            // stacked: true,
            min: this.estados.dataInicio ? this.estados.dataInicio : this.getMin(),
            max: this.estados.dataFinal ? this.estados.dataFinal : this.getMin(),
            type: 'time',
            // time: {
            //   unit: 'minute'
            // },
          },
          y: {
            stacked: true,
            beginAtZero: true
          },
        }
      }
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.formatted,
      }
    },
  },
  mounted() {
    if(this.estados) this.format(this.estados);
  },
  watch: {
    estados() {
      if(this.estados) this.format(this.estados);
    },
  },
  methods:{
    getMin(){
      this.estados.result.sort((a, b) => a.inicio < b.inicio);
      return new Date(this.estados.result[0].inicio);
    },
    getMax(){
      this.estados.result.sort((a, b) => a.inicio < b.inicio);
      return new Date(this.estados.result[this.estados.result.length-1].inicio);
    },
    getMaquinas(dados){
      let labels = [];
      let found = null;
      do {
        found = dados.result.find((e) => !labels.includes(e.configuracaoEstadoProducao.maquinaConfiguracao.nome) );
        if (found) {
          labels.push(found.configuracaoEstadoProducao.maquinaConfiguracao.nome);
        }
      } while (found);
      return labels;
    },
    fillMaquinaDesligada(dados, chartData){
      const maquinas = this.getMaquinas(dados);
      const maquinaDesligada = chartData.find((x) => x.label == 'MAQUINA DESLIGADA');

      maquinas.forEach(maquina => {
        const inicioEstados = new Date(dados.dataInicio);
        const finalEstados = new Date(dados.dataFinal);
        // for (let index = inicioEstados; index.getDate() <= finalEstados.getDate(); index = new Date(index.getTime() + 86400000)) {
        //   dados.turnos.forEach((turno) => {
        //     const inicioTurno = new Date(turno.inicio);
        //     const finalTurno = new Date(turno.fim);
        //     maquinaDesligada.data.push({
        //       x: [
        //         new Date(moment(index).format('YYYY-MM-DD ') + moment(inicioTurno).format('HH:mm')), 
        //         new Date(moment(index).format('YYYY-MM-DD ') + moment(finalTurno).format('HH:mm')), 
        //       ],
        //       y: maquina + ' - Turno ' + turno.nome,
        //     });            
        //     const label = this.labels.find((x) => x == maquina + ' - Turno ' + turno.nome);
        //     if (!label) this.labels.push(maquina + ' - Turno ' + turno.nome);
        //   });
        // }
        // maquinaDesligada.data.push({ x: [ inicioEstados, finalEstados ], y: maquina + ' - Geral' });
        maquinaDesligada.data.push({ x: [ inicioEstados, finalEstados ], y: maquina });
      });           
      return chartData;
    },
    format(dados){
      let chartData = this.colors;      
      chartData = this.fillMaquinaDesligada(dados, chartData);
      dados.result.forEach((estado) => {
        let label = chartData.find((x) => x.label == estado.estado.replaceAll('_', ' '));
        if (label) {
          const inicioEstado = new Date(estado.inicio);
          const finalEstado = estado.fim ? new Date(estado.fim) : new Date();
          // dados.turnos.forEach((turno) => {
          //   const inicioTurno = new Date(turno.inicio);
          //   const finalTurno = new Date(turno.fim);
          //   if(
          //     (inicioTurno.getHours() < finalTurno.getHours()
          //     && (inicioEstado.getHours() > inicioTurno.getHours() 
          //     || (inicioEstado.getHours() == inicioTurno.getHours() && inicioEstado.getMinutes() > inicioTurno.getMinutes()))
          //     && (inicioEstado.getHours() < finalTurno.getHours() 
          //     || (inicioEstado.getHours() == finalTurno.getHours() && inicioEstado.getMinutes() < finalTurno.getMinutes())))

          //     || ((inicioTurno.getHours() > finalTurno.getHours())
          //     && ((inicioEstado.getHours() > inicioTurno.getHours() 
          //     || (inicioEstado.getHours() == inicioTurno.getHours() && inicioEstado.getMinutes() > inicioTurno.getMinutes()))
          //     || (inicioEstado.getHours() < finalTurno.getHours() 
          //     || (inicioEstado.getHours() == finalTurno.getHours() && inicioEstado.getMinutes() < finalTurno.getMinutes()))))
          //   ){
          //     label.data.push({ 
          //       x: [inicioEstado, finalEstado], 
          //       y: estado.configuracaoEstadoProducao.maquinaConfiguracao.nome + ' - Turno ' + turno.nome
          //     });
          //   }
          // });
          // label.data.push({ x: [inicioEstado, finalEstado], y: estado.configuracaoEstadoProducao.maquinaConfiguracao.nome + ' - Geral' });
          label.data.push({ x: [inicioEstado, finalEstado], y: estado.configuracaoEstadoProducao.maquinaConfiguracao.nome });
        }
      });
      this.formatted = chartData;
    },
    exportToPDF() {
      this.download_status = "Processing...";
      let doughnut = document.getElementById("line");

      const exp = new Exporter([doughnut]);
      exp.export_pdf().then((pdf) => {
        pdf.save("TempoDeArco.pdf");
        this.download_status = "Download Charts";
      });
    },
    sortLocal(a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }
  },
};

</script>