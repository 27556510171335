'use strict';

import api from './api';

export default {
  /**
   * create: Conecta com o backend para utilização do método POST para criar uma máquina
   * @param {Object} data Informações do que será usado na criação
   */
  create(data) {
    return api.execute('post', `/server/maquina`, null, data);
  },

  /**
   * update: Conecta com o backend para utilização do método PUT para editar uma máquina
   * @param {Object} data Informações do que será usado na edição
   * @param {*} id Primary key
   */
  update(id, data) {
    return api.execute('put', `/server/maquina/${id}`, null, data);
  },

  /**
   * getAll: Conecta com o backend para utilização do método GET para receber uma ou mais máquinas como retorno
   */
  getAll(page, rowsPerPage = 20, filters = {}) {
    let queryString = ``;
    for (var key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key) && filters[key]) {
        queryString += `&${key}=${filters[key]}`;
      }
    }  
    return api.execute('get', `/server/maquina?${queryString}`, page || 1, {rowsPerPage:rowsPerPage});
  },
  
  /**
   * getAllWithoutPagination: Conecta com o backend para utilização do método GET para receber um ou mais máquinas como retorno sem paginação
   */
  getAllWithoutPagination() {
    return api.execute('get', `/server/maquina/all`, null, null, true);
  },

  /**
   * getAllByLocalizacaoWithoutPagination: Conecta com o backend para utilização do método GET para receber uma ou mais máquinas como retorno sem paginação
   * @param {*} idLocalizacao Primary key
   */
  getAllByLocalizacaoWithoutPagination(idLocalizacao) {
    return api.execute('get', `/server/maquina/?idLocalizacao=${idLocalizacao}`, null, null, true);
  },

  /**
   * getAllByLocalizacao: Conecta com o backend para utilização do método GET para receber uma ou mais máquinas como retorno
   * @param {*} idLocalizacao Primary key
   * @param {Number} page Número de paginação
   */
  getAllByLocalizacao(idLocalizacao, page, rowsPerPage = 20) {
    return api.execute('get', `/server/maquina/?idLocalizacao=${idLocalizacao}`, page || 1,{rowsPerPage:rowsPerPage});
  },

  /**
   * getAllByTipoProcessoWithoutPagination: Conecta com o backend para utilização do método GET para receber uma ou mais máquinas como retorno sem paginação
   * @param {*} idTipoProcesso Primary key
   */
  getAllByTipoProcessoWithoutPagination(idTipoProcesso) {
    return api.execute('get', `/server/maquina/?idTipoProcesso=${idTipoProcesso}`, null, null, true);
  },

  /**
   * getAllByTipoProcesso: Conecta com o backend para utilização do método GET para receber uma ou mais máquinas como retorno
   * @param {*} idTipoProcesso Primary key
   * @param {Number} page Número de paginação
   */
  getAllByTipoProcesso(idTipoProcesso, page, rowsPerPage = 20) {
    return api.execute('get', `/server/maquina/?idTipoProcesso=${idTipoProcesso}`, page || 1,{rowsPerPage:rowsPerPage});
  },

  /**
   * getOne: Conecta com o backend para utilização do método GET para receber uma máquina como retorno
   * @param {Number} id Primary key
   */
  getOne(id) {
    return api.execute('get', `/server/maquina/${id}`);
  },

  /**
   * delete: Conecta com o backend para utilização do método DELETE para receber uma máquina
   * @param {Number} id Primary key
   */
  delete(id) {
    return api.execute('delete', `/server/maquina/${id}`);
  },
}