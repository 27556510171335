<template> 
  <div class="container py-5">
    <div class="card card-table">
      <div class="card-header" style="background-color: white;">
        <div class="container-fluid">
          <div v-if="info" class="row align-items-center text-center">
            <div class="col-sm-2">
              <span>Estado</span>
              <p class="fs-5">{{ info.estado.replaceAll('_', ' ') }}</p>
            </div>
            <div class="col-sm-4">
              <span>Operador</span>
              <p class="fs-5">{{ info.operadorEstadoProducao.nome }}</p>
            </div>
            <div class="col-sm-2">
              <span>Início</span>
              <p class="fs-5">{{ new Date(info.inicio).toLocaleString().replaceAll(',',' ') }}</p>
            </div>
            <div class="col-sm-2">
              <span>Final</span>
              <p class="fs-5">{{ new Date(info.fim).toLocaleString().replaceAll(',',' ') }}</p>
            </div>
            <div class="col-sm-2">
              <span>Duração</span>
              <p class="fs-5">{{ formatDate(info.fim, info.inicio) }}</p>
            </div>
          </div>
        </div>
      </div>            
      <div class="card-body">
        <base-table
        :isBusy="isBusy"
        :fields="fields"
        :rows="rows"
        editRoute='relatorio/configuracao'
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pagination.vue';
import ItemsPerPage from '../../components/ItemsPerPage.vue';
import BaseTable from '../../components/BaseTable.vue';
import RestResource from "../../services/estadoProducao";


export default{
  components: { Pagination, ItemsPerPage, BaseTable },
  data(){
    return {
      id: null,
      isBusy: true,
      rows: [],
      fields: [
        { key: 'calculadoraMedicaoEstado.descricao', label: 'Variáveis' },        
        { 
          key: 'valor', 
          label: 'Valor', 
          format: function (x, type) {
            if (x["calculadoraMedicaoEstado.descricao"].indexOf('Tempo') >= 0) {                    
              const horas = parseInt(((x.valor / 1000) / 60) / 60).toString().padStart(2, '0');
              const min = parseInt(((x.valor - (parseInt(horas) * 1000 * 60 * 60)) / 1000) / 60).toString().padStart(2, '0');
              const seg = parseInt(((x.valor - (parseInt(horas) * 1000 * 60 * 60) - (parseInt(min) * 1000 * 60)) / 1000)).toString().padStart(2, '0');
              return `${horas}:${min}:${seg}`;
            } else {
              return x.valor && x.valor.toString().indexOf('.') >= 0 ? parseFloat(x.valor).toFixed(2) : x.valor 
            }
          } 
        },
      ],
      info: null,
    };
  },
  mounted() {
    if (this.$route.params.idEst) {
      this.id = this.$route.params.idEst;
    }
    if (this.id) {
      this.search();
    } else {
      swal("Oops!", "Configuração não encontrada", "error");
    }
  },
  methods:{
    search() {
      this.isBusy = true;
      RestResource.getOne(this.id).then(result => {
        this.info = result;
        result.estadoProducaoMedicaoEstado.sort(function (a, b) {
          if (a.calculadoraMedicaoEstado.descricao > b.calculadoraMedicaoEstado.descricao) {
            return 1;
          }
          if (a.calculadoraMedicaoEstado.descricao < b.calculadoraMedicaoEstado.descricao) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.rows = result.estadoProducaoMedicaoEstado;
        this.isBusy = false;
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.search();
    },
    selectRows(rowsPerPage) {
      this.rowsPerPage = rowsPerPage;
      this.search();
    },
    formatDate(fim, inicio){
      let horas = '00'; let min = '00'; let seg = '00'
      const milis = new Date(fim).getTime() - new Date(inicio).getTime();
      if (milis) {
        horas = parseInt(((milis / 1000) / 60) / 60).toString().padStart(2, '0');
        min = parseInt(((milis - (parseInt(horas) * 1000 * 60 * 60)) / 1000) / 60).toString().padStart(2, '0');
        seg = parseInt(((milis - (parseInt(horas) * 1000 * 60 * 60) - (parseInt(min) * 1000 * 60)) / 1000)).toString().padStart(2, '0');
      }
      return `${horas}:${min}:${seg}`;
    }
  }

}

</script>
