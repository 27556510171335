<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm">
            <form ref="form">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-sm-6">
                            <label class="form-label">Nome</label>
                            <input v-model="model.nome" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Nome.
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="form-label">Descrição</label>
                            <input v-model="model.descricao" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Descrição.
                            </div>
                        </div>
                    </div>
                    <div class="row py-2">
                        <label class="form-label">Permissões</label>
                        <div class="col-sm-4" v-for="permissao in permissoes" :key="permissao.id">
                          <div class="form-check form-switch">
                            <input class="form-check-input" :checked="permissao.checked" type="checkbox" :value="permissao.checked" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                              {{ permissao.descricao }}
                            </label>
                          </div>
                        </div>
                    </div>
                    <div class="row py-2">
                      <confirm-buttons
                        :habExclusao="false"
                        @cancelar="cancelAdd"
                        @confirmar="save"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert';
import BaseSelect from '../../components/BaseSelect.vue';
import ConfirmButtons from "../../components/ConfirmButtons.vue"
import RestResource from "../../services/grupoOperador";
import RestPermissao from "../../services/permissao";


export default{
  components: { ConfirmButtons, BaseSelect },
  data(){
    return {
      id: null,
      isBusy: false,
      model: {
        nome: '',
        descricao: '',
        permissao: [],
      },
      permissoes: [],
    }
  },
  mounted() {
    document.body.style.backgroundColor = 'var(--color-body)';
    this.searchHelpers();
  },
  methods: {
    searchHelpers() {
      this.isBusy = true;
      RestPermissao.getAllWithoutPagination({fromOperador: true}).then(result => {
        this.permissoes = result.rows;
        this.permissoes.map((p) => {
          p.checked = this.model.permissao.indexOf(p.id) >= 0;
          return p
        })
        this.isBusy = false;
      });
    },
    save() {
      if (!this.$refs.form.checkValidity()) {
          this.$refs.form.classList.add('was-validated');
          return;
      }

      RestResource.create(this.model).then(result => {
        if(result.error){
          swal({
          title: "Oops!",
          text:
            result.message,
          icon: "error",
          button: "Continuar..."
          }).then(() => {
          this.isBusy = false;
          });
        } else {
          swal({
            title: "Bom trabalho!",
            text:
              "O grupo de operador foi inserida na base de dados com sucesso!",
            icon: "success",
            button: "Continuar..."
          }).then(() => {
            this.$router.replace('/grupoOperador');
            this.isBusy = false;
          });
        }
      },() => {
        swal({
          title: "Oops!",
          text:
            "Ocorreu um problema ao tentar inserir o grupo de operador.",
          icon: "error",
          button: "Continuar..."
        }).then(() => {
          this.isBusy = false;
        });
      });
    },
    cancelAdd() {
      swal({
        title: "Cancelamento de criação de registro",
        text: "Deseja cancelar a criação do grupo de operador?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          this.$router.push('/grupoOperador');
        }
      });
    },

  }
}

</script>