'use strict';

import api from './api';

export default {
  /**
   * create: Conecta com o backend para utilização do método POST para criar um usuário
   * @param {Object} data Informações do que será usado na criação
   */
  create(data) {
    return api.execute('post', `/server/usuario`, null, data);
  },

  /**
   * update: Conecta com o backend para utilização do método PUT para editar um usuário
   * @param {Object} data Informações do que será usado na edição
   * @param {*} id Primary key
   */
  update(id, data) {
    return api.execute('put', `/server/usuario/${id}`, null, data);
  },

  /**
   * getAll: Conecta com o backend para utilização do método GET para receber um ou mais usuários como retorno
   */
  getAll(page, rowsPerPage = 20, filters = {}) {
    let queryString = ``;
    for (var key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key) && filters[key]) {
        queryString += `&${key}=${filters[key]}`;
      }
    }
    return api.execute('get', `/server/usuario?${queryString}`, page || 1, {rowsPerPage:rowsPerPage});
  },

  /**
   * getAllByGrupoUsuarioWithoutPagination: Conecta com o backend para utilização do método GET para receber um ou mais usuários como retorno sem paginação
   * @param {*} idGrupoUsuario Primary key
   */
  getAllByGrupoUsuarioWithoutPagination(idGrupoUsuario) {
    return api.execute('get', `/server/usuario/?idGrupoUsuario=${idGrupoUsuario}`, null, null, true);
  },

  /**
   * getAllByGrupoUsuario: Conecta com o backend para utilização do método GET para receber um ou mais usuários como retorno
   * @param {*} idGrupoUsuario Primary key
   * @param {Number} page Número de paginação
   */
  getAllByGrupoUsuario(idGrupoUsuario, page, rowsPerPage = 20) {
    return api.execute('get', `/server/usuario/?idGrupoUsuario=${idGrupoUsuario}`, page || 1,{rowsPerPage:rowsPerPage});
  },

  /**
   * getOne: Conecta com o backend para utilização do método GET para receber um usuário como retorno
   * @param {Number} id Primary key
   */
  getOne(id) {
    return api.execute('get', `/server/usuario/${id}`);
  },

  /**
   * delete: Conecta com o backend para utilização do método DELETE para receber um usuário
   * @param {Number} id Primary key
   */
  delete(id) {
    return api.execute('delete', `/server/usuario/${id}`);
  },
}