<template>
    <div class="d-flex justify-content-center align-items-center vh-10">
        <div class="spinner-border custom-spinner" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<style scoped>
.custom-spinner {
  width: 4rem;
  height: 4rem;
}
</style>
