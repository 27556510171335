<template>
  <loading-page v-if="isBusy" />
  <table v-else class="table text-center">
      <thead><tr><th v-for="head in fields" :key="head">{{ head.label }}</th></tr></thead>      
      <tbody>
          <tr v-for="row in formattedRows" :key="row" class="align-middle">
              <td v-for="field in fields.filter((f) => f.key !== 'edit' && f.key !== 'vizualize')" :key="field">
                  {{ field.format ? field.format(row) : row[field.key] }}
              </td>
              <td v-if="fields.filter((f) => f.key === 'edit').length >= 1"><router-link :to="editRoute + '/' + row.id" class="btn btn-secondary btn-sm bi bi-pencil-fill editar"></router-link></td> 
              <td v-if="fields.filter((f) => f.key === 'vizualize').length >= 1"><router-link :to="editRoute + '/' + row.id_ordem_producao" class="btn btn-secondary btn-sm bi bi-eye-fill editar"></router-link></td>                            
          </tr>
      </tbody>
  </table>
</template>

<script>
import LoadingPage from "./LoadingPage.vue";

export default {
  components: { LoadingPage },
  name: 'BaseTable',
  props: {
    isBusy: {
      required: false,
    },
    rows: {
      required: true,
    },
    fields: {
      required: true,
    },
    editRoute: {
      required: true,
    },
  },
  data() {
    return {
      formattedRows: [],
    };
  },
  watch: {
    rows() {
      this.formattedRows = this.rows.map((row) => { return this.deepKeys(row); });
    },
  },
  mounted(){
    this.formattedRows = this.rows.map((row) => { return this.deepKeys(row); }); 
  },
  methods:{
    deepKeys(t, prefix = ''){
      let auxObj = {};
      if (typeof t === 'object' && t !== null){
        for (const [k, v] of Object.entries(t)) {
          if (typeof v === 'object') auxObj = {...auxObj, ...this.deepKeys(v, prefix + k + '.') };
          else auxObj[prefix + k] = v;
        }
      }
      return auxObj
    },
  }
};

</script>