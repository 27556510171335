<template>
  <label class="form-label">{{ label }}</label>
  <div class="d-flex input-group">
    <select v-model="selectedValue" class="form-select" :disabled="options.disabled" required>
        <option v-for="option in options.options" 
        :key="option.value" 
        :value="option.value">{{ option.text }}</option>
      </select>
      <router-link v-if="!hideLink" :to="`/${route}`" type="button" class="btn btn-info text-light btn-no-hover">
        Gerenciar
      </router-link>
  </div>
</template>

<script>

export default {
  name: 'BaseTable',
  props: {
    label: {
      required: true,
    },
    value: {
      required: true,
    },
    textField: {
      required: false,
    },
    hideLink: {
      required: false
    },
    route: {
      required: true,
    },
  },
  emits: {
    onSelect: {
        required: true
    }
  },
  data() {
    return {
      textSelect: 'nome',
      selectedValue: null,
      options: {
        options: [],
        disabled: true
      },
      rest: null,
    };
  },
  mounted() {
    this.selectedValue = this.value;
    if(this.textField) this.textSelect = this.textField;
    this.search();
  },
  watch: {
    selectedValue() {
      this.$emit('onSelect', this.selectedValue);
    },
    value() {
      if (this.selectedValue != this.value) this.selectedValue = this.value;
    },
  },
  methods: {    
    search() {
      this.options.options = [];
      const service = require("../services/"+ this.route)
      service.default.getAllWithoutPagination().then(result => {
        this.options.options = result.rows.map(el => {
          return { value: el.id, text: el[this.textSelect] };
        });
          this.options.disabled = false;
        }
      );
    },
  }
};

</script>

<style scoped>
.form-label {
  font-weight: bold;
}
</style>