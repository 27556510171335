<template>
  <div class="container py-5">
    <div class="card mb-4 rounded-3 shadow-sm">
      <div class="card-header search py-3">
        <h1>Filtros</h1>
        <p class="m-0">Utilize os campos abaixo para refinar o relatório</p>
      </div>
      <div class="card-body p-0">
        <form>
          <div class="container-fluid p-0">
            <div class="row m-3">
              <div class="col-sm-3">
                <label for="" class="form-label search">Data Inicial</label>
                <input
                  v-model="filter.dateStart"
                  type="datetime-local"
                  class="form-control"
                />
              </div>
              <div class="col-sm-3">
                <label for="" class="form-label search">Data Final</label>
                <input
                  v-model="filter.dateEnd"
                  type="datetime-local"
                  class="form-control"
                />
              </div>
              <div class="col-sm-3">
                <label for="" class="form-label search">Ordem de Serviço</label>
                <input v-model="filter.ordemServico" class="form-control" />
              </div>
              <div class="col-sm-3">
                <label for="" class="form-label search">Ordem de Produção</label>
                <input v-model="filter.ordemProducao" class="form-control" />
              </div>
              <!-- <div class="col-sm-3">
                <base-combobox
                  label="Ordem de Produção"
                  :value="filter.idOrdemProducao"
                  textField="codigo"
                  route="ordemProducao"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idOrdemProducao = id;
                    }
                  "
                />
              </div> -->
            </div>
            <!-- <div class="row m-3">
              <div class="col-sm-3">
                <base-combobox
                  label="Tipo de Processo"
                  :value="filter.idTipoProcesso"
                  textField="nome"
                  route="tipoProcesso"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idTipoProcesso = id;
                    }
                  "
                />
              </div>
              <div class="col-sm-3">
                <base-combobox
                  label="Localização"
                  :value="filter.idLocalizacao"
                  textField="nome"
                  route="localizacao"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idLocalizacao = id;
                    }
                  "
                />
              </div>
              <div class="col-sm-3">
                <base-combobox
                  label="ID"
                  :value="filter.idAtividade"
                  textField="codigo"
                  route="atividade"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idAtividade = id;
                    }
                  "
                />
              </div>
              <div class="col-sm-3">
                <base-combobox
                  label="Material"
                  :value="filter.idMaterial"
                  textField="descricao"
                  route="material"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idMaterial = id;
                    }
                  "
                />
              </div>
              <div class="col-sm-3">
                <base-combobox
                  label="Máquina"
                  :value="filter.idMaquina"
                  textField="nome"
                  route="maquina"
                  :hideLink="true"
                  @onSelect="
                    (id) => {
                      filter.idMaquina = id;
                    }
                  "
                />
              </div>
            </div> -->
            <div class="row pt-2">
              <div class="col-sm-6">
                <button
                  @click="clearFilters"
                  type="button"
                  class="btn LimparFiltros"
                >
                  Limpar Filtros
                </button>
              </div>
              <div class="col-sm-6 text-end">
                <button
                  @click="search"
                  type="button"
                  class="btn btn-success btn-pesquisar"
                >
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <loading-page v-if="isBusy" />
    <div v-else class="card card-table">
      <div class="card-header" style="background-color: white">
        <div class="container-fluid">
          <div class="row py-2">
            <div class="col-sm-6 title-list">
              <h4>Relatório de Produção</h4>
            </div>
            <div class="col-sm-6 text-end">
              <button @click="exportar" type="button" class="btn btn-adicionar">
                <i class="bi bi-file-earmark-spreadsheet"></i>
              </button>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-sm-6">
              <pagination
                :currentPage="currentPage"
                :totalRows="totalRows"
                :rowsPerPage="rowsPerPage"
                @changePage="changePage"
              />
            </div>
            <div class="col-sm-6 text-end">
              <items-per-page
                :rowsPerPage="rowsPerPage"
                @onSelect="selectRows"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="rows.length" class="card-body">
        <base-table
          :isBusy="isBusy"
          :fields="fields"
          :rows="rows"
          editRoute="/relatorio/configuracao"
        />
      </div>
      <div v-else class="card-body text-center">
        <span class="fs-2">Sem Informações No Período Selecionado</span>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination.vue";
import ItemsPerPage from "../../components/ItemsPerPage.vue";
import BaseSelect from "../../components/BaseSelect.vue";
import BaseCombobox from "../../components/BaseCombobox.vue";
import BaseTable from "../../components/BaseTable.vue";
import LoadingPage from "../../components/LoadingPage.vue";
import RestResource from "../../services/configuracao";
import moment from "moment";

export default {
  components: {
    Pagination,
    ItemsPerPage,
    ItemsPerPage,
    BaseSelect,
    BaseCombobox,
    BaseTable,
    LoadingPage,
  },
  data() {
    return {
      isBusy: true,
      currentPage: 1,
      rowsPerPage: 20,
      totalRows: 0,
      rows: [],
      filter: {
        dateStart: moment().startOf("month").format("YYYY-MM-DDTHH:mm"),
        dateEnd: moment().endOf("day").format("YYYY-MM-DDTHH:mm"),
        idTipoProcesso: null,
        idLocalizacao: null,
        idOrdemProducao: null,
        idAtividade: null,
        idMaquina: null,
        ordemProducao: null,
        atividade: null,
        material: null,
      },
      fields: [
        {
          key: "ordemProducaoConfiguracao.ordem_servico",
          label: "Ordem de Serviço",
        },
        { key: "ordemProducaoConfiguracao.codigo", label: "Ordem de Produção" },
        // { key: "atividadeConfiguracao.descricao", label: "ID" },
        // { key: "maquinaConfiguracao.nome", label: "Maquina" },
        // {
        //   key: "inicio",
        //   label: "Inicio",
        //   format: function (x) {
        //     return new Date(x.inicio).toLocaleString().replaceAll(",", "");
        //   },
        // },
        // {
        //   key: "fim",
        //   label: "Final",
        //   format: function (x) {
        //     return !x.fim
        //       ? "-"
        //       : new Date(x.fim).toLocaleString().replaceAll(",", "");
        //   },
        // },
        // { key: 'materialConfiguracao.descricao', label: 'Material' },
        { key: "vizualize", label: "Resultados" },
      ],
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    formatDate(date) {
      const ano = date.getFullYear();
      const mes = (date.getMonth() + 1).toString().padStart(2, 0);
      const dia = date.getDate().toString().padStart(2, 0);
      const hora = date.getHours().toString().padStart(2, 0);
      const minuto = date.getMinutes().toString().padStart(2, 0);
      return `${ano}-${mes}-${dia}T${hora}:${minuto}`;
    },
    exportar() {
      this.isBusy = true;
      RestResource.getCsv(this.filter).then((result) => {
        const blob = new Blob([result], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "FCW-RelatórioGeral.csv");
        a.click();
        this.isBusy = false;
      });
    },
    search() {
      this.isBusy = true;
      RestResource.getAll(this.currentPage, this.rowsPerPage, this.filter).then(
        (result) => {
          this.rows = result.rows;
          this.totalRows = result.count;
          this.isBusy = false;
        }
      );
    },
    changePage(page) {
      this.currentPage = page;
      this.search();
    },
    selectRows(rowsPerPage) {
      this.rowsPerPage = rowsPerPage;
      this.search();
    },
    clearFilters() {
      (this.filter = {
        dateStart: moment().startOf("day").format("YYYY-MM-DDTHH:mm"),
        dateEnd: moment().endOf("day").format("YYYY-MM-DDTHH:mm"),
        idTipoProcesso: null,
        idLocalizacao: null,
        idOrdemProducao: null,
        idAtividade: null,
        idMaquina: null,
        ordemProducao: null,
        atividade: null,
        material: null,
      }),
        (this.rows = []);

      this.search();
    },
  },
};
</script>
