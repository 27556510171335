'use strict';

import api from './api';

export default {
  /**
   * create: Conecta com o backend para utilização do método POST para criar um grupo de operadores
   * @param {Object} data Informações do que será usado na criação
   */
  create(data) {
    return api.execute('post', `/server/grupoOperador`, null, data);
  },

  /**
   * update: Conecta com o backend para utilização do método PUT para editar um grupo de operadores
   * @param {Object} data Informações do que será usado na edição
   * @param {*} id Primary key
   */
  update(id, data) {
    return api.execute('put', `/server/grupoOperador/${id}`, null, data);
  },

  /**
   * getAll: Conecta com o backend para utilização do método GET para receber um ou mais grupos de operadores como retorno
   */
  getAll(page, rowsPerPage = 20, filters = {}) {
    let queryString = ``;
    for (var key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key) && filters[key]) {
        queryString += `&${key}=${filters[key]}`;
      }
    }  
    return api.execute('get', `/server/grupoOperador?${queryString}`, page || 1, {rowsPerPage:rowsPerPage});
  },
  
  /**
   * getAllWithoutPagination: Conecta com o backend para utilização do método GET para receber um ou mais grupos de operadores como retorno sem paginação
   */
  getAllWithoutPagination() {
    return api.execute('get', `/server/grupoOperador/all`, null, null, true);
  },

  /**
   * getOne: Conecta com o backend para utilização do método GET para receber um grupo de usuário como retorno
   * @param {Number} id Primary key
   */
  getOne(id) {
    return api.execute('get', `/server/grupoOperador/${id}`);
  },

  /**
   * delete: Conecta com o backend para utilização do método DELETE para receber um grupo de usuário
   * @param {Number} id Primary key
   */
  delete(id) {
    return api.execute('delete', `/server/grupoOperador/${id}`);
  },
}