'use strict';

import api from './api';

export default {
  /**
   * create: Conecta com o backend para utilização do método POST para criar uma ordem de produção
   * @param {String} model Database Model
   * @param {Object} data Informações do que será usado na criação
   */
  create(model, data) {
    return api.execute('post', `/server/${model}`, null, data);
  },

  /**
   * update: Conecta com o backend para utilização do método PUT para editar uma ordem de produção
   * @param {String} model Database Model
   * @param {Object} data Informações do que será usado na edição
   * @param {*} id Primary key
   */
  update(model, id, data) {
    return api.execute('put', `/server/${model}/${id}`, null, data);
  },

  /**
   * getAll: Conecta com o backend para utilização do método GET para receber uma ou mais ordens de produção como retorno
   * @param {String} model Database Model
   */
  getAll(model, page, rowsPerPage = 20, filters = {}) {
    return api.execute('get', `/server/${model}`, page || 1, {rowsPerPage, ...filters});
  },
  
  /**
   * getAllWithoutPagination: Conecta com o backend para utilização do método GET para receber um ou mais ordens de producao como retorno sem paginação
   * @param {String} model Database Model
   */
  getAllWithoutPagination(model) {
    return api.execute('get', `/server/${model}/all`, null, null, true);
  },

  /**
   * getOne: Conecta com o backend para utilização do método GET para receber uma ordem de produção como retorno
   * @param {String} model Database Model
   * @param {Number} id Primary key
   */
  getOne(model, id, data) {
    return api.execute('get', `/server/${model}/${id}`, null, data);
  },

  /**
   * delete: Conecta com o backend para utilização do método DELETE para receber uma ordem de produção
   * @param {String} model Database Model
   * @param {Number} id Primary key
   */
  delete(model, id, data) {
    return api.execute('delete', `/server/${model}/${id}`, null, data);
  },
}