<template>
    <ul class="pagination">
        <li>
            <button class="page-link" @click="$emit('changePage',1)">
                Primeira
            </button>
        </li>
        <li :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="$emit('changePage',currentPage - 1)">
                Anterior
            </button>
        </li>
        <li v-if="currentPage > 2">
            <button class="page-link" @click="$emit('changePage',currentPage - 2)">
                {{ currentPage - 2 }}
            </button>
        </li>
        <li v-if="currentPage > 1">
            <button class="page-link" @click="$emit('changePage',currentPage - 1)">
                {{ currentPage - 1 }}
            </button>
        </li>
        <li class="active">
            <button class="page-link" @click="$emit('changePage',currentPage)">
                {{ currentPage }}
            </button>
        </li>
        <li v-if="(currentPage <= parseInt(totalRows / rowsPerPage))">
            <button class="page-link" @click="$emit('changePage',currentPage + 1)">
                {{ currentPage + 1 }}
            </button>
        </li>
        <li v-if="(currentPage + 1 <= parseInt(totalRows / rowsPerPage))">
            <button class="page-link" @click="$emit('changePage',currentPage + 2)">
                {{ currentPage + 2 }}
            </button>
        </li>
        <li :class="{ disabled: currentPage > parseInt(totalRows / rowsPerPage) }">
            <button class="page-link" @click="$emit('changePage',currentPage + 1)">
                Próxima
            </button>
        </li>
        <li>
            <button class="page-link" @click="$emit('changePage',parseInt(totalRows / rowsPerPage) + 1)">
                Última
            </button>
        </li>
    </ul>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    rowsPerPage: {
      type: Number,
      required: true,
    },
  },
  emits: {
    changePage: {
        required: true
    }
  },
};

</script>

<style scoped>
.pagination {
    --bs-pagination-disabled-bg: none !important;
}
.page-link.active, .active > .page-link {
    z-index: 0; 
}
</style>