<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm">
            <form  ref="form">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-sm-6">
                            <label class="form-label">Mac</label>
                            <input v-model="model.mac" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Mac.
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="form-label">Nome*</label>
                            <input v-model="model.nome" type="text" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Nome.
                            </div>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-sm-6">
                          <base-select
                          label="Localização"
                          :value="model.id_localizacao"
                          textField="nome"
                          route="localizacao"
                          @onSelect="(id) => { model.id_localizacao = id }"/>
                        </div>
                        <div class="col-sm-6">
                          <base-select
                          label="Tipo de Processo"
                          :value="model.id_tipo_processo"
                          textField="nome"
                          route="tipoProcesso"
                          @onSelect="(id) => { model.id_tipo_processo = id }"
                          />
                        </div>
                    </div>
                    <div class="row py-2">
                      <label class="form-label">Medidores</label>
                      <div class="col-sm-6" v-for="medidor in medidores" :key="medidor.id">
                        <div class="form-check form-switch">
                          <input class="form-check-input" v-model="medidor.checked" type="checkbox">
                          <label class="form-check-label" for="flexCheckDefault">
                            {{ medidor.chave }} - ( {{ medidor.descricao }} )
                          </label>
                        </div>
                        <input type="number" v-model="medidor.identificador" class="form-control" required>
                        <div class="invalid-feedback">
                          Por favor, preencha o campo Identificador.
                        </div>
                      </div>
                    </div>
                    <div class="row py-2">
                      <label class="form-label">Estados</label>
                      <div class="col-sm-6" v-for="estado in estados" :key="estado.id">
                        <div class="form-check form-switch">
                          <input class="form-check-input" v-model="estado.checked" type="checkbox">
                          <label class="form-check-label" for="flexCheckDefault">
                            {{ estado.chave }} - ( {{ estado.descricao }} )
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row py-2">
                      <label class="form-label">Calculadoras</label>
                      <div class="col-sm-6" v-for="calculadora in calculadoras" :key="calculadora.id">
                        <div class="form-check form-switch">
                          <input class="form-check-input" v-model="calculadora.checked" type="checkbox">
                          <label class="form-check-label" for="flexCheckDefault">
                            {{ calculadora.chave }} - ( {{ calculadora.descricao }} )
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row py-2">
                      <confirm-buttons
                        :habExclusao="false"
                        @cancelar="cancelAdd"
                        @confirmar="save"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert';
import BaseSelect from '../../components/BaseSelect.vue';
import ConfirmButtons from "../../components/ConfirmButtons.vue";
import RestResource from "../../services/maquina";
import RestMedidores from "../../services/medidor";
import RestEstados from "../../services/estado";
import RestCalculadoras from "../../services/calculadora";


export default{
  components: { ConfirmButtons, BaseSelect },
  data(){
    return {
      id: null,
      isBusy: false,
      model: {
        medidor: [],
        estado: [],
        calculadora: [],
      },
      medidores: [],
      estados: [],
      calculadoras: [],
    }
  },
  mounted() {
    this.searchHelpers();
  },
  methods: {
    searchHelpers() {
      this.isBusy = true;
      RestMedidores.getAllWithoutPagination({fromMaquina: true}).then(result => {
        this.medidores = result.rows;
        this.medidores.map((p) => {
          const medidor = this.model.medidor.find((m) => m.id == p.id);
          p.checked = !!medidor;
          p.identificador = medidor?.identificador || null;
          return p
        })
        this.isBusy = false;
      });
      RestEstados.getAllWithoutPagination({fromMaquina: true}).then(result => {
        this.estados = result.rows;
        this.estados.map((p) => {
          p.checked = this.model.estado.indexOf(p.id) >= 0;
          return p
        })
        this.isBusy = false;
      });
      RestCalculadoras.getAllWithoutPagination({fromMaquina: true}).then(result => {
        this.calculadoras = result.rows;
        this.calculadoras.map((p) => {
          p.checked = this.model.calculadora.indexOf(p.id) >= 0;
          return p
        })
        this.isBusy = false;
      });
    },
    save() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.classList.add('was-validated');
        return;
      }
      this.model.medidor = this.medidores.filter((p) => p.checked == true).map((p) => ({id: p.id, identificador: p.identificador}));
      this.model.estado = this.estados.filter((p) => p.checked == true).map((p) => p.id);
      this.model.calculadora = this.calculadoras.filter((p) => p.checked == true).map((p) => p.id);
      RestResource.create(this.model).then(result => {
        if(result.error){
          swal({
          title: "Oops!",
          text:
            result.message,
          icon: "error",
          button: "Continuar..."
          }).then(() => {
          this.isBusy = false;
          });
        } else {
          swal({
            title: "Bom trabalho!",
            text:
              "A máquina foi inserida na base de dados com sucesso!",
            icon: "success",
            button: "Continuar..."
          }).then(() => {
            this.$router.replace('/maquina');
            this.isBusy = false;
          });
        }
      },() => {
        swal({
          title: "Oops!",
          text:
            "Ocorreu um problema ao tentar inserir a máquina.",
          icon: "error",
          button: "Continuar..."
        }).then(() => {
          this.isBusy = false;
        });
      });
    },
    cancelAdd() {
      swal({
        title: "Cancelamento de criação de registro",
        text: "Deseja cancelar a criação da máquina?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          this.$router.push('/maquina');
        }
      });
    },

  }
}

</script>
