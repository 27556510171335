<template>
  <div class="container py-5">
    <div class="col-12 mt-5">
      <Line :options="chartOptions" :data="chartData" />
    </div>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import RestResource from "../../services/estadoProducao";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

export default {
  name: 'DashboardGrafico',
  components: { Line },
  data() {
    return {
      chartData: {
        datasets: [
          {
            label: 'ARCO_ABERTO',
            data: [],
            fill: false,
            borderColor: 'rgb(255, 0, 0)',
            tension: 0.1
          },
          {
            label: 'ARCO_FECHADO_ALTO',
            data: [],
            fill: false,
            borderColor: 'rgba(255, 165, 0, 1)',
            tension: 0.1
          },
          {
            label: 'ARCO_FECHADO_OK',
            data: [],
            fill: false,
            borderColor: 'rgba(0, 255, 0, 1)',
            tension: 0.1
          },
          {
            label: 'MAQUINA_DESLIGADA',
            data: [],
            fill: false,
            borderColor: 'rgba(0, 255, 0, 1)',
            tension: 0.1,
          }
        ],
        labels: [],
      },
      chartOptions: {
        responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: ''
            }
          }
        }
      },
      chartInstance: null
    }
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      const page = 1;
      const rowsPerPage = 20;
      const filters = {};

      RestResource.getAll(page, rowsPerPage, filters)
        .then(result => {
          this.rows = result.rows;
          this.totalRows = result.count;
          this.updateChartData();
        })
        .catch(error => {
          console.error('Erro ao buscar dados:', error);
        });
    },

    updateChartData() {
    const estadoCount = this.rows.reduce((count, row) => {
      count[row.estado] = (count[row.estado] || 0) + 1;
      return count;
    }, {});

    // Obter a lista de rótulos (estados) e a contagem associada a cada rótulo
    const labels = Object.keys(estadoCount);
    const dataCount = Object.values(estadoCount);

    const chartData = {
      labels: labels,
      labels: Object.keys(estadoCount),
      datasets: [
        {
          label: 'Arco',
          data: Object.values(estadoCount),
          backgroundColor: [
            'rgba(255, 221, 0, 0.2)',
            'rgba(0, 0, 255, 0.2)',
            'rgba(0, 255, 0, 0.2)',
          ],
          borderColor: [
            'rgb(255, 221, 0)',
            'rgba(0, 0, 255, 0.1)',
            'rgba(0, 255, 0, 1)',
          ],
          borderWidth: 2,
        },

        {
          label: 'MAQUINA',
          data: dataCount.map((value, index) => labels[index] === 'MAQUINA_DESLIGADA' ? value : 0),
          backgroundColor: 'rgba(255, 0, 0, 0.2)',
          borderColor: 'rgba(255, 0, 0, 1)',
          borderWidth: 2,
        }
      ],
    };

    this.chartData = chartData;
  }
  }
};
</script>