<template>
  <loading-page v-if="isBusy" />
  <div v-else class="card card-table mt-0 h-100">
    <div class="card-header bg-white text-uppercase fs-5 px-3">
      <div class="row">
        <div class="col-10">Consumo Total de Material</div>
        <!-- <div class="col-2 text-end">
          <i class="bi bi-arrow-down-circle" style="cursor: pointer;" @click="exportToPDF"></i>
        </div> -->
      </div>
    </div>
    <div class="card-body pt-0 text-center">
      <div class="row py-3">
        <div class="col-6">
          <h2 class="fs-2">TOTAL CONSUMIDO</h2>
        </div>
        <div class="col-6">
          <h2>
            {{
              (
                this.configuracoes
                  .map(
                    (c) =>
                      c.calculos.find(
                        (c) => c.medicao == "CONSUMO_MATERIAL"
                      )?.valor || 0
                  )
                  .reduce((acc, val) => acc + +val, 0) / 1000
              ).toFixed(3)
            }}
            Kg
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>TOTAL POR MATERIAL</p>
        </div>
      </div>
      <div v-for="material in materiais" :key="material" class="row">
        <div class="col-3">
          <p>
            {{
              configuracoes.filter((r) => r.id_material == material)[0]
                .materialConfiguracao.codigo
            }}
          </p>
        </div>
        <div class="col-6">
          <p class="text-truncate">
            {{
              configuracoes.filter((r) => r.id_material == material)[0]
                .materialConfiguracao.descricao
            }}
          </p>
        </div>
        <div class="col-3">
          <p>
            {{
              (
                configuracoes
                  .filter((r) => r.id_material == material)
                  .map(
                    (c) =>
                      c.calculos.find(
                        (c) => c.medicao == "CONSUMO_MATERIAL"
                      )?.valor || 0
                  )
                  .reduce((acc, val) => acc + +val, 0) / 1000
              ).toFixed(3)
            }}
            Kg
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingPage from "../LoadingPage.vue";

export default {
  components: { LoadingPage },
  name: "InfoMaterial",
  props: {
    isBusy: {
      required: false,
    },
    configuracoes: {
      required: true,
    },
  },
  data() {
    return {
      materiais: [],
    };
  },
  mounted() {
    const materiais = this.configuracoes.map((r) => r.id_material);
    this.materiais = materiais.filter(
      (item, index) => materiais.indexOf(item) === index
    );
  },
};
</script>
