<template>
    <div class="container py-5">
        <div class="card mb-4 rounded-3 shadow-sm">
            <form ref="form">
                <div class="container-fluid">
                    <div class="row py-2">
                        <div class="col-sm-6">
                            <label class="form-label">Chave*</label>
                            <input v-model="model.chave" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Chave.
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="form-label">Descrição*</label>
                            <input v-model="model.descricao" class="form-control" required>
                            <div class="invalid-feedback">
                              Por favor, preencha o campo Descrição.
                            </div>
                        </div>
                    </div>
                    <div class="row py-2">
                      <confirm-buttons
                        :habExclusao="true"
                        @cancelar="cancelAdd"
                        @excluir="excluir"
                        @confirmar="save"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert';
import BaseSelect from '../../components/BaseSelect.vue';
import ConfirmButtons from "../../components/ConfirmButtons.vue"
import RestResource from "../../services/tipoCalculadora";


export default{
  components: { ConfirmButtons, BaseSelect },
  data(){
    return {
      id: null,
      isBusy: false,
      model: {
        chave: '',
        identificador: '',
      },
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    if (this.id) {
      this.search();
    } else {
      swal("Oops!", "Tipo de Calculadora não encontrado", "error");
    }
  },
  methods: {
    search() {
      this.isBusy = true;
      RestResource.getOne(this.id).then(result => {
        this.model = Object.assign({}, result);
      });
    },
    save() {
      if (!this.$refs.form.checkValidity()) {
          this.$refs.form.classList.add('was-validated');
          return;
      }
      
      RestResource.update(this.model.id, this.model).then(result => {
        if(result.error){
          swal({
          title: "Oops!",
          text:
            result.message,
          icon: "error",
          button: "Continuar..."
          }).then(() => {
          this.isBusy = false;
          });
        } else {
          swal({
            title: "Bom trabalho!",
            text:
              "O tipo de calculadora foi atualizada na base de dados com sucesso!",
            icon: "success",
            button: "Continuar..."
          }).then(() => {
            this.$router.replace('/tipoCalculadora');
            this.isBusy = false;
          });
        }
      },() => {
        swal({
          title: "Oops!",
          text:
            "Ocorreu um problema ao tentar atualizar a tipo de calculadora.",
          icon: "error",
          button: "Continuar..."
        }).then(() => {
          this.isBusy = false;
        });
      });
    },

    excluir() {
      swal({
        title: "Excluir",
        text:
          "Tem certeza que deseja excluir o tipo de calculadora?\nEsta ação é definitiva e sem retorno.",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          RestResource.delete(this.model.id).then(() => {
            swal({
              title: "Bom trabalho!",
              text: "A processo foi removida da base de dados com sucesso!",
              icon: "success",
              button: "Continuar..."
            }).then(() => {
              this.$router.push('/tipoCalculadora');
            });
          }, e => {
            swal({
              title: "Oops!",
              text: e.response.data && e.response.data.error && e.response.data.error.errorMessage || 'Ocorreu um problema ao tentar remover a tipoCalculadora.',
              icon: "error",
              button: "Continuar..."
            });
          });
        }
      });
    },
    cancelAdd() {
      swal({
        title: "Cancelamento de edição de registro",
        text: "Deseja cancelar a edição do tipo de calculadora?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" }
      }).then(value => {
        if (value) {
          this.$router.push('/tipoCalculadora');
        }
      });
    },

  }
}

</script>
